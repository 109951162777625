import React, { useCallback, useEffect, useMemo } from 'react';
import {
  faHome,
  faMobileAlt,
  faShoppingCart,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ROUTES } from 'constants/routes';
import { NavBarComponent } from 'components/common/NavBar';
import {
  exportContactsSlice,
  selectExportContactsIsLoading,
  selectExportContactsStatus,
} from 'ducks/exportContacts';
import { useNotification, usePermission } from 'helpers/hooks';
import { STATUSES } from 'constants/statuses';
import { NOTIFICATION_TYPES } from 'constants/notifications';

export const NavBar = ({ isOpen }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { hasPermission } = usePermission();

  const { showNotification } = useNotification();

  const exportContactsStatus = useSelector(selectExportContactsStatus);

  const isLoading = useSelector(selectExportContactsIsLoading);

  const onLoyaltyExportContactsClick = useCallback(() => {
    dispatch(exportContactsSlice.actions.exportContacts());
  }, [dispatch]);

  const onPushNotificationsClick = useCallback(() => {
    history.push(ROUTES.notificationsAutomaticTab);
  }, [history]);

  const onCarouselClick = useCallback(() => {
    history.push(ROUTES.mobileImages);
  }, [history]);

  const onNewsClick = useCallback(() => {
    history.push(ROUTES.news);
  }, [history]);

  const listDataArray = useMemo(
    () => [
      {
        icon: faUser,
        route: ROUTES.accountsList,
        routeTemplate: ROUTES.accountsTemplate,
        title: 'sideBar.loyaltyUsers',
        subtitles: [
          { subtitle: 'sideBar.loyaltyUsersSubtitle' },
          {
            subtitle: 'sideBar.loyaltyUsersExportContacts',
            onClick: onLoyaltyExportContactsClick,
            isLoading,
          },
        ],
        permissionGranted: hasPermission(ROUTES.accountsTemplate),
      },
      {
        icon: faUsers,
        route: ROUTES.usersList,
        routeTemplate: ROUTES.usersTemplate,
        title: 'sideBar.adminUsers',
        subtitles: [{ subtitle: 'sideBar.adminUsersSubtitle' }],
        permissionGranted: hasPermission(ROUTES.usersTemplate),
      },
      {
        icon: faHome,
        route: ROUTES.storesList,
        routeTemplate: ROUTES.storesTemplate,
        title: 'sideBar.stores',
        permissionGranted: hasPermission(ROUTES.storesTemplate),
      },
      {
        icon: faShoppingCart,
        route: ROUTES.ordersPendingTab,
        routeTemplate: ROUTES.ordersTemplate,
        title: 'sideBar.orders',
        permissionGranted: hasPermission(ROUTES.ordersTemplate),
      },
      {
        icon: faMobileAlt,
        route: ROUTES.notificationsAutomaticTab,
        routeTemplate: ROUTES.mobileTemplate,
        title: 'sideBar.mobile',
        subtitles: [
          { subtitle: 'sideBar.pushNotifications', onClick: onPushNotificationsClick },
          { subtitle: 'sideBar.mobileImages', onClick: onCarouselClick },
          { subtitle: 'sideBar.news', onClick: onNewsClick },
        ],
        permissionGranted: hasPermission(ROUTES.mobileTemplate),
      },
    ],
    [
      hasPermission,
      isLoading,
      onNewsClick,
      onCarouselClick,
      onLoyaltyExportContactsClick,
      onPushNotificationsClick,
    ],
  );

  useEffect(() => {
    if (exportContactsStatus) {
      showNotification({
        notificationType:
          exportContactsStatus === STATUSES.success
            ? NOTIFICATION_TYPES.success
            : NOTIFICATION_TYPES.error,
        title:
          exportContactsStatus === STATUSES.success
            ? t('notifications.exportContactsSuccess')
            : t('notifications.exportContactsFailed'),
        durationInMilliseconds: 5000,
      });
    }
  }, [exportContactsStatus, showNotification, t]);

  return <NavBarComponent listDataArray={listDataArray} isOpen={isOpen} />;
};

NavBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
