import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SearchTextField } from 'components/common/SearchTextField';

export const AccountsSearchForm = React.memo(({ onClose }) => {
  const {
    formState: { isSubmitted },
  } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (isSubmitted) {
      onClose();
    }
  }, [isSubmitted, onClose]);

  return (
    <Box minWidth={400} p={1} display="flex" flexDirection="column">
      <SearchTextField name="email" label={t('accountInfo.email')} isErrorDisplay={false} />
      <SearchTextField name="name" label={t('accountInfo.lastName')} isErrorDisplay={false} />
      <SearchTextField name="card" label={t('accountInfo.cardNumber')} isErrorDisplay={false} />
      <SearchTextField name="id" label={t('accountInfo.id')} isErrorDisplay={false} />
      <>
        <Button variant="contained" sx={{ alignSelf: 'start' }} type="submit">
          <Search />
        </Button>
        {t('search.exclusive')}
      </>
    </Box>
  );
});

AccountsSearchForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};
