import React from 'react';
import { useSelector } from 'react-redux';

import { selectNotifications } from 'ducks/notifications/selectors';
import { Notification } from 'components/notifications/Notification';

export const NotificationManager = () => {
  const notifications = useSelector(selectNotifications);

  return (
    <>
      {notifications.map(({ id, notificationType, title, durationInMilliseconds, onClose }) => {
        return (
          <Notification
            id={id}
            key={id}
            durationInMilliseconds={durationInMilliseconds}
            title={title}
            notificationType={notificationType}
            onClose={onClose}
          />
        );
      })}
    </>
  );
};
