import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/common/Table';

export const OrdersDetailedCartInformation = React.memo(
  ({ cartRows, cartColumns, priceTotal, currencySign, isEventCanceled }) => {
    const { t } = useTranslation();

    return (
      <Box>
        <Typography variant="h6">{t('orders.cartInformation.cartInformation')}</Typography>
        <Table rows={cartRows} columns={cartColumns} />
        <Grid container rows={3} justifyContent="flex-end">
          <Grid item xs={10} textAlign="end">
            <b>{t('orders.cartInformation.total')}</b>
          </Grid>
          <Grid item xs textAlign="end">{`${priceTotal} ${currencySign}`}</Grid>
        </Grid>
        {isEventCanceled && (
          <Grid container direction="column" alignItems="flex-end" mt="16px">
            <Box>{`${t('orders.cartInformation.cancelMessage.initiator')}: ${t(
              'orders.cartInformation.cancelMessage.platform',
            )}`}</Box>
            <Box>{`${t('orders.cartInformation.cancelMessage.reason')}: ${t(
              'orders.cartInformation.cancelMessage.canceledByOperator',
            )}`}</Box>
          </Grid>
        )}
      </Box>
    );
  },
);

OrdersDetailedCartInformation.propTypes = {
  cartRows: PropTypes.arrayOf(PropTypes.shape({})),
  cartColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
    }),
  ),
  priceTotal: PropTypes.string,
  currencySign: PropTypes.string,
  isEventCanceled: PropTypes.bool,
};

OrdersDetailedCartInformation.defaultProps = {
  cartRows: [],
  cartColumns: [],
  priceTotal: '',
  currencySign: '',
  isEventCanceled: false,
};
