import { format, isValid, parseISO } from 'date-fns';

/**
 * Returns the last word from string
 * @param {string} string
 */
export const getLastWord = (string) => string.split(' ').pop();

/**
 * Returns valid string date with dateFormat format or empty line if date is invalid
 * @param {string} date
 * @param {string} dateFormat
 */
export const formatDate = (date, dateFormat) =>
  isValid(parseISO(date)) ? format(parseISO(date), dateFormat) : '';

/**
 * Returns the array of keys, which values is the same as specified value
 * @param {Object} object
 * @param specifiedValue
 */
export const getKeysWithSpecifiedValue = (object, specifiedValue) =>
  Object.keys(object).filter((key) => object[key] === specifiedValue);

/**
 * Returns array of string errors
 * @param {Object} errors
 * @returns {string[]}
 */
export const getErrorList = (errors) =>
  Object.keys(errors).reduce((acc, errorKey) => {
    if (Array.isArray(errors[errorKey])) {
      if (typeof errors[errorKey][0] === 'string') {
        acc.push(errors[errorKey][0]);
      }

      return acc;
    }

    if (errors[errorKey] instanceof Object) {
      acc.push(...getErrorList(errors[errorKey]));
    }

    return acc;
  }, []);

/**
 * Returns the array of objects with marker "shouldBeTranslated" to specify the behavior of displaying
 * @param {string[]} errors
 * @param {string} untranslatedMarker
 * @returns {{message: string, shouldBeTranslated?: boolean}[]}
 */
export const translateErrorList = (errors, untranslatedMarker) =>
  errors.map((error) => {
    if (error.includes(untranslatedMarker)) {
      return {
        shouldBeTranslated: true,
        message: error,
      };
    }

    return {
      message: error,
    };
  });

/**
 * Returns object with data about wallet difference after purchase
 * @param {{
 * eventType: string,
 * event?: { loyaltyStatus?: {centsEarned: number, centsUsed: number, centsDiff: number} },
 * amount?: number,
 * webOrder?: { loyaltyStatus?: {centsEarned: number, centsUsed: number, centsDiff: number} }
 * }} item
 * @returns {{centsDiff: (*)}|{centsEarned: *, centsUsed: *, centsDiff: *}}
 */
export const getCentsInfoByEventType = (item) => {
  if (item?.eventType === 'web_order') {
    return {
      centsUsed: item.webOrder?.loyaltyStatus?.centsUsed,
      centsEarned: item.webOrder?.loyaltyStatus?.centsEarned,
      centsDiff: item.webOrder?.loyaltyStatus?.centsDiff,
    };
  }
  if (item?.eventType === 'expired' || item?.eventType === 'balance_change') {
    return {
      centsDiff: item.event?.amount,
    };
  }
  return {
    centsUsed: item.event?.loyaltyStatus?.centsUsed,
    centsEarned: item.event?.loyaltyStatus?.centsEarned,
    centsDiff: item.event?.loyaltyStatus?.centsDiff,
  };
};
