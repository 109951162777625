import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { ROUTES } from 'constants/routes';

import {
  editProfile,
  getAccountsUsers,
  getHistory,
  getUserProfile,
  setDefaultPassword,
  editBalance,
  getAccount,
  deactivateAccount,
  activateAccount,
  changeEmailRequest,
  getProfile,
  changeEmail,
} from './services';
import { accountSlice } from './reducer';

function* getAccountSaga({ payload }) {
  try {
    const { cards } = yield call(getAccount, payload);
    const {
      embedded: { user },
    } = cards[0];
    yield put(
      accountSlice.actions.getUserSuccess({ ...user, userId: user.id, accountId: payload }),
    );
    yield put(accountSlice.actions.getHistory({ id: user.id }));
    yield put(accountSlice.actions.getInformation(user.id));
    const res = yield call(getUserProfile, user.id);
    yield put(
      accountSlice.actions.getAccountSuccess({
        ...user,
        ...res,
        userId: user.id,
        accountId: payload,
      }),
    );
  } catch (error) {
    yield put(accountSlice.actions.getAccountFailed('getProfileError'));
  }
}

function* getHistorySaga({ payload }) {
  try {
    const {
      embedded: { items },
      page,
      pages,
    } = yield call(getHistory, payload);
    yield put(accountSlice.actions.getHistorySuccess({ items, page, lastPage: pages }));
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse(error?.response?.data.message));
  }
}

function* getInformationSaga({ payload }) {
  try {
    const res = yield call(getAccountsUsers, payload);
    yield put(accountSlice.actions.getInformationSuccess(res));
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse(error?.response?.data.message));
  }
}

function* getProfileSaga({ payload }) {
  try {
    const res = yield call(getProfile, payload);

    yield put(accountSlice.actions.getProfileSuccess(res));
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse('getProfileError'));
  }
}

function* setDefaultPasswordSaga({ payload }) {
  try {
    const res = yield call(setDefaultPassword, payload);
    yield put(accountSlice.actions.setDefaultPasswordSuccess(res));
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse('resetUserPasswordError'));
  }
}

function* editProfileSaga({ payload: { userId, params, id, accountId } }) {
  try {
    const res = yield call(editProfile, id, params);
    const { cards } = yield call(getAccount, accountId);
    const { user } = cards[0].embedded;
    yield put(accountSlice.actions.editProfileSuccess(res));
    yield put(
      accountSlice.actions.getAccountSuccess({
        ...user,
        ...res,
        userId,
        accountId,
      }),
    );
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse(error?.response?.data.message));
  }
}

function* editBalanceSaga({ payload: { userId, params } }) {
  try {
    const res = yield call(editBalance, userId, params);
    yield put(accountSlice.actions.getHistory({ id: userId }));
    yield put(accountSlice.actions.getInformation(userId));
    yield put(accountSlice.actions.editBalanceSuccess(res));
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse('changeBalanceError'));
  }
}

function* activateAccountSaga({ payload: { id, userId } }) {
  try {
    const res = yield call(activateAccount, userId);
    yield put(accountSlice.actions.activateAccountSuccess(res));
    yield put(accountSlice.actions.getAccount(id));
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse('activateUserError'));
  }
}

function* changeEmailSaga({ payload: { userId, params, extraParams } }) {
  try {
    const res = yield call(changeEmailRequest, userId, params);

    yield put(accountSlice.actions.editProfile(extraParams));

    yield call(changeEmail, res.token);

    yield put(accountSlice.actions.changeEmailSuccess(res));
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse(error?.response?.data.message));
  }
}

function* deactivateAccountSaga({ payload }) {
  try {
    const res = yield call(deactivateAccount, payload);
    yield put(accountSlice.actions.deactivateAccountSuccess(res));
    yield put(push(ROUTES.accountsList));
  } catch (error) {
    yield put(accountSlice.actions.getFailedResponse('accountDisableError'));
  }
}

export function* watchAccount() {
  yield takeLatest(accountSlice.actions.getAccount, getAccountSaga);
  yield takeLatest(accountSlice.actions.getProfile, getProfileSaga);
  yield takeLatest(accountSlice.actions.getHistory, getHistorySaga);
  yield takeLatest(accountSlice.actions.getInformation, getInformationSaga);
  yield takeLatest(accountSlice.actions.setDefaultPassword, setDefaultPasswordSaga);
  yield takeLatest(accountSlice.actions.editProfile, editProfileSaga);
  yield takeLatest(accountSlice.actions.editBalance, editBalanceSaga);
  yield takeLatest(accountSlice.actions.changeEmail, changeEmailSaga);
  yield takeLatest(accountSlice.actions.activateAccount, activateAccountSaga);
  yield takeLatest(accountSlice.actions.deactivateAccount, deactivateAccountSaga);
}
