import { isEqual } from 'lodash';

export const selectNewsIds = (state) => state.news.listIds;
export const selectNewsById = (state) => state.news.listById;

export const selectNewsIsLoading = (state) => state.news.isLoading;

export const selectCurrentNewsItem = (state) => state.news.currentNewsItem;

export const selectIsNewsIdsEqual = (state) =>
  isEqual(state.news.listIds, state.news.initialListIds);
