import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SearchField } from 'components/common/SearchField';
import { Form } from 'components/common/Form';

import { UserSearchForm } from './UsersSearchForm';

export const UsersSearchFieldComponent = React.memo(({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" minWidth={450}>
        <Form onSubmit={onSubmit}>
          <SearchField name="username" label={t('user.username')} onSubmit={onSubmit}>
            {(props) => <UserSearchForm {...props} />}
          </SearchField>
        </Form>
      </Box>
    </>
  );
});

UsersSearchFieldComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
