import React, { useCallback } from 'react';
import { Divider, MenuItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const DropdownItem = React.memo(({ text, onClick, handleCloseMenu, isDividerNeeded }) => {
  const handleClick = useCallback(() => {
    onClick();
    handleCloseMenu();
  }, [handleCloseMenu, onClick]);

  return (
    <>
      {onClick ? (
        <MenuItem onClick={handleClick}>{text}</MenuItem>
      ) : (
        <>
          <Typography p="6px 16px">{text}</Typography>
          {isDividerNeeded && <Divider />}
        </>
      )}
    </>
  );
});

DropdownItem.defaultProps = {
  onClick: null,
  isDividerNeeded: false,
};

DropdownItem.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  handleCloseMenu: PropTypes.func.isRequired,
  isDividerNeeded: PropTypes.bool,
};
