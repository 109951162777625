import request from 'helpers/request';

export const fetchCurrentUser = () => request.get('/api/me');

export const changePassword = ({ currentPassword, newPassword }) =>
  request.post(
    '/api/users/change-password',
    {
      currentPassword,
      new: newPassword,
    },
    { ignoreList: ['currentPassword'] },
  );
