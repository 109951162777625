import React from 'react';
import { Fade, Grid, IconButton, styled, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MuiDrawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SIDE_BAR_WIDTH } from 'constants/sideBarWidth';

const openedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  width: SIDE_BAR_WIDTH.open,
  borderRight: 0,
  backgroundColor: theme.palette.sideBar.main,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: SIDE_BAR_WIDTH.close,
  borderRight: 0,
  backgroundColor: theme.palette.sideBar.main,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  '& *': {
    color: theme.palette.sideBar.text,
  },
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: 'auto',
  padding: theme.spacing(2),
  color: theme.palette.sideBar.footer,
  fontSize: 12,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: SIDE_BAR_WIDTH.open,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const SideBar = React.memo(({ isOpen, handleToggleSideBar, children }) => {
  const { t } = useTranslation();

  return (
    <Drawer variant="permanent" open={isOpen}>
      <DrawerHeader>
        <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
          <Typography noWrap>{t('sideBar.title')}</Typography>
          <IconButton onClick={handleToggleSideBar}>
            <MenuIcon />
          </IconButton>
        </Grid>
      </DrawerHeader>
      {children}
      <Fade in={isOpen}>
        <DrawerFooter>{t('sideBar.version')}</DrawerFooter>
      </Fade>
    </Drawer>
  );
});

SideBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggleSideBar: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
