import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationsById: {},
  notificationsIds: [],
  triggerListById: {},
  triggerListIds: [],
  notificationTemplate: {
    id: 0,
    title: '',
    content: '',
    schedule: '',
  },
  triggerTemplate: {
    id: 0,
    content: '',
    trigger: '',
  },
  meta: {
    notificationsPage: 1,
    notificationsLastPage: 1,
    triggersPage: 1,
    triggersLastPage: 1,
  },
  notifications: {
    isLoading: false,
    isSent: '',
    isEdited: '',
  },
  triggers: {
    isLoading: false,
    isSent: '',
    isEdited: '',
    isDeleted: false,
  },
  error: {},
};

export const pushNotificationsSlice = createSlice({
  name: '@@pushNotifications',
  initialState,
  reducers: {
    sendManualNotification: (state) => {
      state.notifications.isSent = initialState.notifications.isSent;
    },
    sendManualNotificationSuccess: (state) => {
      state.notifications.isSent = true;
    },
    sendManualNotificationFailed: (state, action) => {
      state.notifications.isSent = false;
      state.error = action.payload;
    },

    sendAutoNotification: (state) => {
      state.triggers.isSent = initialState.triggers.isSent;
    },
    sendAutoNotificationSuccess: (state) => {
      state.triggers.isSent = true;
    },
    sendAutoNotificationFailed: (state, action) => {
      state.triggers.isSent = false;
      state.error = action.payload;
    },

    getManualNotificationList: (state) => {
      state.notifications.isLoading = true;
    },
    getManualNotificationListSuccess: (state, { payload: { list, page, lastPage } }) => {
      const { byId, allIds } = list;
      state.notificationsById = byId;
      state.notificationsIds = allIds;

      state.meta.notificationsPage = page;
      state.meta.notificationsLastPage = lastPage;
      state.notifications.isLoading = false;
    },
    getManualNotificationListFailed: (state, action) => {
      state.error = action.payload;
      state.notifications.isLoading = false;
    },

    getAutoNotificationList: (state) => {
      state.triggers.isLoading = true;
    },
    getAutoNotificationListSuccess: (state, { payload: { list, page, lastPage } }) => {
      const { byId, allIds } = list;
      state.triggerListById = byId;
      state.triggerListIds = allIds;

      state.meta.triggersPage = page;
      state.meta.triggersLastPage = lastPage;
      state.triggers.isLoading = false;
    },
    getAutoNotificationListFailed: (state, action) => {
      state.error = action.payload;
      state.triggers.isLoading = false;
    },

    editManualNotification: (state) => {
      state.notifications.isEdited = initialState.notifications.isEdited;
    },
    editManualNotificationSuccess: (state) => {
      state.notifications.isEdited = true;
    },
    editManualNotificationFailed: (state, action) => {
      state.error = action.payload;
      state.notifications.isEdited = false;
    },

    editAutoNotification: (state) => {
      state.triggers.isEdited = initialState.isEdited;
    },
    editAutoNotificationSuccess: (state) => {
      state.triggers.isEdited = true;
    },
    editAutoNotificationFailed: (state, action) => {
      state.error = action.payload;
      state.triggers.isEdited = false;
    },

    deleteManualNotification: (state) => state,
    deleteManualNotificationSuccess: (state, { payload: { id, status } }) => {
      state.notificationsById[id].status = status;
    },
    deleteManualNotificationFailed: (state, action) => {
      state.error = action.payload;
    },

    deleteAutoNotification: (state) => state,
    deleteAutoNotificationSuccess: (state) => {
      state.triggers.isDeleted = true;
    },
    deleteAutoNotificationFailed: (state, action) => {
      state.triggers.isDeleted = false;
      state.error = action.payload;
    },

    setNotificationTemplate: (state, { payload }) => {
      state.notificationTemplate = payload;
    },
    resetNotificationTemplate: (state) => {
      state.notifications = initialState.notifications;
      state.notificationTemplate = initialState.notificationTemplate;
    },

    setTriggerTemplate: (state, { payload }) => {
      state.triggerTemplate = payload;
    },
    resetTriggerTemplate: (state) => {
      state.triggerTemplate = initialState.triggerTemplate;
      state.triggers = initialState.triggers;
    },

    setNotificationsPage: (state, { payload: page }) => {
      state.meta.notificationsPage = page;
    },
    setTriggersPage: (state, { payload: page }) => {
      state.meta.triggersPage = page;
    },
  },
});
