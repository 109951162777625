import request from 'helpers/request';
import { CLIENT_ID } from 'config';

export const login = (username, password) =>
  request.post('/oauth/v2/token', {
    username,
    password,
    clientId: CLIENT_ID,
    grantType: 'password',
  });
