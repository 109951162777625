import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export const AccountTitle = React.memo(({ title }) => {
  const { t } = useTranslation();
  const titleArray = Object.entries(title).map(
    ([key, value]) => `${t(`accountInfo.${key}`)} = "${value}" `,
  );
  return (
    <Typography variant="h4">
      {titleArray.length > 0 ? t('accountInfo.title.result') : t('accountInfo.title.empty')}
      {titleArray}
    </Typography>
  );
});
AccountTitle.propTypes = {
  title: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    lastname: PropTypes.string,
    id: PropTypes.number,
  }),
};
AccountTitle.defaultProps = {
  title: {},
};
