import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { TextField } from 'components/common/TextField';
import { SelectField } from 'components/common/SelectField';
import { SubmitButtonWithOnlyValidData as SubmitButton } from 'components/common/Form';

export const AutoNotificationForm = React.memo(({ selectOptions, defaultValues }) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext();

  useEffect(() => {
    const { content, trigger: triggerName } = defaultValues;
    setValue('content', content, { shouldValidate: true });
    setValue('trigger', triggerName, { shouldValidate: true });
  }, [defaultValues, setValue]);

  const { content, trigger: triggerName } = defaultValues;

  return (
    <Box display="flex" justifyContent="center" p={1}>
      <Box display="flex" flexDirection="column" minWidth={400}>
        <TextField
          name="content"
          multiline
          label={t('pushNotificationsPage.newNotification.content')}
          isErrorDisplay
          defaultValue={content}
        />
        <SelectField
          label={t('pushNotificationsPage.trigger')}
          name="trigger"
          options={selectOptions}
          defaultValue={triggerName}
        />
        <Box display="flex" justifyContent="flex-end">
          <SubmitButton>{t('pushNotificationsPage.buttons.send')}</SubmitButton>
        </Box>
      </Box>
    </Box>
  );
});

AutoNotificationForm.defaultProps = {
  defaultValues: {
    content: '',
    trigger: '',
  },
};

AutoNotificationForm.propTypes = {
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  defaultValues: PropTypes.shape({
    content: PropTypes.string.isRequired,
    trigger: PropTypes.string.isRequired,
  }),
};
