export const selectIsLoading = (state) => state.users.isLoading;
export const selectPageCount = (state) => state.users.pageCount;
export const selectCurrentPage = (state) => state.users.currentPage;
export const selectError = (state) => state.users.error;
export const selectStatus = (state) => state.users.status;
export const selectUserList = ({ users: { usersById, usersIds } }) =>
  usersIds.map((id) => usersById[id]);
export const selectEditedUser = ({ users: { usersById, editedUserId } }) => usersById[editedUserId];
export const selectDeletedId = (state) => state.users.deletedId;

export const selectModalErrors = (state) => state.users.modalErrors;

export const selectRestaurantsList = (state) => state.users.restaurantsList;

export const selectIsRestaurantsListLoading = (state) => state.users.isRestaurantsListLoading;
