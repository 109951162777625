import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fab as MuiFab, DialogActions, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { EditBalanceForm } from 'components/account/EditBalanceForm';
import { Form, SubmitButtonWithOnlyValidData } from 'components/common/Form';
import { balanceSchema } from 'schemas';

const Fab = styled(MuiFab)(({ theme }) => ({
  backgroundColor: theme.palette.button.success.main,
  color: theme.palette.white,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.button.success.hover,
  },
  alignSelf: 'end',
  marginRight: theme.spacing(2),
}));

export const EditBalanceComponent = React.memo(({ handleSuccess }) => {
  return (
    <Box ml={1} mr={1}>
      <Form onSubmit={handleSuccess} validationSchema={balanceSchema}>
        <EditBalanceForm />
        <DialogActions>
          <Fab size="small" type="submit" component={SubmitButtonWithOnlyValidData}>
            <CheckIcon />
          </Fab>
        </DialogActions>
      </Form>
    </Box>
  );
});

EditBalanceComponent.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
};
