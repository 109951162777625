import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectOrderList, selectIsLoading, selectPages } from 'ducks/orders';
import { OrderCardsListComponent } from 'components/orders/OrderCardsListComponent';

export const OrderCardsList = React.memo(({ onChangeQuery }) => {
  const cardList = useSelector(selectOrderList);
  const isLoading = useSelector(selectIsLoading);
  const { currentPage, maxPages } = useSelector(selectPages);

  const handleChangePage = React.useCallback((_, page) => onChangeQuery({ page }), [onChangeQuery]);

  return (
    <OrderCardsListComponent
      cardList={cardList}
      isLoading={isLoading}
      currentPage={currentPage}
      maxPages={maxPages}
      handleChangePage={handleChangePage}
    />
  );
});

OrderCardsList.propTypes = {
  onChangeQuery: PropTypes.func.isRequired,
};
