import React, { useMemo } from 'react';
import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Tabs } from 'components/common/Tabs';
import { ROUTES } from 'constants/routes';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { CreateManualNotification } from 'containers/pushNotifications/CreateManualNotification';
import { ManualNotificationsHistory } from 'containers/pushNotifications/ManualHistory/ManualNotificationsHistory';
import { selectNotificationTemplate } from 'ducks/pushNotifications';
import { EditManualNotification } from 'containers/pushNotifications/EditManualNotification';
import { AutoNotificationsHistory } from 'containers/pushNotifications/AutoHistory/AutoNotificationsHistory';
import { CreateAutoNotification } from 'containers/pushNotifications/CreateAutoNotification';

export const PushNotificationsPage = () => {
  const { t } = useTranslation();

  const { id: pushNotificationId } = useSelector(selectNotificationTemplate);

  const tabItems = useMemo(
    () => [
      {
        route: ROUTES.notificationsAutomaticTab,
        title: t('tabs.notifications.automatic'),
        component: () => (
          <>
            <CreateAutoNotification />
            <AutoNotificationsHistory isActive />
          </>
        ),
      },
      {
        route: ROUTES.notificationsManualTab,
        title: t('tabs.notifications.manual'),
        component: pushNotificationId ? EditManualNotification : CreateManualNotification,
      },
      {
        route: ROUTES.notificationsHistoryTab,
        title: t('tabs.notifications.history'),
        component: () => (
          <>
            {t('tabs.notifications.manual')}
            <ManualNotificationsHistory />
            {t('tabs.notifications.automatic')}
            <AutoNotificationsHistory isActive={false} />
          </>
        ),
      },
    ],
    [pushNotificationId, t],
  );

  const breadcrumbsItemsArray = useMemo(
    () => [
      {
        text: t('pushNotificationsPage.title'),
        route: ROUTES.notificationsAutomaticTab,
      },
    ],
    [t],
  );

  return (
    <Box pt={2} pl={6} pr={6} pb={2} component={Paper}>
      <Box pb={2}>
        <Breadcrumbs breadcrumbsItemsArray={breadcrumbsItemsArray} />
      </Box>
      <Tabs items={tabItems} defaultTab={ROUTES.notificationsAutomaticTab} />
    </Box>
  );
};
