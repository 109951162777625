import { call, put, takeLatest } from 'redux-saga/effects';

import {
  editAutoNotification,
  editManualNotification,
  getAutoNotificationList,
  getManualNotificationList,
  sendNotification,
} from 'ducks/pushNotifications/services';
import { pushNotificationsSlice } from 'ducks/pushNotifications/reducer';
import { normalize } from 'ducks/mapper';
import { formatDate } from 'helpers/helpers';
import { PUSH_NOTIFICATION_STATUSES } from 'constants/pushNotificationStatuses';
import { DATE_FORMATS } from 'constants/dateFormats';

function* getManualNotificationListSaga({ payload: { page, limit, automatic, sort } }) {
  try {
    const { order, orderBy } = sort;
    const {
      embedded: { items },
      pages,
    } = yield call(getManualNotificationList, { page, limit, automatic, order, orderBy });

    yield put(
      pushNotificationsSlice.actions.getManualNotificationListSuccess({
        list: normalize(items),
        page,
        lastPage: pages,
      }),
    );
  } catch (error) {
    yield put(
      pushNotificationsSlice.actions.getManualNotificationListFailed(error?.response?.data),
    );
  }
}

function* getAutoNotificationListSaga({ payload: { page, limit, automatic, active, sort } }) {
  try {
    const { order, orderBy } = sort;
    const {
      embedded: { items },
      pages,
    } = yield call(getAutoNotificationList, { page, limit, automatic, active, order, orderBy });

    yield put(
      pushNotificationsSlice.actions.getAutoNotificationListSuccess({
        list: normalize(items),
        page,
        lastPage: pages,
        active,
      }),
    );
  } catch (error) {
    yield put(pushNotificationsSlice.actions.getAutoNotificationListFailed(error?.response?.data));
  }
}

function* sendAutoNotificationSaga({ payload: { content, trigger } }) {
  try {
    yield call(sendNotification, {
      trigger,
      title: trigger,
      content,
      automatic: true,
    });

    yield put(pushNotificationsSlice.actions.sendAutoNotificationSuccess());
  } catch (error) {
    yield put(pushNotificationsSlice.actions.sendAutoNotificationFailed(error?.response?.data));
  }
}

function* sendManualNotificationSaga({ payload: { title, content, schedule } }) {
  try {
    yield call(sendNotification, {
      title,
      content,
      schedule:
        schedule &&
        formatDate(new Date(schedule).toISOString(), DATE_FORMATS.pushNotificationFormat),
      automatic: false,
    });

    yield put(pushNotificationsSlice.actions.sendManualNotificationSuccess());
  } catch (error) {
    yield put(pushNotificationsSlice.actions.sendManualNotificationFailed(error?.response?.data));
  }
}

function* editManualNotificationSaga({ payload: { id, title, content, schedule } }) {
  try {
    yield call(editManualNotification, {
      id,
      title,
      content,
      schedule:
        schedule &&
        formatDate(new Date(schedule).toISOString(), DATE_FORMATS.pushNotificationFormat),
    });

    yield put(pushNotificationsSlice.actions.editManualNotificationSuccess());
    yield put(pushNotificationsSlice.actions.resetNotificationTemplate());
  } catch (error) {
    yield put(pushNotificationsSlice.actions.editManualNotificationFailed(error?.response?.data));
  }
}

function* editAutoNotificationSaga({ payload: { id, content, trigger } }) {
  try {
    yield call(editAutoNotification, { id, active: false });

    yield call(sendNotification, {
      trigger,
      title: trigger,
      content,
      automatic: true,
    });

    yield put(pushNotificationsSlice.actions.editAutoNotificationSuccess());
    yield put(pushNotificationsSlice.actions.resetTriggerTemplate());
  } catch (error) {
    yield put(pushNotificationsSlice.actions.editAutoNotificationFailed(error?.response?.data));
  }
}

function* deleteManualNotificationSaga({ payload: { id } }) {
  try {
    const status = PUSH_NOTIFICATION_STATUSES.canceled;
    yield call(editManualNotification, { id, status });

    yield put(pushNotificationsSlice.actions.deleteManualNotificationSuccess({ id, status }));
  } catch (error) {
    yield put(pushNotificationsSlice.actions.deleteManualNotificationFailed(error?.response?.data));
  }
}

function* deleteAutoNotificationSaga({ payload: { id } }) {
  try {
    const active = false;
    yield call(editAutoNotification, { id, active });

    yield put(pushNotificationsSlice.actions.deleteAutoNotificationSuccess({ id, active }));
  } catch (error) {
    yield put(pushNotificationsSlice.actions.deleteAutoNotificationFailed(error?.response?.data));
  }
}

export function* watchPushNotifications() {
  yield takeLatest(
    pushNotificationsSlice.actions.getManualNotificationList,
    getManualNotificationListSaga,
  );
  yield takeLatest(
    pushNotificationsSlice.actions.getAutoNotificationList,
    getAutoNotificationListSaga,
  );

  yield takeLatest(
    pushNotificationsSlice.actions.sendManualNotification,
    sendManualNotificationSaga,
  );
  yield takeLatest(pushNotificationsSlice.actions.sendAutoNotification, sendAutoNotificationSaga);

  yield takeLatest(
    pushNotificationsSlice.actions.editManualNotification,
    editManualNotificationSaga,
  );
  yield takeLatest(pushNotificationsSlice.actions.editAutoNotification, editAutoNotificationSaga);

  yield takeLatest(
    pushNotificationsSlice.actions.deleteManualNotification,
    deleteManualNotificationSaga,
  );
  yield takeLatest(
    pushNotificationsSlice.actions.deleteAutoNotification,
    deleteAutoNotificationSaga,
  );
}
