import request from 'helpers/request';

export const getManualNotificationList = ({ page, limit, automatic, order, orderBy }) =>
  request.get('/api/fcm-notifications/', {
    params: { page, limit, automatic, order, orderBy },
    ignoreList: ['orderBy'],
  });

export const getAutoNotificationList = ({ page, limit, automatic, active, order, orderBy }) =>
  request.get('/api/fcm-notification-templates/', {
    params: { page, limit, automatic, order, active, orderBy },
    ignoreList: ['orderBy'],
  });

export const sendNotification = ({ title, content, schedule, automatic, trigger }) =>
  request.post('/api/fcm-notification-templates/', {
    title,
    content,
    schedule,
    automatic,
    trigger,
  });

export const editManualNotification = ({ id, title, content, schedule, status }) =>
  request.patch(`/api/fcm-notifications/${id}`, { title, content, schedule, status });

export const editAutoNotification = ({ id, active }) =>
  request.patch(`/api/fcm-notification-templates/${id}`, { active });
