import request from 'helpers/request';

export const getMobileImagesList = () =>
  request.get('/api/content-management', { params: { category: 'home_slider', paginate: false } });

export const addMobileImage = ({ image, hyperlink }) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('category', 'home_slider');
  formData.append('hyperlink', hyperlink);

  return request.post('/api/content-management/images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const editMobileImage = ({ id, image, hyperlink }) => {
  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
  formData.append('category', 'home_slider');
  if (hyperlink || hyperlink === '') {
    formData.append('hyperlink', hyperlink);
  }

  return request.post(`/api/content-management/${id}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteMobileImage = ({ id }) => request.delete(`/api/content-management/${id}/images`);

export const sendNewListOrder = ({ newOrder }) =>
  request.post('/api/content-management/images-order', {
    category: 'home_slider',
    order: newOrder,
  });
