import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField as MuiTextField, styled } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import frLocale from 'date-fns/locale/fr';
import { Controller, useFormContext } from 'react-hook-form';
import { isValid } from 'date-fns';

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    maxWidth: 45,
    maxHeight: 25,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  margin: theme.spacing(0, 1),
}));

export const TimePickerField = React.memo(({ schedule, label, isClosed, fieldName }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const onOpenClose = () => {
    setTimeout(() => document.activeElement?.blur());
  };

  useEffect(() => {
    setValue(fieldName, schedule);
  }, [fieldName, schedule, setValue]);

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={schedule}
      render={({ field: { onChange, ...field } }) => {
        const handleChange = (data) => {
          if (isValid(data)) {
            onChange(data);
            return;
          }
          onChange('');
        };

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <TimePicker
              onChange={handleChange}
              label={label}
              disabled={isClosed}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  InputLabelProps={{ shrink: true }}
                  error={!isClosed && !!errors[fieldName]}
                  size="small"
                />
              )}
              {...field}
              onOpen={onOpenClose}
              onClose={onOpenClose}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
});

TimePickerField.propTypes = {
  fieldName: PropTypes.string,
  index: PropTypes.number,
  schedule: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  label: PropTypes.string,
  isClosed: PropTypes.bool,
};

TimePickerField.defaultProps = {
  fieldName: '',
  schedule: '',
  label: '',
  isClosed: false,
  index: 0,
};
