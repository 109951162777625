import React, { useEffect } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

export const SelectField = React.memo(({ name, label, options, defaultValue, ...props }) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Box mb={2}>
          <FormControl size="small" fullWidth>
            <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
            <Select labelId={`select-label-${label}`} label={label} {...props} {...field}>
              {options.map(({ id, value }) => (
                <MenuItem key={id} value={id} sx={{ height: 36 }}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    />
  );
});

SelectField.defaultProps = {
  defaultValue: '',
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
