import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export const GuestLayout = ({ children }) => {
  return (
    <Box component="main" flexGrow={1}>
      {children}
    </Box>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
