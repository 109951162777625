import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { accountSlice } from 'ducks/account';
import { InformationCollapseComponent } from 'components/account/InformationCollapse/InformationCollapseComponent';

export const InformationCollapse = ({ information, enabled }) => {
  const { userId, id } = information;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { showModal } = useModal();

  const handleSuccess = useCallback(
    (data) => {
      dispatch(accountSlice.actions.editBalance({ userId, params: data }));
    },
    [dispatch, userId],
  );

  const handleEdit = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.editBalance,
      title: t('loyaltyHistory.event.balance_change'),
      onSuccess: handleSuccess,
    });
  }, [showModal, t, handleSuccess]);

  const handleConfirm = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.confirmation,
      title: t('modals.activateUserModal.header'),
      body: t('modals.activateUserModal.body'),
      onSuccess: () => {
        dispatch(accountSlice.actions.activateAccount({ id, userId }));
      },
    });
  }, [dispatch, userId, id, showModal, t]);

  return (
    <InformationCollapseComponent
      enabled={enabled}
      information={information}
      onEdit={handleEdit}
      onConfirm={handleConfirm}
    />
  );
};

InformationCollapse.propTypes = {
  enabled: PropTypes.bool,
  information: PropTypes.shape({
    userId: PropTypes.number,
    balance: PropTypes.number,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
        status: PropTypes.number,
        type: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
    ),
    expiresAt: PropTypes.string,
    id: PropTypes.number,
  }),
};
InformationCollapse.defaultProps = {
  enabled: false,
  information: {},
};
