import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { scheduleSchema } from 'schemas/scheduleSchema';
import { Form, SubmitButtonWithOnlyValidData } from 'components/common/Form';
import { ScheduleForm } from 'components/store/ScheduleForm';

export const Schedule = React.memo(({ schedules, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5">{t('storePage.schedule')}</Typography>
      <Box display="flex" flexDirection="column">
        <Form onSubmit={onSubmit} validationSchema={scheduleSchema}>
          <ScheduleForm schedules={schedules} />
          <SubmitButtonWithOnlyValidData
            type="submit"
            variant="contained"
            sx={{ alignSelf: 'start' }}
          >
            {t('common.confirm')}
          </SubmitButtonWithOnlyValidData>
        </Form>
      </Box>
    </>
  );
});

Schedule.propTypes = {
  onSubmit: PropTypes.func,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      scheduleLines: PropTypes.arrayOf(
        PropTypes.shape({
          dayOfWeek: PropTypes.number,
          openClose: PropTypes.shape({
            open: PropTypes.string,
            close: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
};

Schedule.defaultProps = {
  schedules: [],
  onSubmit: () => {},
};
