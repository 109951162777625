import { ROUTES } from './routes';

export const USER_ROLES = {
  pdpAdmin: 'ROLE_CENTRAL_PDP',
  loyaltyAdmin: 'ROLE_LOYALTY',
  superAdmin: 'ROLE_SUPER_ADMIN',
  manager: 'ROLE_MANAGER',
};

export const ROUTE_MAP_WITH_ROLES = {
  pdpAdmin: ROUTES.accountsList,
  loyaltyAdmin: ROUTES.accountsList,
  superAdmin: ROUTES.accountsList,
  manager: ROUTES.storesList,
};

export const SCOPES = {
  accounts: 'accounts',
  account: 'account',
  users: 'users',
  editUser: 'editUser',
  createUser: 'createUser',
  orders: 'orders',
  order: 'order',
  stores: 'stores',
  storeMenu: 'storeMenu',
  storeInformation: 'storeInformation',
  storeCustom: 'storeCustom',
  login: 'login',
  mobile: 'mobile',
};

export const PERMISSIONS = {
  [USER_ROLES.manager]: [SCOPES.stores, SCOPES.storeMenu, SCOPES.storeInformation, SCOPES.login],
  [USER_ROLES.loyaltyAdmin]: [SCOPES.accounts, SCOPES.account, SCOPES.login],
  [USER_ROLES.pdpAdmin]: [
    SCOPES.accounts,
    SCOPES.account,
    SCOPES.users,
    SCOPES.editUser,
    SCOPES.createUser,
    SCOPES.orders,
    SCOPES.order,
    SCOPES.stores,
    SCOPES.storeMenu,
    SCOPES.storeInformation,
    SCOPES.login,
  ],
  [USER_ROLES.superAdmin]: [
    SCOPES.accounts,
    SCOPES.account,
    SCOPES.users,
    SCOPES.editUser,
    SCOPES.createUser,
    SCOPES.orders,
    SCOPES.order,
    SCOPES.stores,
    SCOPES.storeMenu,
    SCOPES.storeInformation,
    SCOPES.storeCustom,
    SCOPES.login,
    SCOPES.mobile,
  ],
};

export const ROUTE_MAP_WITH_SCOPES = {
  [ROUTES.accountsTemplate]: SCOPES.accounts,
  [ROUTES.accountTemplate]: SCOPES.account,
  [ROUTES.usersTemplate]: SCOPES.users,
  [ROUTES.ordersTemplate]: SCOPES.orders,
  [ROUTES.orderTemplate]: SCOPES.order,
  [ROUTES.storesTemplate]: SCOPES.stores,
  [ROUTES.storeMenuTemplate]: SCOPES.storeMenu,
  [ROUTES.storeInformationTemplate]: SCOPES.storeInformation,
  [ROUTES.storeCustomTemplate]: SCOPES.storeCustom,
  [ROUTES.loginTemplate]: SCOPES.login,
  [ROUTES.editUserTemplate]: SCOPES.editUser,
  [ROUTES.createUser]: SCOPES.createUser,
  [ROUTES.mobileTemplate]: SCOPES.mobile,
  [ROUTES.notificationsTemplate]: SCOPES.mobile,
};
