import React from 'react';
import { Menu } from '@mui/material';
import PropTypes from 'prop-types';

import { DropdownItem } from 'components/common/Dropdown/DropdownItem';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const Dropdown = React.memo(({ menuItemsArray, anchorEl, handleCloseMenu, children }) => (
  <Menu
    id="menu-appbar"
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    open={Boolean(anchorEl)}
    onClose={handleCloseMenu}
    disablePortal
  >
    {menuItemsArray.map(({ text, onClick, isDividerNeeded }) => (
      <DropdownItem
        key={text}
        text={text}
        handleCloseMenu={handleCloseMenu}
        isDividerNeeded={isDividerNeeded}
        onClick={onClick}
      />
    ))}
    {children({ onClose: handleCloseMenu })}
  </Menu>
));

Dropdown.defaultProps = {
  menuItemsArray: [],
  children: () => <div />,
  anchorEl: null,
};

Dropdown.propTypes = {
  menuItemsArray: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      isDividerNeeded: PropTypes.bool,
    }),
  ),
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Element)]),
  handleCloseMenu: PropTypes.func.isRequired,
  children: PropTypes.func,
};
