import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const OrdersDetailedCustomerInformation = React.memo(
  ({
    cardNumber,
    firstName,
    mobileNumber,
    postcode,
    email,
    pricePayboxPart,
    currencySign,
    willUsePaymentBalance,
    pricePaymentPart,
  }) => {
    const { t } = useTranslation();

    return (
      <Box mr={2}>
        <Typography variant="h6">{t('orders.customerInformation.customerInformation')}</Typography>
        <Box pl={2}>
          {cardNumber && (
            <Typography>
              {t('orders.customerInformation.cardNumber')}: {cardNumber}
            </Typography>
          )}
          <Typography>
            {t('orders.customerInformation.client')}: {firstName}
          </Typography>
          <Typography>{t('orders.customerInformation.address')}:</Typography>
          <Typography>
            {t('orders.customerInformation.postalCode')}:{` ${postcode || ''}`}
          </Typography>
          <Typography>
            {t('orders.customerInformation.phone')}:{` ${mobileNumber}`}
          </Typography>
          <Typography>
            {t('orders.customerInformation.email')}: {email}
          </Typography>
          <Typography>
            {t('orders.customerInformation.payment')}: {pricePayboxPart}
            {` ${currencySign}`}
          </Typography>
          {willUsePaymentBalance && (
            <Typography>
              {t('orders.customerInformation.paymentBalance')}: {pricePaymentPart}
              {` ${currencySign}`}
            </Typography>
          )}
        </Box>
      </Box>
    );
  },
);

OrdersDetailedCustomerInformation.propTypes = {
  cardNumber: PropTypes.string,
  firstName: PropTypes.string,
  mobileNumber: PropTypes.string,
  postcode: PropTypes.string,
  email: PropTypes.string,
  pricePayboxPart: PropTypes.string,
  currencySign: PropTypes.string,
  willUsePaymentBalance: PropTypes.bool,
  pricePaymentPart: PropTypes.string,
};

OrdersDetailedCustomerInformation.defaultProps = {
  cardNumber: '',
  firstName: '',
  mobileNumber: '',
  postcode: '',
  email: '',
  pricePayboxPart: '',
  currencySign: '',
  willUsePaymentBalance: false,
  pricePaymentPart: '',
};
