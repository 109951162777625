import * as yup from 'yup';

/**
 * Check if dates are both empty or if the date is empty and the other one is not
 * @param {string} currentDate
 * @param {string} oppositeDate
 * @returns {boolean}
 */
function checkIfDatesEmpty(currentDate, oppositeDate) {
  const isDateInvalid = oppositeDate === 'Invalid Date' || currentDate === 'Invalid Date';

  const isOppositeDateNotEmpty =
    oppositeDate !== '' && oppositeDate !== null && (currentDate === '' || currentDate === null);

  const isDateEmpty =
    (currentDate === '' || currentDate === null) && (oppositeDate === '' || oppositeDate === null);

  return isDateInvalid || isOppositeDateNotEmpty || isDateEmpty;
}

/**
 * Check closing time to be greater than opening time
 * @param {string} closeDate
 * @returns {boolean}
 */
function checkCloseDate(closeDate) {
  if (closeDate === undefined) {
    return false;
  }

  const openDate = this.parent[`open${this.path.slice(-1)}`];
  if (openDate === undefined) {
    return false;
  }

  const isClosed = this.parent[`isClosed${this.path.slice(-1)}`];
  if (isClosed) {
    return true;
  }

  if (checkIfDatesEmpty(closeDate, openDate)) {
    return false;
  }

  const openDateArray = openDate.split(':');
  const closeDateArray = closeDate.split(':');

  const openTime = `${openDateArray[0].slice(-2)}:${openDateArray[1]}`;
  const closeTime = `${closeDateArray[0].slice(-2)}:${closeDateArray[1]}`;

  return openTime <= closeTime;
}

/**
 * Check closing time to be greater than opening time
 * @param {string} openDate
 * @returns {boolean}
 */
function checkOpenDate(openDate) {
  if (openDate === undefined) {
    return false;
  }

  const closeDate = this.parent[`close${this.path.slice(-1)}`];
  if (closeDate === undefined) {
    return false;
  }

  const isClosed = this.parent[`isClosed${this.path.slice(-1)}`];
  if (isClosed) {
    return true;
  }

  if (checkIfDatesEmpty(openDate, closeDate)) {
    return false;
  }

  const openDateArray = openDate.split(':');
  const closeDateArray = closeDate.split(':');

  const openTime = `${openDateArray[0].slice(-2)}:${openDateArray[1]}`;
  const closeTime = `${closeDateArray[0].slice(-2)}:${closeDateArray[1]}`;

  return openTime <= closeTime;
}

export const scheduleSchema = yup.object().shape({
  open0: yup.string().test('openingTime', 'Invalid open time', checkOpenDate),
  close0: yup.string().test('closingTime', 'Invalid close time', checkCloseDate),
  isClosed0: yup.bool().required(),
  open1: yup.string().test('openingTime', 'Invalid open time', checkOpenDate),
  close1: yup.string().test('closingTime', 'Invalid close time', checkCloseDate),
  isClosed1: yup.bool().required(),
  open2: yup.string().test('openingTime', 'Invalid open time', checkOpenDate),
  close2: yup.string().test('closingTime', 'Invalid close time', checkCloseDate),
  isClosed2: yup.bool().required(),
  open3: yup.string().test('openingTime', 'Invalid open time', checkOpenDate),
  close3: yup.string().test('closingTime', 'Invalid close time', checkCloseDate),
  isClosed3: yup.bool().required(),
  open4: yup.string().test('openingTime', 'Invalid open time', checkOpenDate),
  close4: yup.string().test('closingTime', 'Invalid close time', checkCloseDate),
  isClosed4: yup.bool().required(),
  open5: yup.string().test('openingTime', 'Invalid open time', checkOpenDate),
  close5: yup.string().test('closingTime', 'Invalid close time', checkCloseDate),
  isClosed5: yup.bool().required(),
  open6: yup.string().test('openingTime', 'Invalid open time', checkOpenDate),
  close6: yup.string().test('closingTime', 'Invalid close time', checkCloseDate),
  isClosed6: yup.bool().required(),
});
