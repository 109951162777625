import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button as MuiButton, Container, Paper, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  selectEditedUser,
  usersSlice,
  selectIsLoading,
  selectModalErrors,
  selectRestaurantsList,
  selectIsRestaurantsListLoading,
} from 'ducks/users';
import { UserForm } from 'components/users/UserForm';
import { Form } from 'components/common/Form';
import { SubmitButtonWithOnlyValidData } from 'components/common/Form/SubmitButtonWithOnlyValidData';
import { userSchema } from 'schemas';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { ROUTES } from 'constants/routes';
import { MODAL_TYPES } from 'constants/modals';
import { useModal } from 'helpers/hooks';
import { Loader } from 'components/common/Loader/Loader';
import { selectRole } from 'ducks/currentUser';
import { USER_ROLES } from 'constants/userRoles';

const SaveButton = styled(SubmitButtonWithOnlyValidData)(({ theme }) => ({
  alignSelf: 'start',
  backgroundColor: theme.palette.button.success.main,
  '&:hover': {
    backgroundColor: theme.palette.button.success.hover,
  },
}));
const Button = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.white,
  margin: theme.spacing(4, 1),
  padding: theme.spacing(1, 2),
  borderRadius: 2,
  alignSelf: 'start',
  backgroundColor: theme.palette.button.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.button.primary.hover,
  },
}));

export const EditUserPage = () => {
  const { t } = useTranslation();

  const { userId } = useParams();

  const dispatch = useDispatch();

  const { showModal } = useModal();

  const role = useSelector(selectRole);
  const user = useSelector(selectEditedUser);
  const isLoading = useSelector(selectIsLoading);
  const errors = useSelector(selectModalErrors);
  const restaurantsList = useSelector(selectRestaurantsList);
  const isRestaurantsListLoading = useSelector(selectIsRestaurantsListLoading);

  const onSubmit = useCallback(
    ({ confirmPassword, roles, id, restaurants, ...sendData }) => {
      const managedStores =
        roles === USER_ROLES.manager ? restaurants.map(({ id: storeId }) => storeId) : undefined;

      if (userId) {
        return dispatch(
          usersSlice.actions.updateUser({
            userId,
            params: { ...sendData, roles: [roles], managedStores },
          }),
        );
      }

      return dispatch(
        usersSlice.actions.createUser({
          params: { ...sendData, roles: [roles], managedStores },
        }),
      );
    },
    [dispatch, userId],
  );

  const clearStatus = useCallback(() => {
    dispatch(usersSlice.actions.clearStatus());
  }, [dispatch]);

  const clearErrors = useCallback(() => {
    dispatch(usersSlice.actions.clearErrors());
  }, [dispatch]);

  const getRestaurantsList = useCallback(() => {
    dispatch(usersSlice.actions.getRestaurantsList({}));
  }, [dispatch]);

  const items = useMemo(
    () => [
      { text: t('user.list'), route: ROUTES.usersList },
      { text: user ? t('user.edit', { username: user.username }) : t('user.create') },
    ],
    [t, user],
  );

  const translatedErrors = useMemo(
    () =>
      errors
        .map(({ message, shouldBeTranslated }) => {
          if (shouldBeTranslated) return t(`modals.untranslated.${message}`);
          return message;
        })
        .join('\n\n'),
    [errors, t],
  );

  useEffect(() => {
    if (translatedErrors.length) {
      showModal({
        modalType: MODAL_TYPES.acknowledgment,
        title: 'error',
        body: translatedErrors,
        onSuccess: clearStatus,
        onClose: clearErrors,
      });
    }
  }, [showModal, dispatch, clearStatus, clearErrors, translatedErrors]);

  useEffect(() => {
    if (userId) {
      dispatch(usersSlice.actions.getUser({ userId }));
    }
  }, [userId, dispatch]);

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Container component={Paper} maxWidth="lg" sx={{ margin: 3, padding: 4 }}>
        <Loader isLoading={isLoading}>
          <Breadcrumbs breadcrumbsItemsArray={items} />
          <Form onSubmit={onSubmit} validationSchema={userSchema}>
            <UserForm
              user={user}
              role={role}
              isRestaurantsListLoading={isRestaurantsListLoading}
              restaurantsList={restaurantsList}
              getRestaurantsList={getRestaurantsList}
            />
            <SaveButton>{t('user.save')}</SaveButton>
          </Form>
        </Loader>
      </Container>
      <Button component={Link} to={ROUTES.usersList}>
        {t('user.backToList')}
      </Button>
    </Box>
  );
};
