import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export const DraggableElement = React.memo(({ id, dragType, index, moveElement, children }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: dragType,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(acc, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = acc.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveElement(dragIndex, hoverIndex);

      acc.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: dragType,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <Box component="div" sx={{ opacity, cursor: 'move' }} ref={ref} dataHandlerId={handlerId}>
      {children}
    </Box>
  );
});

DraggableElement.propTypes = {
  id: PropTypes.number.isRequired,
  dragType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  moveElement: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
