import { createTheme } from '@mui/material';

import OpenSans from 'fonts/open-sans-v26-latin-regular.woff';
import { NOTIFICATION_TYPES } from 'constants/notifications';

const openSans = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Open Sans'),
    url(${OpenSans}) format('woff')`,
};

const palette = {
  white: '#FFFFFF',
  black: '#000000',
  body: {
    background: {
      main: '#e5e5e5',
    },
    text: {
      main: '#333333',
    },
  },
  topBar: {
    main: '#3f474c',
    text: '#FFFFFF',
    profileHover: 'rgba(255, 255, 255, 0.1)',
  },
  sideBar: {
    main: '#2d3437',
    text: '#797979',
    greetingTitle: '#72848b',
    hover: '#FFFFFF',
    border: '#e99d1a',
    footer: '#b2bfdc',
  },
  table: {
    header: '#fafafa',
    columnOdd: '#F9FAFF',
    columnEven: '#FFFFFF',
    border: '#EBF0FC',
    hover: '#f3f4f7',
  },
  link: {
    main: '#428bca',
    hover: '#3071a9',
  },
  accordion: {
    title: '#f3f4f7',
    details: '#FFFFFF',
    border: '#EBF0FC',
    button: '#383e47',
    color: '#000',
    hover: '#22252a',
  },
  button: {
    primary: {
      main: '#428bca',
      hover: '#3071a9',
    },
    success: {
      main: '#93C54B',
      hover: '#79a736',
    },
    warning: {
      main: '#F47C3C',
      hover: '#ef5c0e',
    },
    danger: {
      main: '#d9534f',
    },
    info: {
      main: '#29ABE0',
    },
  },
  tab: {
    text: '#737F93',
    hover: '#f3f4f7',
    active: '#e5e5e5',
    border: '#EBF0FC',
  },
  notification: {
    [NOTIFICATION_TYPES.success]: '#93C54B',
    [NOTIFICATION_TYPES.warning]: '#F47C3C',
    [NOTIFICATION_TYPES.error]: '#d9534f',
    [NOTIFICATION_TYPES.info]: '#29ABE0',
  },
  datepicker: {
    text: '#FFFFFF',
    main: '#383e47',
    hover: '#22252a',
    color: '#737F93',
    selected: '#1B8DBB',
    today: '#29ABE0',
    close: '#79a736',
    clear: '#c9302c',
  },
  fab: {
    main: '#F47C3C',
    hover: '#ef5c0e',
    text: '#FFFFFF',
  },
  popper: {
    info: '#29ABE0',
  },
  divider: '#f3f4f7',
};

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      '@font-face': [openSans],
      body: {
        margin: 0,
        color: palette.body.text.main,
        backgroundColor: palette.body.background.main,
      },
    },
  },
};

const typography = {
  fontFamily: 'Open Sans',
  button: {
    textTransform: 'none',
  },
};

export const theme = createTheme({
  palette,
  components,
  typography,
});
