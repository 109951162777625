import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { ROUTES } from 'constants/routes';
import { getErrorList, translateErrorList } from 'helpers/helpers';
import { USER_ROLES } from 'constants/userRoles';

import { usersSlice } from './reducer';
import {
  getUser,
  getUsersList,
  createUser,
  updateUser,
  deleteUser,
  getRestaurantsList,
  updateUsersStores,
} from './services';

const errorMessage = (error) => error?.response?.data.message;

function* confirmListSaga(data) {
  yield put(usersSlice.actions.confirmList(data));
}
function* confirmEditSaga(data) {
  yield put(usersSlice.actions.confirmEdit(data));
}

function* getUsersListSaga({ payload }) {
  try {
    const res = yield call(getUsersList, payload);
    yield put(usersSlice.actions.getUsersSuccess(res));
  } catch (error) {
    yield call(confirmListSaga, errorMessage(error));
  }
}

function* getUserSaga({ payload: { userId } }) {
  try {
    const res = yield call(getUser, userId);
    yield put(usersSlice.actions.getUserSuccess(res));
  } catch (error) {
    yield call(confirmListSaga, errorMessage(error));
  }
}

function* createUserSaga({ payload: { params } }) {
  try {
    yield call(createUser, params);
    yield call(confirmListSaga, 'created');
    yield put(push(ROUTES.usersList));
  } catch (error) {
    yield put(
      usersSlice.actions.createUserFailed(
        translateErrorList(getErrorList(error?.response?.data?.errors), 'fos_user'),
      ),
    );
    yield call(confirmEditSaga, 'permissionDenied');
  }
}

function* updateUserSaga({ payload: { userId, params } }) {
  try {
    yield call(updateUser, userId, { ...params, managedStores: undefined });
    if (params.roles[0] === USER_ROLES.manager) {
      yield call(updateUsersStores, userId, { managedStores: params.managedStores });
    }
    yield call(confirmListSaga, 'updated');
    yield put(push(ROUTES.usersList));
  } catch (error) {
    yield put(
      usersSlice.actions.createUserFailed(
        translateErrorList(getErrorList(error?.response?.data?.errors), 'fos_user'),
      ),
    );
    yield call(confirmEditSaga, 'permissionDenied');
  }
}

function* deleteUserSaga({ payload: { userId } }) {
  try {
    yield call(deleteUser, userId);
    yield call(confirmListSaga, 'deleted');
    yield put(usersSlice.actions.deleteUserSuccess(userId));
    yield put(push(ROUTES.usersList));
  } catch (error) {
    yield call(confirmListSaga, 'permissionDenied');
  }
}

function* getRestaurantsListSaga({ payload: { page = 1, paginate = false, scope = 'store' } }) {
  try {
    const items = yield call(getRestaurantsList, { page, paginate, scope });

    yield put(usersSlice.actions.getRestaurantsListSuccess({ items }));
  } catch (error) {
    yield put(usersSlice.actions.getRestaurantsListFailed(error?.response?.data));
  }
}

export function* watchUsers() {
  yield takeLatest(usersSlice.actions.getUsersList, getUsersListSaga);
  yield takeLatest(usersSlice.actions.getUser, getUserSaga);
  yield takeLatest(usersSlice.actions.createUser, createUserSaga);
  yield takeLatest(usersSlice.actions.updateUser, updateUserSaga);
  yield takeLatest(usersSlice.actions.deleteUser, deleteUserSaga);
  yield takeLatest(usersSlice.actions.getRestaurantsList, getRestaurantsListSaga);
}
