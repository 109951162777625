import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Form } from 'components/common/Form';
import { ProfileFormComponent } from 'components/account/ProfileCollapse/ProfileFormComponent';
import { profileSchema } from 'schemas/profileSchema';
import { accountSlice } from 'ducks/account';

export const ProfileForm = ({ profile, setIsModified }) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    ({ gender, firstName, lastName, email, birthday }) => {
      if (profile.email !== email) {
        dispatch(
          accountSlice.actions.changeEmail({
            userId: profile.userId,
            params: { email },
            extraParams: {
              userId: profile.userId,
              id: profile.id,
              params: { gender, firstName, lastName, birthday },
              accountId: profile.accountId,
            },
          }),
        );

        setIsModified();
        return;
      }
      dispatch(
        accountSlice.actions.editProfile({
          userId: profile.userId,
          id: profile.id,
          params: { gender, firstName, lastName, birthday },
          accountId: profile.accountId,
        }),
      );
      setIsModified();
    },
    [dispatch, profile.email, profile.id, profile.accountId, profile.userId, setIsModified],
  );

  return (
    <Form onSubmit={onSubmit} validationSchema={profileSchema}>
      <ProfileFormComponent profile={profile} onSubmit={onSubmit} />
    </Form>
  );
};
ProfileForm.propTypes = {
  setIsModified: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    accountId: PropTypes.string,
    userId: PropTypes.number,
    id: PropTypes.number,
    birthday: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.shape({
      id: PropTypes.number,
      shortTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
};
