import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ImageWithTextListItem } from 'components/common/ImageWithTextListItem';
import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { selectStoreContentById, storeSlice } from 'ducks/store';

export const StoreCustomListItem = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { storeId } = useParams();

  const { showModal } = useModal();

  const storeContentById = useSelector(selectStoreContentById);

  const { imageName: src, description: text } = storeContentById[id];

  const handleEditStoreContent = useCallback(
    (data) => {
      if (data.image.length) {
        dispatch(
          storeSlice.actions.editStoreContentImage({
            id,
            image: data.image[0],
            storeId,
          }),
        );
      }
    },
    [dispatch, id, storeId],
  );

  const handleDeleteStoreContent = useCallback(() => {
    dispatch(storeSlice.actions.deleteStoreContent({ id }));
  }, [dispatch, id]);

  const handleResetCurrentStoreContent = useCallback(() => {
    dispatch(storeSlice.actions.resetCurrentStoreContent());
  }, [dispatch]);

  const onTextEdit = useCallback(
    ({ text: editedText }) => {
      dispatch(
        storeSlice.actions.editStoreContentText({
          id,
          text: editedText,
          storeId,
        }),
      );
    },
    [dispatch, id, storeId],
  );

  const onImageEdit = useCallback(() => {
    dispatch(storeSlice.actions.setCurrentStoreContent({ id, src, alt: src, text }));

    showModal({
      modalType: MODAL_TYPES.editStoreContent,
      title: t('modals.storeContent.editHeader'),
      onSuccess: handleEditStoreContent,
      onClose: handleResetCurrentStoreContent,
    });
  }, [
    dispatch,
    handleEditStoreContent,
    handleResetCurrentStoreContent,
    text,
    id,
    showModal,
    src,
    t,
  ]);

  const onDelete = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.deleteConfirmation,
      title: t('modals.confirmDeleteStoreContent.header'),
      body: t('modals.confirmDeleteContent.body'),
      onSuccess: handleDeleteStoreContent,
    });
  }, [handleDeleteStoreContent, showModal, t]);

  return (
    <ImageWithTextListItem
      src={`https://platform.pommedepain.fr/media/thumbnail/png/512/512/${src}`}
      alt={src}
      text={text}
      onTextEdit={onTextEdit}
      onImageEdit={onImageEdit}
      onDelete={onDelete}
    />
  );
};

StoreCustomListItem.propTypes = {
  id: PropTypes.number.isRequired,
};
