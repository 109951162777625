import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useParams } from 'react-router';

import { ROUTES } from 'constants/routes';
import { selectCurrentStore, storeSlice } from 'ducks/store';
import { Tabs } from 'components/common/Tabs';
import { StoreMenu } from 'containers/store/StoreMenu';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { StoreInformation } from 'containers/store/StoreInformation';
import { StoreCustom } from 'containers/store/StoreCustom';

export const StorePage = () => {
  const location = useLocation();

  const { t } = useTranslation();

  const { storeId } = useParams();

  const dispatch = useDispatch();

  const { name: storeName } = useSelector(selectCurrentStore);

  const storeMenuRoute = generatePath(ROUTES.storeMenu, { storeId });
  const storeInformationRoute = generatePath(ROUTES.storeInformation, { storeId });
  const storeCustomRoute = generatePath(ROUTES.storeCustom, { storeId });

  const tabItems = useMemo(
    () => [
      {
        route: storeMenuRoute,
        title: t('tabs.stores.menu'),
        component: StoreMenu,
      },
      {
        route: storeInformationRoute,
        title: t('tabs.stores.information'),
        component: StoreInformation,
      },
      {
        route: storeCustomRoute,
        title: t('tabs.stores.custom'),
        component: StoreCustom,
      },
    ],
    [storeCustomRoute, storeInformationRoute, storeMenuRoute, t],
  );

  const breadcrumbsItemsArray = useMemo(() => {
    const lastBreadcrumbByRoute = {
      [storeMenuRoute]: 'storePage.menu',
      [storeInformationRoute]: 'storePage.information',
      [storeCustomRoute]: 'storePage.custom',
    };
    return [
      {
        text: t('storePage.stores'),
        route: ROUTES.storesList,
      },
      { text: storeName },
      { text: t(lastBreadcrumbByRoute[location.pathname]) },
    ];
  }, [location, storeCustomRoute, storeInformationRoute, storeMenuRoute, storeName, t]);

  useEffect(() => {
    dispatch(storeSlice.actions.getStoreById(storeId));
  }, [dispatch, storeId]);

  return (
    <Box pt={2} pl={6} pr={6} pb={2} component={Paper}>
      <Box pb={2}>{storeName && <Breadcrumbs breadcrumbsItemsArray={breadcrumbsItemsArray} />}</Box>
      <Tabs items={tabItems} defaultTab={storeMenuRoute} />
    </Box>
  );
};
