import React, { useCallback, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { OrderCardsList } from 'containers/orders/OrderCardsList';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { Tabs } from 'components/common/Tabs/Tabs';
import { ROUTES } from 'constants/routes';
import { useQuery } from 'helpers/hooks';
import { OrdersSearch } from 'components/orders/OrdersSearch';
import { orderSlice } from 'ducks/orders';
import { FILTER_OPTIONS } from 'constants/filterOptions';

const ordersFiltersByRoute = {
  [ROUTES.ordersPendingTab]: FILTER_OPTIONS.accepted,
  [ROUTES.ordersPreparedTab]: FILTER_OPTIONS.pickup,
  [ROUTES.ordersDeliveredTab]: FILTER_OPTIONS.delivered,
  [ROUTES.ordersCanceledTab]: FILTER_OPTIONS.canceled,
  [ROUTES.ordersErrorTab]: FILTER_OPTIONS.failed,
};

export const OrdersPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const location = useLocation();

  const { query, onChangeQuery } = useQuery({
    initialQuery: { filter: ordersFiltersByRoute[location.pathname], page: 1, paid: true },
  });

  const handleChangeTab = useCallback(
    (newTabRoute) => {
      if (newTabRoute !== ROUTES.ordersSearchTab) {
        onChangeQuery({
          page: 1,
          filter: ordersFiltersByRoute[newTabRoute],
          storeName: null, // On other tabs (not search tab) we need to clear all search info from request
          orderId: null,
          customerName: null,
          customerPostalCode: null,
        });
      }
    },
    [onChangeQuery],
  );

  const tabsItems = useMemo(
    () => [
      {
        route: ROUTES.ordersPendingTab,
        title: t('tabs.orders.waiting'),
        component: () => <OrderCardsList onChangeQuery={onChangeQuery} />,
      },
      {
        route: ROUTES.ordersPreparedTab,
        title: t('tabs.orders.pickup'),
        component: () => <OrderCardsList onChangeQuery={onChangeQuery} />,
      },
      {
        route: ROUTES.ordersDeliveredTab,
        title: t('tabs.orders.delivered'),
        component: () => <OrderCardsList onChangeQuery={onChangeQuery} />,
      },
      {
        route: ROUTES.ordersCanceledTab,
        title: t('tabs.orders.canceled'),
        component: () => <OrderCardsList onChangeQuery={onChangeQuery} />,
      },
      {
        route: ROUTES.ordersErrorTab,
        title: t('tabs.orders.failed'),
        component: () => <OrderCardsList onChangeQuery={onChangeQuery} />,
      },
      {
        route: ROUTES.ordersSearchTab,
        title: t('tabs.orders.search'),
        exactVisible: true,
        component: () => <OrderCardsList onChangeQuery={onChangeQuery} />,
      },
    ],
    [onChangeQuery, t],
  );

  const breadcrumbsItems = useMemo(
    () => [
      {
        route: ROUTES.ordersPendingTab,
        text: t('tabs.orders.orders'),
      },
    ],
    [t],
  );

  useEffect(() => {
    dispatch(orderSlice.actions.getPagesInfo(query));
  }, [dispatch, query]);

  return (
    <Box sx={{ backgroundColor: 'white' }} p={2}>
      <Breadcrumbs breadcrumbsItemsArray={breadcrumbsItems} />
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <OrdersSearch onChangeQuery={onChangeQuery} />
      </Box>
      <Tabs items={tabsItems} defaultTab={location.pathname} onChangeTab={handleChangeTab} />
    </Box>
  );
};
