import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { authSlice } from 'ducks/auth';
import { Login } from 'components/login/Login';

export const LoginPage = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data) => {
      dispatch(authSlice.actions.login(data));
    },
    [dispatch],
  );

  return <Login onSubmit={onSubmit} />;
};
