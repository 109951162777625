import request from 'helpers/request';

export const getUsersList = (payload) =>
  request.get('/api/users/search', {
    params: payload,
  });

export const getUser = (userId) => {
  return request({
    method: 'GET',
    url: `/api/users/${userId}`,
    params: {
      limit: 20,
      onlyAdmin: 1,
      page: 1,
      scope: 'user_full,profile_full,account_full,store',
    },
  });
};

export const updateUser = (userId, params) => {
  return request({
    method: 'PATCH',
    url: `/api/users/${userId}`,
    data: params,
    ignoreList: ['plainPassword'],
  });
};

export const updateUsersStores = (userId, params) => {
  return request({
    method: 'PATCH',
    url: `/api/users/${userId}/managed-stores`,
    data: params,
    ignoreList: ['managedStores'],
  });
};

export const createUser = (params) => {
  return request.post('/api/users/', params, { ignoreList: ['plainPassword', 'managedStores'] });
};

export const deleteUser = (userId) => {
  return request({
    method: 'DELETE',
    url: `/api/users/${userId}`,
  });
};

export const getRestaurantsList = ({ page, paginate, scope }) =>
  request.get('/api/stores/search', {
    params: { page, scope, paginate },
  });
