import React from 'react';
import { Box, Button, Paper, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Popper } from 'components/common/Popper';

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.success.main,
  '&:hover': {
    backgroundColor: theme.palette.button.success.hover,
  },
}));

export const StorePopper = ({ isOpen }) => {
  const { t } = useTranslation();

  return (
    <Popper isOpen={isOpen} placement="bottom-end" anchorEl={document.body}>
      <Box
        maxWidth={300}
        p={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor="popper.info"
        component={Paper}
      >
        <Typography pl={1} pr={1} color="white">
          {t('storePage.popper.text')}
        </Typography>
        <SubmitButton variant="contained" type="submit">
          {t('storePage.popper.confirm')}
        </SubmitButton>
      </Box>
    </Popper>
  );
};

StorePopper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
