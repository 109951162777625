import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Form } from 'components/common/Form';
import { ROUTES } from 'constants/routes';
import { SearchField } from 'components/common/SearchField';
import { OrdersForm } from 'components/orders/OrdersForm';
import { useToggle } from 'helpers/hooks';

export const OrdersSearch = React.memo(({ onChangeQuery }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation();

  const [isResetNeeded, toggleIsResetNeeded] = useToggle(false);

  const onSubmit = useCallback(
    (data) => {
      onChangeQuery({ ...data, page: 1 });
      if (location.pathname !== ROUTES.ordersSearchTab) {
        history.push(ROUTES.ordersSearchTab);
      }
    },
    [history, location, onChangeQuery],
  );

  useEffect(() => {
    if (location.pathname !== ROUTES.ordersSearchTab) {
      toggleIsResetNeeded();
    }
  }, [location, toggleIsResetNeeded]);

  return (
    <Form onSubmit={onSubmit}>
      <SearchField
        label={t('ordersPage.search.orderId')}
        name="orderId"
        onSubmit={onSubmit}
        isResetNeeded={isResetNeeded}
        toggleIsResetNeeded={toggleIsResetNeeded}
      >
        {(props) => <OrdersForm {...props} />}
      </SearchField>
    </Form>
  );
});

OrdersSearch.propTypes = {
  onChangeQuery: PropTypes.func.isRequired,
};
