import React, { useMemo } from 'react';
import { Fab as MuiFab, Box, styled, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';

import { TextField } from 'components/common/TextField/TextField';
import { GENRES } from 'constants/genres';
import { SelectField } from 'components/common/SelectField/SelectField';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.button.success.main,
    },
  },
}));

const Fab = styled(MuiFab)(({ theme }) => ({
  backgroundColor: theme.palette.button.success.main,
  color: theme.palette.white,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.button.success.hover,
  },
  marginRight: theme.spacing(2),
}));

export const ProfileFormComponent = React.memo(({ profile }) => {
  const { t } = useTranslation();
  const { reset } = useFormContext();

  const genderOptions = useMemo(
    () =>
      Object.entries(GENRES).map(([key, value]) => ({
        id: key,
        value: t(`accountInfo.${value}`),
      })),
    [t],
  );

  React.useEffect(() => {
    reset({ ...profile, gender: profile.gender.id });
  }, [reset, profile]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" px={2}>
      <SelectField
        label={t('accountInfo.gender')}
        name="gender"
        options={genderOptions}
        sx={{ '& fieldset': { borderColor: 'button.success.main' } }}
      />
      <StyledTextField
        name="firstName"
        label={t('accountInfo.firstName')}
        color="success"
        isErrorDisplay
      />
      <StyledTextField
        name="lastName"
        label={t('accountInfo.lastName')}
        color="success"
        isErrorDisplay
      />
      <StyledTextField name="email" label={t('accountInfo.email')} color="success" isErrorDisplay />
      <StyledTextField
        name="birthday"
        label={t('accountInfo.dateOfBirth')}
        color="success"
        isErrorDisplay
      />
      <Box display="flex" justifyContent="flex-end">
        <Fab size="small" type="submit" component={Button}>
          <CheckIcon />
        </Fab>
      </Box>
    </Box>
  );
});

ProfileFormComponent.propTypes = {
  profile: PropTypes.shape({
    birthday: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.shape({
      id: PropTypes.number,
      shortTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
};

ProfileFormComponent.defaultProps = {
  profile: { birthday: null },
};
