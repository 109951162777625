import request from 'helpers/request';

export const getAccount = (id) => {
  return request.get(`/api/accounts/${id}`, {
    params: {
      scope: 'card_full,user',
    },
  });
};

export const getUserProfile = (id) => request.get(`/api/users/${id}/profile`);

export const getCardsAccounts = (id) =>
  request.get(`/api/cards/accounts/${id}`, {
    params: {
      scope: 'card_full,account_full',
    },
  });

export const getProfile = (id) =>
  request.get(`/api/profiles/${id}`, {
    params: {
      scope: 'profile_full',
    },
  });

export const getAccountsUsers = (id) => request.get(`/api/accounts/users/${id}`);

export const getHistory = ({ id, params }) => request.get(`/api/loyalty-history/${id}`, { params });

export const setDefaultPassword = (id) => request.post(`/api/users/${id}/set-default-password`);

export const editProfile = (id, params) =>
  request.request({
    method: 'PATCH',
    url: `/api/profiles/${id}`,
    data: params,
    ignoreList: ['firstName', 'lastName'],
  });

export const changeEmailRequest = (id, params) =>
  request.post(`/api/users/${id}/change-email-request`, {
    ...params,
  });

export const changeEmail = (token) => request.get(`/api/users/${token}/change-email`);

export const editBalance = (id, params) =>
  request.post(`/api/balance-update-request/users/${id}`, {
    ...params,
  });

export const activateAccount = (id) => request.post(`/api/users/${id}/activate`);

export const deactivateAccount = (id) => request.post(`/api/users/${id}/expire`);
