import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ImageWithTextListItem } from 'components/common/ImageWithTextListItem';
import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { newsSlice, selectNewsById } from 'ducks/news';

export const NewsItem = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { showModal } = useModal();

  const newsById = useSelector(selectNewsById);

  const { imageName: src, title, description: text } = newsById[id];

  const handleEditNewsItemImage = useCallback(
    (data) => {
      if (data.image.length) {
        dispatch(
          newsSlice.actions.editNewsItemImage({
            id,
            image: data.image[0],
          }),
        );
      }
    },
    [dispatch, id],
  );

  const handleDeleteNewsItem = useCallback(() => {
    dispatch(newsSlice.actions.deleteNewsItem({ id }));
  }, [dispatch, id]);

  const handleResetCurrentNewsItem = useCallback(() => {
    dispatch(newsSlice.actions.resetCurrentNewsItem());
  }, [dispatch]);

  const onTextEdit = useCallback(
    ({ title: editedTitle, text: editedText }) => {
      dispatch(
        newsSlice.actions.editNewsItemText({
          id,
          title: editedTitle,
          text: editedText,
        }),
      );
    },
    [dispatch, id],
  );

  const onImageEdit = useCallback(() => {
    dispatch(newsSlice.actions.setCurrentNewsItem({ id, src, alt: src, title, text }));

    showModal({
      modalType: MODAL_TYPES.editNews,
      title: t('modals.news.editHeader'),
      onSuccess: handleEditNewsItemImage,
      onClose: handleResetCurrentNewsItem,
    });
  }, [
    dispatch,
    handleEditNewsItemImage,
    handleResetCurrentNewsItem,
    title,
    text,
    id,
    showModal,
    src,
    t,
  ]);

  const onDelete = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.deleteConfirmation,
      title: t('modals.confirmDeleteNews.header'),
      body: t('modals.confirmDeleteNews.body'),
      onSuccess: handleDeleteNewsItem,
    });
  }, [handleDeleteNewsItem, showModal, t]);

  return (
    <ImageWithTextListItem
      src={`https://platform.pommedepain.fr/media/thumbnail/png/512/512/${src}`}
      alt={src}
      title={title}
      isTitleVisible
      text={text}
      onTextEdit={onTextEdit}
      onImageEdit={onImageEdit}
      onDelete={onDelete}
    />
  );
};

NewsItem.propTypes = {
  id: PropTypes.number.isRequired,
};
