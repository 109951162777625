import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { PasswordField } from 'components/common/PasswordField';

const triggerNames = ['password', 'confirmPassword'];

export const ChangePasswordForm = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" ml={1} mr={1}>
      <PasswordField
        isErrorDisplay={false}
        name="oldPassword"
        label={t('modals.changePasswordModal.enterCurrentPassword')}
      />
      <PasswordField
        namesToTrigger={triggerNames}
        isErrorDisplay
        label={t('modals.changePasswordModal.newPassword')}
      />
      <PasswordField
        isErrorDisplay
        name="confirmPassword"
        label={t('modals.changePasswordModal.passwordConfirmation')}
      />
    </Box>
  );
});
