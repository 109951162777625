import React from 'react';
import { generatePath } from 'react-router';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

export const AccountActions = ({
  row: {
    account: { accountId },
  },
}) => {
  return accountId ? (
    <IconButton size="small" component={Link} to={generatePath(ROUTES.account, { accountId })}>
      <FontAwesomeIcon icon={faEdit} />
    </IconButton>
  ) : null;
};

AccountActions.propTypes = {
  row: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
  ).isRequired,
};
