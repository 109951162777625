import React from 'react';
import {
  styled,
  TableBody,
  TableHead,
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableRow as MuiTableRow,
} from '@mui/material';
import PropTypes from 'prop-types';

import { TableRow } from 'components/common/Table/TableRow';
import { TableHeaderCell } from 'components/common/Table/TableHeaderCell';

const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.table.border,
  borderBottomWidth: 0,
}));

export const Table = React.memo(({ columns, rows, query, onChangeQuery, isHeaderTransparent }) => {
  const {
    sort: { order: orderDirection, orderBy: orderedBy },
  } = query;

  return (
    <TableContainer component="div">
      <MuiTable size="small">
        <TableHead>
          <MuiTableRow>
            {columns.map(({ name, field, isSortable, isSortDouble }) => (
              <TableHeaderCell
                key={name}
                name={name}
                field={field || name}
                isSortable={isSortable}
                orderDirection={orderDirection}
                orderedBy={orderedBy}
                onChangeQuery={onChangeQuery}
                isTransparent={isHeaderTransparent}
                isSortDouble={isSortDouble}
              />
            ))}
          </MuiTableRow>
        </TableHead>
        <TableBody>
          {Boolean(rows.length) &&
            rows.map((row) => <TableRow key={row.id} row={row} columns={columns} />)}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
});

Table.defaultProps = {
  onChangeQuery: () => {},
  query: { sort: { order: 'none' } },
  isHeaderTransparent: false,
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      field: PropTypes.string,
      cell: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }).isRequired,
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.bool.isRequired,
        PropTypes.object.isRequired,
        PropTypes.array.isRequired,
      ]),
    ).isRequired,
  ).isRequired,
  query: PropTypes.shape({
    sort: PropTypes.shape({
      order: PropTypes.string.isRequired,
      orderBy: PropTypes.string,
    }).isRequired,
  }),
  onChangeQuery: PropTypes.func,
  isHeaderTransparent: PropTypes.bool,
};
