import React from 'react';
import { Accordion, AccordionDetails, Box } from '@mui/material';
import PropTypes from 'prop-types';

import { AccordionHeader } from 'components/common/CollapseBlock/AccordionHeader';

export const CollapseItem = React.memo(
  ({ title, component: Component, action, isExpanded, isFirst, onChange }) => {
    const handleChange = React.useCallback(
      (event, expanded) => {
        onChange(title, expanded);
      },
      [title, onChange],
    );

    return (
      <Box m={1} key={title}>
        <Accordion
          disableGutters
          square
          expanded={isExpanded}
          onChange={handleChange}
          sx={{ boxShadow: 'none' }}
        >
          <AccordionHeader expanded={isExpanded} title={title} action={action} isFirst={isFirst} />
          <AccordionDetails>
            <Component />
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  },
);

CollapseItem.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  action: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  isExpanded: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

CollapseItem.defaultProps = {
  action: { label: '', onClick: () => {} },
};
