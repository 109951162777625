import React from 'react';
import { useFormContext } from 'react-hook-form';
import MuiButton from '@mui/material/Button';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';

/**
 * Modify the style of MuiButton to cancel the uppercase text
 */
const Button = styled(MuiButton)(() => ({
  textTransform: 'none',
}));

export const SubmitButtonWithOnlyValidData = React.memo(({ children, ...props }) => {
  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <Button disabled={!isValid} type="submit" variant="contained" {...props}>
      {children}
    </Button>
  );
});

SubmitButtonWithOnlyValidData.propTypes = {
  children: PropTypes.node.isRequired,
};
