import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router';

import {
  pushNotificationsSlice,
  selectNotificationMeta,
  selectTriggerList,
  selectTriggersState,
} from 'ducks/pushNotifications';
import { HistoryTable } from 'components/pushNotifications/HistoryTable';
import { useQuery } from 'helpers/hooks';
import { formatDate } from 'helpers/helpers';
import { DATE_FORMATS } from 'constants/dateFormats';
import { HistoryTableActions } from 'containers/pushNotifications/AutoHistory/HistoryTableActions';
import { ROUTES } from 'constants/routes';

export const AutoNotificationsHistory = ({ isActive }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const location = useLocation();

  const { isLoading, isSent, isEdited, isDeleted } = useSelector(selectTriggersState);

  const { triggersPage, triggersLastPage } = useSelector(selectNotificationMeta);

  const rows = useSelector(selectTriggerList);

  const { query, onChangeQuery } = useQuery({
    initialQuery: {
      page: triggersPage,
      limit: 20,
      automatic: true,
      active: isActive,
      sort: { orderBy: 'id', order: 'desc' },
    },
  });

  const onChangePage = useCallback(
    (event, newPage) => {
      if (newPage) {
        onChangeQuery({ page: newPage });
      }
    },
    [onChangeQuery],
  );

  const columnsActive = useMemo(
    () => [
      {
        name: t('pushNotificationsPage.triggers.trigger'),
        field: 'trigger',
        cell: ({ row }) => t(`pushNotificationsPage.triggers.${row.trigger}`),
      },
      {
        name: t('pushNotificationsPage.triggers.content'),
        field: 'content',
        cell: ({ row }) => (
          <Typography fontSize={13} sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
            {row.content}
          </Typography>
        ),
      },
      {
        name: t('pushNotificationsPage.triggers.createdAt'),
        cell: ({ row }) => formatDate(row.createdAt, DATE_FORMATS.accountDateFormat),
      },
      {
        name: t('pushNotificationsPage.history.actions'),
        cell: HistoryTableActions,
      },
    ],
    [t],
  );

  const columnsNotActive = useMemo(
    () => [
      {
        name: t('pushNotificationsPage.triggers.trigger'),
        field: 'trigger',
        cell: ({ row }) => t(`pushNotificationsPage.triggers.${row.trigger}`),
      },
      {
        name: t('pushNotificationsPage.triggers.content'),
        field: 'content',
        cell: ({ row }) => (
          <Typography fontSize={13} sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
            {row.content}
          </Typography>
        ),
      },
      {
        name: t('pushNotificationsPage.triggers.createdAt'),
        cell: ({ row }) => formatDate(row.createdAt, DATE_FORMATS.accountDateFormat),
      },
    ],
    [t],
  );

  useEffect(() => {
    if (isSent) {
      dispatch(pushNotificationsSlice.actions.getAutoNotificationList(query));
    }
  }, [dispatch, isSent, query]);

  useEffect(() => {
    dispatch(pushNotificationsSlice.actions.getAutoNotificationList(query));
  }, [dispatch, query]);

  useEffect(() => {
    if (isEdited) {
      onChangeQuery({ page: 1 });
      dispatch(pushNotificationsSlice.actions.resetTriggerTemplate());
    }
  }, [dispatch, isEdited, onChangeQuery]);

  useEffect(() => {
    if (isDeleted) {
      onChangeQuery({ page: 1 });
      dispatch(pushNotificationsSlice.actions.resetTriggerTemplate());
    }
  }, [dispatch, isDeleted, onChangeQuery]);

  useEffect(() => {
    return () => {
      dispatch(pushNotificationsSlice.actions.setTriggersPage(1));
    };
  }, [dispatch]);

  return (
    <HistoryTable
      isLoading={isLoading}
      rows={rows}
      columns={
        location.pathname === ROUTES.notificationsHistoryTab ? columnsNotActive : columnsActive
      }
      page={triggersPage}
      lastPage={triggersLastPage}
      onChangePage={onChangePage}
    />
  );
};

AutoNotificationsHistory.defaultProps = {
  isActive: undefined,
};

AutoNotificationsHistory.propTypes = {
  isActive: PropTypes.bool,
};
