import * as yup from 'yup';

function checkStartDate() {
  if (this.parent.startDateIntervalFrom && !this.parent.startDateIntervalTo) {
    return false;
  }
  return !(!this.parent.startDateIntervalFrom && this.parent.startDateIntervalTo);
}

function checkEndDate() {
  if (this.parent.endDateIntervalFrom && !this.parent.endDateIntervalTo) {
    return false;
  }
  return !(!this.parent.endDateIntervalFrom && this.parent.endDateIntervalTo);
}

export const productSearchSchema = yup.object().shape({
  startDateIntervalFrom: yup.mixed().test('date', 'date.bothDates', checkStartDate),
  startDateIntervalTo: yup.mixed().test('date', 'date.bothDates', checkStartDate),
  endDateIntervalFrom: yup.mixed().test('date', 'date.bothDates', checkEndDate),
  endDateIntervalTo: yup.mixed().test('date', 'date.bothDates', checkEndDate),
});
