import React from 'react';
import PropTypes from 'prop-types';
import { Box, Pagination } from '@mui/material';

export const TablePagination = React.memo(({ page, lastPage, handleChangePage }) => (
  <Box display="flex" justifyContent="center" pt={4} pb={4}>
    <Pagination
      count={lastPage}
      page={page}
      siblingCount={2}
      onChange={handleChangePage}
      showFirstButton
      showLastButton
    />
  </Box>
));

TablePagination.propTypes = {
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};
