import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isBoolean } from 'lodash';

import { ManualNotificationForm } from 'components/pushNotifications/ManualNotificationForm';
import { Form } from 'components/common/Form';
import {
  pushNotificationsSlice,
  selectNotificationsState,
  selectNotificationTemplate,
} from 'ducks/pushNotifications';
import { manualNotificationSchema } from 'schemas';
import { useNotification } from 'helpers/hooks';
import { STATUSES } from 'constants/statuses';

export const CreateManualNotification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pushNotificationTemplate = useSelector(selectNotificationTemplate);
  const { isSent } = useSelector(selectNotificationsState);

  const { showNotification } = useNotification();

  const onSubmit = useCallback(
    (data) => {
      dispatch(pushNotificationsSlice.actions.sendManualNotification(data));
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(pushNotificationsSlice.actions.resetNotificationTemplate());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isBoolean(isSent)) {
      showNotification({
        notificationType: isSent ? STATUSES.success : STATUSES.error,
        title: isSent
          ? t('notifications.pushNotificationCreated')
          : t('notifications.pushNotificationNotCreated'),
      });
    }
  }, [isSent, showNotification, t]);

  return (
    <Form onSubmit={onSubmit} validationSchema={manualNotificationSchema}>
      <ManualNotificationForm
        defaultValues={pushNotificationTemplate}
        buttonText="pushNotificationsPage.buttons.send"
      />
    </Form>
  );
};
