import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Form } from 'components/common/Form';
import { SearchField } from 'components/common/SearchField';
import { StoreForm } from 'components/store/StoreForm';
import { productSearchSchema } from 'schemas';

export const StoreSearch = React.memo(({ onChangeQuery }) => {
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (data) => {
      onChangeQuery({ search: data, page: 1 });
    },
    [onChangeQuery],
  );

  return (
    <Box display="flex" justifyContent="center" mb={-5}>
      <Form onSubmit={onSubmit} validationSchema={productSearchSchema}>
        <SearchField label={t('storePage.search.name')} name="productName" onSubmit={onSubmit}>
          {(props) => <StoreForm {...props} />}
        </SearchField>
      </Form>
    </Box>
  );
});

StoreSearch.propTypes = {
  onChangeQuery: PropTypes.func.isRequired,
};
