import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  selectStoresItems,
  selectStoresMeta,
  selectStoresTableIsLoading,
  storesSlice,
} from 'ducks/stores';
import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { ROUTES } from 'constants/routes';
import { useQuery } from 'helpers/hooks';
import { StoresSearch } from 'components/stores/StoresSearch';
import { StoresTable } from 'components/stores/StoresTable';

export const StoresPage = React.memo(() => {
  const { t } = useTranslation();

  const { currentPage: page, lastPage } = useSelector(selectStoresMeta);

  const items = useSelector(selectStoresItems);

  const isLoading = useSelector(selectStoresTableIsLoading);

  const dispatch = useDispatch();

  const { query, onChangeQuery } = useQuery({
    initialQuery: { page, search: {}, sort: { order: 'none' } },
  });

  const breadcrumbsItemsArray = useMemo(
    () => [
      {
        text: t('storesPage.stores'),
        route: ROUTES.storesList,
      },
      { text: t('storesPage.all') },
    ],
    [t],
  );

  useEffect(() => {
    dispatch(storesSlice.actions.getStoresList(query));
  }, [dispatch, query]);

  useEffect(() => {
    return () => {
      dispatch(storesSlice.actions.setCurrentPage(1));
    };
  }, [dispatch]);

  return (
    <Box p={2} component={Paper}>
      <Box pb={2}>
        <Breadcrumbs breadcrumbsItemsArray={breadcrumbsItemsArray} />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <StoresSearch onChangeQuery={onChangeQuery} />
      </Box>
      <StoresTable
        query={query}
        rows={items}
        page={page}
        lastPage={lastPage}
        isLoading={isLoading}
        onChangeQuery={onChangeQuery}
      />
    </Box>
  );
});
