import { Button as MuiButton, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.accordion.button,
  '&:hover': {
    backgroundColor: theme.palette.accordion.hover,
  },
  color: theme.palette.accordion.details,
  borderRadius: 0,
  fontSize: 12,
  padding: theme.spacing(0, 2),
}));

export const DeactivateCollapseComponent = React.memo(({ handleConfirm }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="button" typography={{ textTransform: 'uppercase' }} gutterBottom>
        {t('disableAccount.title')}
      </Typography>
      <Typography variant="body2">{t('disableAccount.description')}</Typography>
      <Button onClick={handleConfirm}>{t('disableAccount.confirm')}</Button>
    </>
  );
});

DeactivateCollapseComponent.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
};
