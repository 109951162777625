import { AcknowledgmentModal } from 'containers/modals/AcknowledgmentModal';
import { Confirmation } from 'containers/modals/Confirmation';
import { ChangePassword } from 'containers/modals/ChangePassword';
import { ConfirmationWithList } from 'containers/modals/ConfirmationWithList';
import { EditBalance } from 'containers/modals/EditBalance';
import { DeleteConfirmation } from 'containers/modals/DeleteConfirmation';
import { EditStoreContent } from 'containers/modals/EditStoreContent';
import { AddStoreContent } from 'containers/modals/AddStoreContent';
import { EditMobileImage } from 'containers/modals/EditMobileImage';
import { AddMobileImage } from 'containers/modals/AddMobileImage';
import { EditNews } from 'containers/modals/EditNews';
import { AddNews } from 'containers/modals/AddNews';

export const MODAL_TYPES = {
  confirmation: 'confirmation',
  confirmationWithList: 'confirmation with list',
  deleteConfirmation: 'delete confirmation',
  passwordChange: 'password change',
  acknowledgment: 'acknowledgment',
  editBalance: 'edit balance',
  addStoreContent: 'add store content',
  editStoreContent: 'add edit store content',
  addMobileImage: 'add mobile image',
  editMobileImage: 'edit mobile image',
  addNews: 'add news item',
  editNews: 'edit news item',
};

export const MODAL_COMPONENTS_BY_TYPES = {
  [MODAL_TYPES.confirmation]: Confirmation,
  [MODAL_TYPES.confirmationWithList]: ConfirmationWithList,
  [MODAL_TYPES.deleteConfirmation]: DeleteConfirmation,
  [MODAL_TYPES.passwordChange]: ChangePassword,
  [MODAL_TYPES.acknowledgment]: AcknowledgmentModal,
  [MODAL_TYPES.editBalance]: EditBalance,
  [MODAL_TYPES.addStoreContent]: AddStoreContent,
  [MODAL_TYPES.editStoreContent]: EditStoreContent,
  [MODAL_TYPES.addMobileImage]: AddMobileImage,
  [MODAL_TYPES.editMobileImage]: EditMobileImage,
  [MODAL_TYPES.addNews]: AddNews,
  [MODAL_TYPES.editNews]: EditNews,
};
