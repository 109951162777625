import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogActions, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MobileImageForm } from 'components/common/MobileImageForm';
import { mobileImageSchema } from 'schemas/mobileImageSchema';
import { Form, SubmitButtonWithOnlyValidData } from 'components/common/Form';

const SubmitButton = styled(SubmitButtonWithOnlyValidData)(({ theme }) => ({
  backgroundColor: theme.palette.button.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.button.primary.hover,
  },
  '&:disabled': {
    opacity: 0.65,
    color: theme.palette.white,
    backgroundColor: theme.palette.button.primary.main,
  },
}));

const WarningButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.button.warning.hover,
  },
}));

export const AddEditMobileImageComponent = React.memo(
  ({ defaultValues, handleSuccess, handleCancel, children }) => {
    const { t } = useTranslation();

    return (
      <Box ml={1} mr={1}>
        <Form onSubmit={handleSuccess} validationSchema={mobileImageSchema}>
          <MobileImageForm defaultValues={defaultValues}>{children}</MobileImageForm>
          <DialogActions>
            <WarningButton variant="contained" onClick={handleCancel}>
              {t('modals.changePasswordModal.cancel')}
            </WarningButton>
            <SubmitButton> {t('modals.changePasswordModal.confirm')}</SubmitButton>
          </DialogActions>
        </Form>
      </Box>
    );
  },
);

AddEditMobileImageComponent.defaultProps = {
  children: <></>,
};

AddEditMobileImageComponent.propTypes = {
  defaultValues: PropTypes.shape({
    id: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
  }).isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
};
