import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { OrdersDetailed } from 'components/orders/OrdersDetailed';
import { orderSlice, selectOrder } from 'ducks/orders';

export const OrderPage = () => {
  const dispatch = useDispatch();
  const { order } = useSelector(selectOrder);
  const { orderId } = useParams();

  useEffect(() => {
    dispatch(orderSlice.actions.getOrderById(orderId));
  }, [dispatch, orderId]);

  return <OrdersDetailed orderData={order} orderId={orderId} />;
};
