import React from 'react';
import { Backdrop as MuiBackdrop, Box, CircularProgress, styled } from '@mui/material';
import PropTypes from 'prop-types';

const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
  color: 'rgba(0,0,0,0.5)',
  backgroundColor: 'rgba(256, 256, 256, 0.5)',
  zIndex: theme.zIndex.drawer + 1,
  position: 'absolute',
}));

export const Loader = ({ isLoading, children }) => (
  <Box p={4} sx={{ position: 'relative' }}>
    <Backdrop open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
    {children}
  </Box>
);

Loader.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};
Loader.defaultProps = {
  children: <div />,
  isLoading: true,
};
