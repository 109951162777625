import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { BaseModalDialog } from 'components/common/BaseModalDialog';
import { BaseModalDialogTitle } from 'components/common/BaseModalDialog/BaseModalDialogTitle';
import { useModal } from 'helpers/hooks';
import { ChangePasswordComponent } from 'components/modals/ChangePasswordComponent';

export const ChangePassword = ({ id, title, onSuccess, onCancel, onClose }) => {
  const { closeModal } = useModal(id);

  const handleSuccess = useCallback(
    (data) => {
      onSuccess(data);
      closeModal();
    },
    [closeModal, onSuccess],
  );

  const handleCancel = useCallback(() => {
    onCancel();
    closeModal();
  }, [closeModal, onCancel]);

  const handleClose = useCallback(() => {
    onClose();
    closeModal();
  }, [closeModal, onClose]);

  return (
    <BaseModalDialog handleClose={handleClose}>
      <BaseModalDialogTitle>{title}</BaseModalDialogTitle>
      <ChangePasswordComponent handleCancel={handleCancel} handleSuccess={handleSuccess} />
    </BaseModalDialog>
  );
};

ChangePassword.defaultProps = {
  title: '',
  onSuccess: () => {},
  onCancel: () => {},
  onClose: () => {},
};

ChangePassword.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};
