import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  styled,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BaseModalDialog } from 'components/common/BaseModalDialog';
import { BaseModalDialogTitle } from 'components/common/BaseModalDialog/BaseModalDialogTitle';

const WarningButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.button.warning.hover,
  },
}));

const PrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.button.primary.hover,
  },
}));

export const ConfirmationComponent = React.memo(
  ({ title, body, handleClose, handleCancel, handleSuccess }) => {
    const { t } = useTranslation();
    return (
      <BaseModalDialog handleClose={handleClose}>
        <BaseModalDialogTitle onClose={handleClose}>{title}</BaseModalDialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText color="body.text" fontSize={14}>
            {body}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <WarningButton variant="contained" onClick={handleCancel}>
            {t('common.no')}
          </WarningButton>
          <PrimaryButton variant="contained" onClick={handleSuccess} autoFocus>
            {t('common.yes')}
          </PrimaryButton>
        </DialogActions>
      </BaseModalDialog>
    );
  },
);

ConfirmationComponent.defaultProps = {
  title: '',
  body: '',
};

ConfirmationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};
