import { takeLatest, put, call } from 'redux-saga/effects';

import {
  addMobileImage,
  deleteMobileImage,
  editMobileImage,
  getMobileImagesList,
  sendNewListOrder,
} from 'ducks/mobileImages/services';
import { mobileImagesSlice } from 'ducks/mobileImages/reducer';
import { normalize } from 'ducks/mapper';

function* getMobileImagesListSaga() {
  try {
    const items = yield call(getMobileImagesList);

    yield put(mobileImagesSlice.actions.getMobileImagesListSuccess({ items: normalize(items) }));
  } catch (error) {
    yield put(mobileImagesSlice.actions.getMobileImagesListFailed(error?.response?.data));
  }
}

function* addMobileImageSaga({ payload: { image, hyperlink } }) {
  try {
    yield call(addMobileImage, { image, hyperlink });

    yield put(mobileImagesSlice.actions.addMobileImageSuccess());

    yield put(mobileImagesSlice.actions.getMobileImagesList());
  } catch (error) {
    yield put(mobileImagesSlice.actions.addMobileImageFailed(error?.response?.data));
  }
}

function* editMobileImageSaga({ payload: { id, image } }) {
  try {
    yield call(editMobileImage, { id, image });

    yield put(mobileImagesSlice.actions.editMobileImageSuccess());
    yield put(mobileImagesSlice.actions.getMobileImagesList());
  } catch (error) {
    yield put(mobileImagesSlice.actions.editMobileImageFailed(error?.response?.data));
  }
}

function* editHyperlinkSaga({ payload: { id, hyperlink } }) {
  try {
    yield call(editMobileImage, { id, hyperlink });

    yield put(mobileImagesSlice.actions.editHyperlinkSuccess({ id, hyperlink }));
    yield put(mobileImagesSlice.actions.getMobileImagesList());
  } catch (error) {
    yield put(mobileImagesSlice.actions.editHyperlinkFailed(error?.response?.data));
  }
}

function* deleteMobileImageSaga({ payload: { id } }) {
  try {
    yield call(deleteMobileImage, { id });

    yield put(mobileImagesSlice.actions.deleteMobileImageSuccess({ id }));
  } catch (error) {
    yield put(mobileImagesSlice.actions.deleteMobileImageFailed(error?.response?.data));
  }
}

function* sendNewListOrderSaga({ payload: { newOrder } }) {
  try {
    yield call(sendNewListOrder, { newOrder });

    yield put(mobileImagesSlice.actions.sendNewListOrderSuccess({ newOrder }));
    yield put(mobileImagesSlice.actions.getMobileImagesList());
  } catch (error) {
    yield put(mobileImagesSlice.actions.sendNewListOrderFailed(error?.response?.data));
  }
}

export function* watchMobileImages() {
  yield takeLatest(mobileImagesSlice.actions.getMobileImagesList, getMobileImagesListSaga);
  yield takeLatest(mobileImagesSlice.actions.addMobileImage, addMobileImageSaga);
  yield takeLatest(mobileImagesSlice.actions.editMobileImage, editMobileImageSaga);
  yield takeLatest(mobileImagesSlice.actions.editHyperlink, editHyperlinkSaga);
  yield takeLatest(mobileImagesSlice.actions.deleteMobileImage, deleteMobileImageSaga);
  yield takeLatest(mobileImagesSlice.actions.sendNewListOrder, sendNewListOrderSaga);
}
