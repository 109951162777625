import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { Table } from 'components/common/Table';
import { TablePagination } from 'components/common/TablePagination';
import { Loader } from 'components/common/Loader';

import { AccountActions } from '../AccountActions';

export const AccountsTable = ({
  isLoading,
  accountsList,
  pageCount,
  query,
  onChangeQuery,
  page,
  handleChangePage,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        name: t('accountInfo.id'),
        field: 'id',
        cell: ({ row: { user } }) => user.id,
      },
      {
        name: t('accountInfo.firstName'),
        field: 'firstName',
        isSortable: true,
        isSortDouble: true,
        cell: ({ row: { user } }) => user.firstName,
      },
      {
        name: t('accountInfo.lastName'),
        field: 'lastName',
        isSortable: true,
        isSortDouble: true,
        cell: ({ row: { user } }) => user.lastName,
      },
      {
        name: t('accountInfo.email'),
        field: 'email',
        isSortable: true,
        isSortDouble: true,
        cell: ({ row: { user } }) => user.email,
      },
      {
        name: t('accountInfo.cardNumber'),
        field: 'number',
      },
      {
        name: t('accountInfo.enabled'),
        field: 'enabled',
        cell: ({ row: { user } }) => (user.enabled ? t('common.yes') : t('common.no')),
      },
      {
        name: t('accountInfo.balance'),
        field: 'balance',
        cell: ({ row: { account } }) => t('account.euroValue', { value: account.balance / 100 }),
      },
      {
        name: '',
        cell: AccountActions,
      },
    ],
    [t],
  );

  return (
    <Loader isLoading={isLoading}>
      <Table columns={columns} rows={accountsList} query={query} onChangeQuery={onChangeQuery} />
      {!accountsList.length && (
        <Typography sx={{ textAlign: 'center', marginTop: 5 }} variant="h5">
          {t('account.noData')}
        </Typography>
      )}
      <TablePagination page={page} lastPage={pageCount} handleChangePage={handleChangePage} />
    </Loader>
  );
};

AccountsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  accountsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
    }),
  ),
  query: PropTypes.shape({
    limit: PropTypes.number,
    page: PropTypes.number,
    sort: PropTypes.shape({
      order: PropTypes.string,
      orderBy: PropTypes.string,
    }),
  }),
  onChangeQuery: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func.isRequired,
};
AccountsTable.defaultProps = {
  accountsList: [],
  pageCount: 1,
  page: 1,
  query: {
    limit: 20,
    page: 1,
    sort: {
      order: 'asc',
      orderBy: 'lastName',
    },
  },
};
