import request from 'helpers/request';

export const getStoreById = (id) =>
  request.get(`/api/stores/${id}`, {
    params: {
      scope: 'store_service_full,schedule_full',
    },
  });

export const getProductsList = ({
  exclude,
  limit,
  order,
  orderBy,
  page,
  scope,
  storeId,
  systemId,
  productName,
  category,
  deliveryStatus,
  startDateIntervalFrom,
  endDateIntervalFrom,
  startDateIntervalTo,
  endDateIntervalTo,
}) =>
  request.get('/api/products/search', {
    params: {
      exclude,
      limit,
      order,
      orderBy,
      page,
      scope,
      storeId,
      systemId,
      productName,
      category,
      deliveryStatus,
      startDateIntervalFrom,
      startDateIntervalTo,
      endDateIntervalFrom,
      endDateIntervalTo,
    },
    ignoreList: ['orderBy'],
  });

export const changeDeliveryStatus = ({ storeId, productId, issue }) =>
  request.put(`/api/stores/${storeId}/products/${productId}/delivery`, { issue });

export const getServicesList = () => request.get('/api/stores-services/');

export const toggleStoreService = (id, type) => request.put(`/api/stores/${id}/${type}`);

export const changeStoreSchedule = (id, params) =>
  request.patch(
    `/api/stores/${id}/schedule`,
    {
      ...params,
    },
    { ignoreList: ['scheduleId'] },
  );

export const getStoreContentList = ({ storeId }) =>
  request.get('/api/content-management', {
    params: { category: 'restaurant', storeId, paginate: false },
  });

export const addStoreContent = ({ image, text, storeId }) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('category', 'restaurant');
  formData.append('description', text);
  formData.append('store', storeId);

  return request.post('/api/content-management/images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const editStoreContent = ({ id, image, text, storeId }) => {
  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
  formData.append('category', 'restaurant');
  formData.append('store', storeId);
  if (text || text === '') {
    formData.append('description', text);
  }

  return request.post(`/api/content-management/${id}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteStoreContent = ({ id }) =>
  request.delete(`/api/content-management/${id}/images`);

export const sendNewListOrder = ({ newOrder, storeId }) =>
  request.post('/api/content-management/images-order', {
    category: 'restaurant',
    order: newOrder,
    storeId,
  });
