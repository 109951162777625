import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const OrdersDetailedMainInformation = React.memo(
  ({ id, isAwaiting, storeName, createdAt, type, expectedDeliveryDatetime }) => {
    const { t } = useTranslation();

    return (
      <Box pl={4} pb={4}>
        <Typography>
          {`${t('orders.mainInformation.id')} ${id} - `}
          {isAwaiting && <b>{t('orders.mainInformation.withdrawalAwait')}</b>}
        </Typography>
        <Typography>
          {t('orders.mainInformation.restaurant')}: {storeName}
        </Typography>
        <Typography>{`${t('orders.mainInformation.date')}: ${createdAt}`}</Typography>
        <Typography>
          {`${t('orders.mainInformation.type.type')}: `}
          {t(`orders.mainInformation.type.${type}`)}
        </Typography>
        <Typography>{`${t(
          'orders.mainInformation.expectedDate',
        )}: ${expectedDeliveryDatetime}`}</Typography>
      </Box>
    );
  },
);

OrdersDetailedMainInformation.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  isAwaiting: PropTypes.bool,
  storeName: PropTypes.string,
  createdAt: PropTypes.string,
  expectedDeliveryDatetime: PropTypes.string,
};

OrdersDetailedMainInformation.defaultProps = {
  id: 0,
  type: '',
  isAwaiting: false,
  storeName: '',
  createdAt: '',
  expectedDeliveryDatetime: '',
};
