import React from 'react';
import { Autocomplete, Box, FormControl, FormHelperText, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const MultiSelectField = React.memo(({ name, label, options, defaultValues }) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValues}
      onChange={([, data]) => data}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <Box mb={2}>
            <FormControl size="small" fullWidth error={Boolean(error?.message)}>
              <Autocomplete
                multiple
                {...field}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                onChange={(_, item) => onChange(item)}
                options={options}
                getOptionLabel={(option) => option.value}
                size="small"
                renderInput={(params) => (
                  <TextField error={Boolean(error?.message)} {...params} label={label} />
                )}
              />
              <FormHelperText>{Boolean(error?.message) && t(`${error?.message}`)}</FormHelperText>
            </FormControl>
          </Box>
        );
      }}
    />
  );
});

MultiSelectField.defaultProps = {
  defaultValues: [],
};

MultiSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  defaultValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.string.isRequired,
    }),
  ),
};
