import { createSlice } from '@reduxjs/toolkit';

import { normalize } from 'ducks/mapper';

const initialState = {
  error: {},
  order: {},
  currentPage: 0,
  maxPages: 0,
  cards: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
};

export const orderSlice = createSlice({
  name: '@@order',
  initialState,
  reducers: {
    getOrderById: (state) => {
      state.order = initialState.order;
    },
    getOrderByIdSuccess: (state, { payload: { order } }) => {
      state.order = order;
    },
    getOrderByIdFailed: (state, action) => {
      state.error = action.payload;
    },

    getPagesInfo: (state) => {
      state.isLoading = true;
    },
    getPagesInfoSuccess: (state, { payload }) => {
      state.cards = normalize(payload.embedded.items);
      state.currentPage = payload.page;
      state.maxPages = payload.pages;
      state.isLoading = false;
    },
    getPagesInfoFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});
