import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography, styled } from '@mui/material';

const InputField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-input': {
    height: 10,
  },
}));

export const StoreInformationComponent = React.memo(({ storeData }) => {
  const { t } = useTranslation();

  const storeInformation = useMemo(
    () => [
      {
        label: 'stores.id',
        value: storeData.number,
      },
      {
        label: 'stores.name',
        value: storeData.name,
      },
      {
        label: 'stores.address',
        value: storeData.address?.streetName,
      },
      {
        label: 'stores.town',
        value: storeData.address?.town,
      },
      {
        label: 'stores.postalCode',
        value: storeData.address?.postcode,
      },
    ],
    [storeData],
  );

  return (
    <>
      <Box m={2}>
        {storeInformation.map(({ label, value }) => (
          <Box display="flex" key={label}>
            <Typography fontWeight="bold" mr={1} maxWidth={100} minWidth={100} textAlign="right">
              {t(label)}:
            </Typography>
            <Typography ml={1}>{value}</Typography>
          </Box>
        ))}
      </Box>
      <Typography variant="h5" mb="20px">
        Informations
      </Typography>
      <Box
        sx={{
          maxWidth: '400px',
          padding: '0 20px',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: 'repeat(2, 1fr)',
          rowGap: '15px',
        }}
      >
        <Typography fontWeight="bold">Latitude:</Typography>
        <InputField
          name="latitude"
          type="number"
          defaultValue={storeData.latitude}
          inputProps={{ step: '0.000001' }}
          size="small"
        />
        <Typography fontWeight="bold">Longitude:</Typography>
        <InputField
          name="longitude"
          type="number"
          defaultValue={storeData.longitude}
          inputProps={{ step: '0.000001' }}
          size="small"
        />
      </Box>
    </>
  );
});

StoreInformationComponent.propTypes = {
  storeData: PropTypes.shape({
    number: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.shape({
      streetName: PropTypes.string,
      town: PropTypes.string,
      postcode: PropTypes.string,
    }),
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

StoreInformationComponent.defaultProps = {
  storeData: {
    number: '',
    name: '',
    address: {
      streetName: '',
      town: '',
      postcode: '',
    },
    latitude: 0,
    longitude: 0,
  },
};
