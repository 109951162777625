import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { BaseModalDialog } from 'components/common/BaseModalDialog';
import { BaseModalDialogTitle } from 'components/common/BaseModalDialog/BaseModalDialogTitle';
import { useModal } from 'helpers/hooks';
import { selectCurrentMobileImage } from 'ducks/mobileImages';
import { AddEditMobileImageComponent } from 'components/modals/AddEditMobileImageComponent';

export const EditMobileImage = ({ id, title, onSuccess, onCancel, onClose }) => {
  const { closeModal } = useModal(id);

  const defaultValues = useSelector(selectCurrentMobileImage);

  const handleClose = useCallback(() => {
    onClose();
    closeModal();
  }, [closeModal, onClose]);

  const handleSuccess = useCallback(
    (data) => {
      onSuccess(data);
      handleClose();
    },
    [handleClose, onSuccess],
  );

  const handleCancel = useCallback(() => {
    onCancel();
    handleClose();
  }, [handleClose, onCancel]);

  return (
    <BaseModalDialog handleClose={handleClose}>
      <BaseModalDialogTitle>{title}</BaseModalDialogTitle>
      <AddEditMobileImageComponent
        defaultValues={defaultValues}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
      />
    </BaseModalDialog>
  );
};

EditMobileImage.defaultProps = {
  title: '',
  onSuccess: () => {},
  onCancel: () => {},
  onClose: () => {},
};

EditMobileImage.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};
