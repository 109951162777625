import { takeLatest, put, call } from 'redux-saga/effects';

import { login } from 'ducks/auth/services';

import { authSlice } from './reducer';

function* loginSaga({ payload: { username, password } }) {
  try {
    yield call(login, username, password);

    yield put(authSlice.actions.loginSuccess());
  } catch (error) {
    yield put(authSlice.actions.loginFailed(error?.response?.data));
  }
}

function* logoutSaga() {
  yield localStorage.removeItem('token');
}

export function* watchAuth() {
  yield takeLatest(authSlice.actions.login, loginSaga);
  yield takeLatest(authSlice.actions.logout, logoutSaga);
}
