import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { AcknowledgmentModalDialog } from 'components/modals/AcknowledgmentModalDialog';
import { useModal } from 'helpers/hooks';

export const AcknowledgmentModal = ({ id, title, body, onClose, onSuccess }) => {
  const { closeModal } = useModal(id);

  const handleClose = useCallback(() => {
    onClose();
    closeModal();
  }, [closeModal, onClose]);

  const handleSuccess = useCallback(() => {
    onSuccess();
    closeModal();
  }, [closeModal, onSuccess]);

  return (
    <AcknowledgmentModalDialog
      handleClose={handleClose}
      title={title}
      handleSuccess={handleSuccess}
    >
      {body}
    </AcknowledgmentModalDialog>
  );
};

AcknowledgmentModal.defaultProps = {
  title: 'success',
  body: '',
  onClose: () => {},
  onSuccess: () => {},
};

AcknowledgmentModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};
