import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

export const Checkbox = React.memo(({ name, defaultValue }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => <MuiCheckbox size="small" {...field} checked={field.value} />}
    />
  );
});

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
};

Checkbox.defaultProps = {
  defaultValue: false,
};
