import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import { StoreAction } from 'components/store/StoreAction';
import { storeSlice } from 'ducks/store';

export const StoreActionDeliveryStatus = ({ row }) => {
  const { storeId } = useParams();

  const { id, productStores } = row;
  const { deliveryStatus } = productStores[0];

  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(storeSlice.actions.changeDeliveryStatus({ storeId, productId: id }));
  }, [dispatch, id, storeId]);

  return <StoreAction onClick={onClick} deliveryStatus={deliveryStatus} />;
};

StoreActionDeliveryStatus.propTypes = {
  row: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
  ).isRequired,
};
