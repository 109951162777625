import { createSlice } from '@reduxjs/toolkit';

import { normalize } from 'ducks/mapper';

const initialState = {
  usersById: {},
  usersIds: [],
  isLoading: false,
  editedUserId: undefined,
  deletedId: undefined,
  currentPage: 1,
  pageCount: 1,
  error: undefined,
  status: undefined,
  modalErrors: [],
  restaurantsList: [],
  isRestaurantsListLoading: false,
};
export const usersSlice = createSlice({
  name: '@@users',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload: page }) => {
      state.currentPage = page;
    },
    confirmList: (state, { payload }) => {
      state.isLoading = false;
      state.status = payload;
    },
    confirmEdit: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    clearStatus: (state) => {
      state.status = initialState.status;
      state.error = initialState.error;
      state.modalErrors = initialState.modalErrors;
      state.deletedId = initialState.deletedId;
    },

    getUsersList: (state) => {
      state.isLoading = true;
    },
    getUsersSuccess: (state, { payload }) => {
      const { pages, page, embedded } = payload;
      const { items } = embedded;
      const { byId, allIds } = normalize(items);
      state.usersById = byId;
      state.usersIds = allIds;
      state.pageCount = pages;
      state.currentPage = page;
      state.isLoading = false;
      state.editedUserId = undefined;
    },

    getUser: (state) => {
      state.isLoading = true;
    },
    getUserSuccess: (state, { payload }) => {
      const {
        id,
        roles,
        email,
        username,
        embedded: { managedStores },
      } = payload;
      state.editedUserId = id;
      state.usersById[id] = { id, email, username, roles: roles.join(), managedStores };
      state.isLoading = false;
    },
    clearUser: (state) => {
      state.editedUserId = undefined;
    },

    updateUser: (state) => {
      state.isLoading = true;
    },

    createUser: (state) => {
      state.isLoading = true;
    },
    createUserFailed: (state, { payload }) => {
      state.isLoading = false;
      state.modalErrors = payload;
    },

    deleteUser: (state) => {
      state.isLoading = true;
    },
    deleteUserSuccess: (state, { payload: userId }) => {
      state.deletedId = userId;
    },

    clearErrors: (state) => {
      state.modalErrors = initialState.modalErrors;
      state.error = initialState.error;
    },

    getRestaurantsList: (state) => {
      state.isRestaurantsListLoading = true;
    },
    getRestaurantsListSuccess: (state, { payload: { items } }) => {
      state.restaurantsList = items.map(({ id, name }) => ({ id, value: name }));
      state.isRestaurantsListLoading = false;
    },
    getRestaurantsListFailed: (state, action) => {
      state.isRestaurantsListLoading = false;
      state.error = action.payload;
    },
  },
});
