import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import { modalsSlice } from 'ducks/modals';
import { notificationsSlice } from 'ducks/notifications';
import { authSlice } from 'ducks/auth';
import { usersSlice } from 'ducks/users';
import { currentUserSlice } from 'ducks/currentUser';
import { orderSlice } from 'ducks/orders';
import { accountSlice } from 'ducks/account';
import { accountsSlice } from 'ducks/accounts';
import { storeSlice } from 'ducks/store';
import { storesSlice } from 'ducks/stores';
import { exportContactsSlice } from 'ducks/exportContacts';
import { pushNotificationsSlice } from 'ducks/pushNotifications';
import { mobileImagesSlice } from 'ducks/mobileImages';
import { newsSlice } from 'ducks/news';

export const rootReducer = (history) =>
  combineReducers({
    modals: modalsSlice.reducer,
    notifications: notificationsSlice.reducer,
    auth: authSlice.reducer,
    users: usersSlice.reducer,
    currentUser: currentUserSlice.reducer,
    order: orderSlice.reducer,
    account: accountSlice.reducer,
    accounts: accountsSlice.reducer,
    stores: storesSlice.reducer,
    store: storeSlice.reducer,
    pushNotifications: pushNotificationsSlice.reducer,
    exportContacts: exportContactsSlice.reducer,
    mobileImages: mobileImagesSlice.reducer,
    news: newsSlice.reducer,
    router: connectRouter(history),
  });
