import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { Close, Edit } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { ImageWithTextField } from 'components/common/ImageWithTextListItem/ImageWithTextField';
import { Image } from 'components/common/Image';
import { Form } from 'components/common/Form';
import { textWithoutImageSchema } from 'schemas';

export const ImageWithTextListItem = React.memo(
  ({ src, alt, title, isTitleVisible, text, onTextEdit, onImageEdit, onDelete }) => (
    <Box border="1px solid" borderColor="tab.border" p={2} mb={2} display="flex">
      <Grid container justifyContent="space-evenly" position="relative">
        <Grid item xs={4} container alignItems="center" justifyContent="center">
          <Image src={src} alt={alt} borderRadius={2} overflow="hidden" maxWidth={300} />
        </Grid>
        <Grid
          item
          xs={7}
          container
          justifyContent="center"
          alignItems="stretch"
          flexDirection="column"
        >
          <Form onSubmit={onTextEdit} validationSchema={textWithoutImageSchema}>
            <ImageWithTextField title={title} isTitleVisible={isTitleVisible} text={text} />
          </Form>
        </Grid>
        <IconButton
          sx={{ position: 'absolute', top: '0', left: 'calc(100% - 90px)' }}
          onClick={onImageEdit}
        >
          <Edit />
        </IconButton>
        <IconButton
          sx={{ position: 'absolute', top: '0', left: 'calc(100% - 40px)' }}
          onClick={onDelete}
        >
          <Close />
        </IconButton>
      </Grid>
    </Box>
  ),
);

ImageWithTextListItem.defaultProps = {
  title: '',
  isTitleVisible: false,
};

ImageWithTextListItem.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
  isTitleVisible: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onTextEdit: PropTypes.func.isRequired,
  onImageEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
