import { createSelector } from '@reduxjs/toolkit';

export const selectStoresItemsById = (state) => state.stores.itemsById;
export const selectStoresItemsIds = (state) => state.stores.itemsIds;

export const selectStoresMeta = (state) => state.stores.meta;

export const selectStoresTableIsLoading = (state) => state.stores.isLoading;

export const selectStoresItems = createSelector(
  selectStoresItemsById,
  selectStoresItemsIds,
  (itemsById, itemsIds) => itemsIds.map((id) => itemsById[id]),
);
