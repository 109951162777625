import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectField } from 'components/common/SelectField/SelectField';
import { USER_ROLES } from 'constants/userRoles';
import { SearchTextField } from 'components/common/SearchTextField';

export const UserSearchForm = React.memo(({ onClose }) => {
  const {
    formState: { isSubmitted },
  } = useFormContext();
  const { t } = useTranslation();

  const roles = useMemo(
    () =>
      [{ id: '', value: '' }].concat(
        Object.values(USER_ROLES).map((value) => ({
          id: value,
          value: t(`accountInfo.role.${value}`),
        })),
      ),
    [t],
  );

  useEffect(() => {
    if (isSubmitted) {
      onClose();
    }
  }, [isSubmitted, onClose]);

  return (
    <Box minWidth={400} p={1} display="flex" flexDirection="column">
      <SearchTextField name="username" label={t('user.username')} isErrorDisplay={false} />
      <SearchTextField name="email" label={t('user.email')} isErrorDisplay={false} />
      <SelectField name="role" label={t('user.role')} options={roles} />
      <Button variant="contained" sx={{ alignSelf: 'end' }} type="submit">
        <Search />
      </Button>
    </Box>
  );
});

UserSearchForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};
