import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton, styled, Typography } from '@mui/material';
import { isNumber } from 'lodash';

import { Table } from 'components/common/Table';
import { getCentsInfoByEventType } from 'helpers/helpers';

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.accordion.button,
  color: theme.palette.accordion.details,
  borderRadius: 0,
  fontSize: 12,
  minWidth: '90px',
  textDecoration: 'none',
  maxHeight: 33,
  marginTop: 20,
  '&:hover': {
    backgroundColor: theme.palette.accordion.hover,
    textDecoration: 'none',
  },
}));

export const HistoryCollapse = React.memo(
  ({ history, initialBalance, isButtonVisible, onClick }) => {
    const { t } = useTranslation();

    const historyItems = useMemo(
      () =>
        history.reduce((acc, item, index) => {
          if (index === 0) {
            acc.push({ ...item, balance: initialBalance, id: item.event.id });
            return acc;
          }

          const oldItem = acc[acc.length - 1];

          const { centsDiff: balanceDiff } = getCentsInfoByEventType(oldItem);
          const newBalance = oldItem.balance - balanceDiff;

          const newItem = { ...item, id: item.event.id, balance: newBalance };
          acc.push(newItem);

          return acc;
        }, []),
      [history, initialBalance],
    );

    const columns = useMemo(
      () => [
        {
          name: t('loyaltyTransactions.date'),
          field: 'completedAt',
          cell: ({ row: { event } }) => event.createdAt.slice(0, 10),
        },
        {
          name: t('loyaltyTransactions.event'),
          field: 'eventType',
          cell: ({ row: { event, eventType } }) => (
            <>
              {t(`loyaltyHistory.event.${eventType}`)}
              <br />
              <i>{event.complaint}</i>
            </>
          ),
        },
        {
          name: t('loyaltyTransactions.storeName'),
          field: 'store',
          cell: ({
            row: {
              event: { store },
            },
          }) => (store ? store.name : null),
        },
        {
          name: t('loyaltyTransactions.amount'),
          field: 'amount',
          cell: ({
            row: {
              event: { total },
            },
          }) => (isNumber(total) ? t('account.euroValue', { value: total / 100 }) : null),
        },
        {
          name: t('loyaltyTransactions.balanceChange'),
          field: 'balanceChange',
          cell: ({ row }) => {
            const { centsDiff, centsUsed, centsEarned } = getCentsInfoByEventType(row);

            return centsUsed && centsEarned
              ? t('account.euroValueDiff', {
                  firstValue: centsEarned / 100,
                  secondValue: Math.abs(centsUsed) / 100,
                })
              : ((centsDiff || row.eventType === 'balance_change') &&
                  t('account.euroValue', {
                    value: centsDiff / 100,
                  })) ||
                  '';
          },
        },
        {
          name: t('loyaltyTransactions.balance'),
          field: 'balance',
          cell: ({ row: { balance } }) => t('account.euroValue', { value: balance / 100 }),
        },
      ],
      [t],
    );

    return historyItems.length ? (
      <>
        <Table columns={columns} rows={historyItems} />
        {isButtonVisible && <Button onClick={onClick}>{t('account.moreHistory')}</Button>}
      </>
    ) : (
      <Typography sx={{ textAlign: 'center', marginTop: 5 }} variant="h5">
        {t('account.noData')}
      </Typography>
    );
  },
);

HistoryCollapse.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.shape({
        completedAt: PropTypes.string,
        total: PropTypes.number,
        store: PropTypes.shape({
          name: PropTypes.string,
        }),
        loyaltyStatus: PropTypes.shape({
          centsEarned: PropTypes.number,
          centsDiff: PropTypes.number,
          centsUsed: PropTypes.number,
        }),
      }),
      webOrder: PropTypes.shape({
        loyaltyStatus: PropTypes.shape({
          centsEarned: PropTypes.number,
          centsDiff: PropTypes.number,
          centsUsed: PropTypes.number,
        }),
      }),
      eventType: PropTypes.string,
    }),
  ),
  initialBalance: PropTypes.number,
  isButtonVisible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
HistoryCollapse.defaultProps = {
  history: [{ event: {} }],
  isButtonVisible: false,
  initialBalance: 0,
};
