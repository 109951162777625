import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Stack } from '@mui/material';

export const DatepickerToolbar = React.memo(({ handleClear, handleToday, handleClose }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    spacing={2}
    order={1}
    m={1}
  >
    <ButtonGroup variant="contained" aria-label="outlined primary button group" size="small">
      <Button onClick={handleToday} sx={{ bgcolor: 'datepicker.today' }}>
        Today
      </Button>
      <Button onClick={handleClear} sx={{ bgcolor: 'datepicker.clear' }}>
        Clear
      </Button>
    </ButtonGroup>
    <Button
      sx={{ alignSelf: 'flex-end', bgcolor: 'datepicker.close' }}
      variant="contained"
      onClick={handleClose}
      size="small"
    >
      Fermer
    </Button>
  </Stack>
));

DatepickerToolbar.propTypes = {
  handleClear: PropTypes.func.isRequired,
  handleToday: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
