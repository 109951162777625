import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { Image } from 'components/common/Image';
import { ImageField } from 'components/common/ImageField';

export const MobileImageForm = React.memo(({ defaultValues, children }) => {
  const { src, alt } = defaultValues;

  const { watch } = useFormContext();

  const uploadedImage = watch('image', {});

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" ml={1} mr={1}>
      {(src || Boolean(uploadedImage.length)) && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Image
            src={
              uploadedImage.length
                ? URL.createObjectURL(uploadedImage[0])
                : `https://platform.pommedepain.fr/media/thumbnail/png/512/512/${src}`
            }
            alt={alt}
            mb={2}
            borderRadius={2}
            overflow="hidden"
            maxWidth={500}
          />
        </Box>
      )}
      <ImageField name="image" />
      {children}
    </Box>
  );
});

MobileImageForm.propTypes = {
  defaultValues: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
