import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  itemsById: {},
  itemsIds: [],
  errors: {},
  isLoading: false,
  meta: {
    currentPage: 1,
    lastPage: 1,
  },
};

export const storesSlice = createSlice({
  name: '@@stores',
  initialState,
  reducers: {
    getStoresList: (state) => {
      state.isLoading = true;
    },
    getStoresListSuccess: (state, { payload: { items, currentPage, lastPage } }) => {
      const { byId, allIds } = items;
      state.isLoading = false;
      state.itemsById = byId;
      state.itemsIds = allIds;
      state.meta.currentPage = currentPage;
      state.meta.lastPage = lastPage;
    },
    getStoresListFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    toggleVemState: (state) => state,
    toggleVemStateSuccess: (state, { payload: { id, isDineinEnabled } }) => {
      state.itemsById[id].isDineinEnabled = isDineinEnabled;
    },
    toggleVemStateFailed: (state, action) => {
      state.error = action.payload;
    },

    toggleVaeState: (state) => state,
    toggleVaeStateSuccess: (state, { payload: { id, isTakeawayEnabled } }) => {
      state.itemsById[id].isTakeawayEnabled = isTakeawayEnabled;
    },
    toggleVaeStateFailed: (state, action) => {
      state.error = action.payload;
    },

    setCurrentPage: (state, { payload: page }) => {
      state.meta.currentPage = page;
    },
  },
});
