import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { ProfileCollapseComponent } from 'components/account/ProfileCollapse/ProfileCollapseComponent';
import { accountSlice } from 'ducks/account';

export const ProfileCollapse = ({ isModified, profile, setIsModified }) => {
  const { userId } = profile;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { showModal } = useModal();

  const handleReset = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.confirmation,
      title: t('modals.resetUserPasswordModal.header'),
      body: t('modals.resetUserPasswordModal.body'),
      onSuccess: () => {
        dispatch(accountSlice.actions.setDefaultPassword(userId));
      },
    });
  }, [showModal, dispatch, userId, t]);

  return (
    <ProfileCollapseComponent
      setIsModified={setIsModified}
      isModified={isModified}
      profile={profile}
      handleReset={handleReset}
    />
  );
};

ProfileCollapse.propTypes = {
  setIsModified: PropTypes.func.isRequired,
  isModified: PropTypes.bool,
  profile: PropTypes.shape({
    userId: PropTypes.number,
    birthday: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.shape({
      id: PropTypes.number,
      shortTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
};
ProfileCollapse.defaultProps = {
  isModified: false,
  profile: {},
};
