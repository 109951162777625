import * as yup from 'yup';

import { USER_ROLES } from 'constants/userRoles';

export const userSchema = yup.object().shape({
  username: yup
    .string()
    .required('accountInfo.error.mandatory')
    .matches(
      /^([A-Za-z\-\u2010\u2212\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      'user.error.username.pattern',
    )
    .matches(/^\S+$/, 'user.error.username.spaces'),
  plainPassword: yup
    .string()
    .min(8, 'modals.changePasswordModal.passwordPatternError')
    .matches(/^\S+$/, 'user.error.spaces')
    .matches(/^[a-zA-Z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/, 'user.error.passwordPattern')
    .matches(/[a-z]/, 'user.error.passwordPattern')
    .matches(/[A-Z]/, 'user.error.passwordPattern')
    .matches(/\d/, 'user.error.passwordPattern')
    .matches(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, 'user.error.passwordPattern')
    .required('accountInfo.error.mandatory'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('plainPassword'), null], 'user.error.passwordNotMatch')
    .required('accountInfo.error.mandatory'),
  email: yup
    .string()
    .email('user.error.email')
    .matches(/^[A-Za-z\d_\-.]+@[A-Za-z\d.]+.[A-Za-z]+$/, 'user.error.email')
    .required('accountInfo.error.mandatory'),
  roles: yup.string().oneOf(Object.values(USER_ROLES)).required('accountInfo.error.mandatory'),
  restaurants: yup
    .array()
    .min(1, 'user.error.minNumberOfRestaurants')
    .max(3, 'user.error.maxNumberOfRestaurants'),
});
