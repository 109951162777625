import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form } from 'components/common/Form';
import { SearchField } from 'components/common/SearchField';
import { StoresForm } from 'components/stores/StoresForm';

export const StoresSearch = React.memo(({ onChangeQuery }) => {
  const { t } = useTranslation();

  const onSubmit = useCallback(
    ({ name, sysId }) => {
      onChangeQuery({ search: { name, sysId }, page: 1 });
    },
    [onChangeQuery],
  );

  return (
    <Form onSubmit={onSubmit}>
      <SearchField label={t('storesPage.search.storeName')} name="name" onSubmit={onSubmit}>
        {(props) => <StoresForm {...props} />}
      </SearchField>
    </Form>
  );
});

StoresSearch.propTypes = {
  onChangeQuery: PropTypes.func.isRequired,
};
