import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  account: {},
  profile: {},
  information: {},
  history: [],
  historyMeta: {
    page: 1,
    lastPage: 1,
  },
  isAdditionalHistoryLoading: false,
  error: '',
  isLoading: false,
  isDeactivated: false,
  status: '',
  resetPasswordStatus: '',
};

export const accountSlice = createSlice({
  name: '@@account',
  initialState,
  reducers: {
    getAccount: (state) => {
      state.isLoading = true;
    },
    getUserSuccess: (state, { payload }) => {
      state.user = payload;
    },
    getAccountSuccess: (state, { payload }) => {
      state.profile = payload;
      state.isLoading = false;
      state.user = {};
    },
    getAccountFailed: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.profile = state.user;
    },
    getProfile: (state) => {
      state.isLoading = true;
    },
    getProfileSuccess: (state) => {
      state.isLoading = false;
    },

    getHistory: (state) => {
      if (!state.history.length) {
        state.isLoading = true;
      }
      state.isAdditionalHistoryLoading = true;
    },
    getHistorySuccess: (state, { payload: { items, page, lastPage } }) => {
      state.history = state.history.concat(items);
      state.historyMeta.page = page;
      state.historyMeta.lastPage = lastPage;
      state.isLoading = false;
      state.isAdditionalHistoryLoading = false;
    },
    resetHistory: (state) => {
      state.history = initialState.history;
    },

    getInformation: (state) => {
      state.isLoading = true;
    },
    getInformationSuccess: (state, { payload }) => {
      state.information = payload;
      state.isLoading = false;
    },
    setDefaultPassword: (state) => {
      state.isLoading = true;
    },
    setDefaultPasswordSuccess: (state) => {
      state.isLoading = false;
      state.status = 'resetUserPasswordSuccess';
    },
    editProfile: (state) => {
      state.isLoading = true;
    },
    editProfileSuccess: (state) => {
      state.isLoading = false;
      state.status = 'success';
    },
    changeEmail: (state) => {
      state.isLoading = true;
    },
    changeEmailSuccess: (state) => {
      state.isLoading = false;
      state.resetPasswordStatus = 'changingEmailModal';
    },
    clearPasswordStatus: (state) => {
      state.resetPasswordStatus = '';
    },
    clearStatus: (state) => {
      state.status = '';
    },
    editBalance: (state) => {
      state.isLoading = true;
    },
    editBalanceSuccess: (state) => {
      state.isLoading = false;
      state.status = 'successEditBalance';
    },
    activateAccount: (state) => {
      state.isLoading = true;
    },
    activateAccountSuccess: (state) => {
      state.isLoading = false;
      state.status = 'success';
    },
    deactivateAccount: (state) => {
      state.isLoading = true;
    },
    deactivateAccountSuccess: (state) => {
      state.isLoading = false;
      state.isDeactivated = true;
    },

    resetIsDeactivated: (state) => {
      state.isDeactivated = initialState.isDeactivated;
    },

    getFailedResponse: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    clearError: (state) => {
      state.error = '';
    },
  },
});
