import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Search } from '@mui/icons-material';

import { SearchTextField } from 'components/common/SearchTextField';

export const OrdersForm = React.memo(({ onClose }) => {
  const {
    formState: { isSubmitted },
  } = useFormContext();

  const { t } = useTranslation();

  useEffect(() => {
    if (isSubmitted) {
      onClose();
    }
  }, [isSubmitted, onClose]);

  return (
    <Box display="flex" flexDirection="column" p={1} minWidth={400}>
      <SearchTextField
        name="storeName"
        label={t('ordersPage.search.storeName')}
        isErrorDisplay={false}
      />
      <SearchTextField
        name="orderId"
        label={t('ordersPage.search.orderId')}
        isErrorDisplay={false}
      />
      <SearchTextField
        name="customerName"
        label={t('ordersPage.search.customerName')}
        isErrorDisplay={false}
      />
      <SearchTextField
        name="customerPostalCode"
        label={t('ordersPage.search.customerPostalCode')}
        isErrorDisplay={false}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" type="submit">
          <Search />
        </Button>
      </Box>
    </Box>
  );
});

OrdersForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};
