import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router';
import { IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/routes';
import { USER_ROLES } from 'constants/userRoles';

export const UsersActionsComponent = React.memo(
  ({ userRole, currentUserId, row, handleDelete }) => {
    const { id } = row;

    const history = useHistory();

    const isEditable =
      id === currentUserId ||
      row.roles[0] !== USER_ROLES.superAdmin ||
      (userRole !== USER_ROLES.superAdmin && userRole !== USER_ROLES.pdpAdmin);

    const isDeletable = id === currentUserId || row.roles[0] !== USER_ROLES.superAdmin;

    const handleEdit = useCallback(() => {
      history.push(generatePath(ROUTES.editUser, { userId: id }));
    }, [history, id]);

    return (
      <>
        {isEditable && (
          <IconButton size="small" onClick={handleEdit}>
            <FontAwesomeIcon icon={faEdit} />
          </IconButton>
        )}
        {isDeletable && (
          <IconButton size="small" onClick={handleDelete}>
            <Cancel color="error" />
          </IconButton>
        )}
      </>
    );
  },
);

UsersActionsComponent.propTypes = {
  userRole: PropTypes.oneOf(Object.values(USER_ROLES)).isRequired,
  currentUserId: PropTypes.number.isRequired,
  row: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
  ).isRequired,
  handleDelete: PropTypes.func.isRequired,
};
