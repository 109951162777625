import React, { useCallback } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { TextField } from 'components/common/TextField';
import { useToggle } from 'helpers/hooks';

export const PasswordField = React.memo(({ name, label, isErrorDisplay, namesToTrigger }) => {
  const [isVisiblePassword, togglePasswordVisibility] = useToggle(false);

  const { trigger } = useFormContext();

  const triggerAnotherFieldOnChange = useCallback(() => {
    trigger(namesToTrigger);
  }, [trigger, namesToTrigger]);

  return (
    <TextField
      triggerAnotherFieldOnChange={namesToTrigger.length ? triggerAnotherFieldOnChange : undefined}
      label={label}
      name={name}
      isErrorDisplay={isErrorDisplay}
      type={isVisiblePassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
              edge="end"
            >
              {isVisiblePassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});

PasswordField.defaultProps = {
  name: 'password',
  label: 'Password',
  namesToTrigger: [],
};

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isErrorDisplay: PropTypes.bool.isRequired,
  namesToTrigger: PropTypes.arrayOf(PropTypes.string.isRequired),
};
