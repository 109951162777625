import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listById: {},
  listIds: [],
  initialListIds: [],
  currentMobileImage: {
    id: 0,
    src: '',
    alt: '',
    hyperlink: '',
  },
  isLoading: false,
  error: {},
};

export const mobileImagesSlice = createSlice({
  name: '@@mobileImages',
  initialState,
  reducers: {
    getMobileImagesList: (state) => {
      state.isLoading = true;
    },
    getMobileImagesListSuccess: (state, { payload: { items } }) => {
      const { byId, allIds } = items;
      state.listById = byId;
      state.listIds = allIds;
      state.initialListIds = allIds;

      state.isLoading = false;
    },
    getMobileImagesListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    addMobileImage: (state) => state,
    addMobileImageSuccess: (state) => state,
    addMobileImageFailed: (state, action) => {
      state.error = action.payload;
    },

    editMobileImage: (state) => state,
    editMobileImageSuccess: (state) => state,
    editMobileImageFailed: (state, action) => {
      state.error = action.payload;
    },

    editHyperlink: (state) => state,
    editHyperlinkSuccess: (state) => state,
    editHyperlinkFailed: (state, action) => {
      state.error = action.payload;
    },

    deleteMobileImage: (state) => state,
    deleteMobileImageSuccess: (state, { payload: { id } }) => {
      delete state.listById[id];
      state.listIds = state.listIds.filter((listId) => listId !== id);
      state.initialListIds = state.initialListIds.filter((listId) => listId !== id);
    },
    deleteMobileImageFailed: (state, action) => {
      state.error = action.payload;
    },

    setCurrentMobileImage: (state, { payload }) => {
      state.currentMobileImage = payload;
    },
    resetCurrentMobileImage: (state) => {
      state.currentMobileImage = initialState.currentMobileImage;
    },

    reorderItems: (state, { payload: { dragIndex, hoverIndex } }) => {
      const dragId = state.listIds[dragIndex];
      state.listIds.splice(dragIndex, 1);
      state.listIds.splice(hoverIndex, 0, dragId);
    },

    sendNewListOrder: (state) => state,
    sendNewListOrderSuccess: (state, { payload: { newOrder } }) => {
      state.initialListIds = newOrder;
    },
    sendNewListOrderFailed: (state, action) => {
      state.error = action.payload;
    },
  },
});
