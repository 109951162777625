import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { accountSlice } from 'ducks/account';
import { DeactivateCollapseComponent } from 'components/account/DeactivateCollapse/DeactivateCollapseComponent';

export const DeactivateCollapse = ({ userId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { showModal } = useModal();

  const handleConfirm = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.confirmation,
      title: t('modals.disableUserModal.header'),
      body: t('modals.disableUserModal.body'),
      onSuccess: () => {
        dispatch(accountSlice.actions.deactivateAccount(userId));
      },
    });
  }, [dispatch, userId, showModal, t]);

  return <DeactivateCollapseComponent handleConfirm={handleConfirm} />;
};

DeactivateCollapse.propTypes = {
  userId: PropTypes.number,
};
DeactivateCollapse.defaultProps = {
  userId: null,
};
