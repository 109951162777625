import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Tabs as MuiTabs, Tab as MuiTab, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import { TabPanel } from 'components/common/Tabs/TabPanel';

/**
 * Modify MuiTabs to render properly
 */
const TabContainer = styled(MuiTabs)(({ theme }) => ({
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.tab.border,
  margin: theme.spacing(0, 2),
  minHeight: 19,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

/**
 * Modify MuiTab to render Tabs on pseudo-classes
 */
const Tab = styled(MuiTab)(({ theme }) => ({
  marginRight: '2px',
  lineHeight: 1.42857,
  border: '1px solid transparent',
  color: theme.palette.link.main,
  textTransform: 'none',
  padding: theme.spacing(1.25, 2),
  minHeight: 19,
  borderRadius: '2px 2px 0 0',
  '&: hover': {
    backgroundColor: theme.palette.tab.hover,
    borderColor: theme.palette.tab.border,
  },
  '&.Mui-selected': {
    color: theme.palette.tab.text,
    backgroundColor: theme.palette.tab.active,
    borderColor: theme.palette.tab.border,
    borderBottomColor: 'transparent',
  },
}));

export const Tabs = React.memo(({ items, defaultTab, onChangeTab }) => {
  const [value, setValue] = useState(defaultTab);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== value) {
      setValue(location.pathname);
      onChangeTab(location.pathname);
    }
  }, [location, onChangeTab, value]);

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      onChangeTab(newValue);
    },
    [onChangeTab],
  );

  const hiddenItems = useMemo(() => items.filter((item) => item.exactVisible), [items]);

  return (
    <div>
      <TabContainer variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange}>
        {items.map(
          ({ route, title, exactVisible }) =>
            !exactVisible && (
              <Tab key={title} label={title} to={route} value={route} component={Link} />
            ),
        )}
        {hiddenItems.map(
          ({ route: hiddenRoute, title: hiddenTitle }) =>
            value === hiddenRoute && (
              <Tab
                key={hiddenTitle}
                label={hiddenTitle}
                to={hiddenRoute}
                value={hiddenRoute}
                component={Link}
              />
            ),
        )}
      </TabContainer>
      {items.map(({ component, route, title }) => (
        <TabPanel key={title} value={value} selectedValue={route} component={component} />
      ))}
    </div>
  );
});

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      component: PropTypes.elementType,
      exactVisible: PropTypes.bool,
    }),
  ),
  defaultTab: PropTypes.string,
  onChangeTab: PropTypes.func,
};

Tabs.defaultProps = {
  items: [],
  defaultTab: '',
  onChangeTab: () => {},
};
