import * as yup from 'yup';

export const mobileImageSchema = yup.object().shape({
  image: yup
    .mixed()
    .required()
    .test('fileFormat', 'Images only', function test(value) {
      return (
        value[0]?.size <= 10485760 &&
        value[0]?.type.includes('image') &&
        (value[0]?.type.includes('png') ||
          value[0]?.type.includes('jpg') ||
          value[0]?.type.includes('jpeg'))
      );
    }),
  hyperlink: yup.string().nullable().url('errors.url'),
  title: yup.string().nullable().max(255, 'errors.maxLength'),
});
