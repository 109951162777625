import { createSlice } from '@reduxjs/toolkit';

import { STATUSES } from 'constants/statuses';

const initialState = {
  username: '',
  email: '',
  id: 0,
  error: {},
  changePasswordStatus: '',
  role: '',
  isForcedLogout: false,
};

export const currentUserSlice = createSlice({
  name: '@@currentUser',
  initialState,
  reducers: {
    fetchCurrentUser: (state) => {
      state.isForcedLogout = initialState.isForcedLogout;
    },
    fetchCurrentUserSuccess: (state, { payload }) => {
      const { username, email, id, role } = payload;
      state.username = username;
      state.email = email;
      state.id = id;
      state.role = role;
    },
    fetchCurrentUserFailed: (state, action) => {
      state.error = action.payload;
      state.isForcedLogout = true;
    },

    changePassword: (state) => {
      state.changePasswordStatus = initialState.changePasswordStatus;
    },
    changePasswordSuccess: (state) => {
      state.changePasswordStatus = STATUSES.success;
    },
    changePasswordFailed: (state, action) => {
      state.changePasswordStatus = STATUSES.error;
      state.error = action.payload;
    },
  },
});
