export const SORT_FIELD = {
  email: 'email',
  lastName: 'lastname',
  firstName: 'firstname',
};

export const SORT_ORDERS = {
  asc: 'asc',
  desc: 'desc',
};
