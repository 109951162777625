import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ImageWithTextListItem } from 'components/common/ImageWithTextListItem';
import { mobileImagesSlice, selectMobileImagesById } from 'ducks/mobileImages';
import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';

export const MobileImage = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { showModal } = useModal();

  const mobileImagesById = useSelector(selectMobileImagesById);

  const { imageName: src, hyperlink } = mobileImagesById[id];

  const handleEditMobileImage = useCallback(
    (data) => {
      if (data.image.length) {
        dispatch(
          mobileImagesSlice.actions.editMobileImage({
            id,
            image: data.image[0],
          }),
        );
      }
    },
    [dispatch, id],
  );

  const handleDeleteMobileImage = useCallback(() => {
    dispatch(mobileImagesSlice.actions.deleteMobileImage({ id }));
  }, [dispatch, id]);

  const handleResetCurrentMobileImage = useCallback(() => {
    dispatch(mobileImagesSlice.actions.resetCurrentMobileImage());
  }, [dispatch]);

  const onTextEdit = useCallback(
    ({ hyperlink: editedHyperlink }) => {
      dispatch(
        mobileImagesSlice.actions.editHyperlink({
          id,
          hyperlink: editedHyperlink,
        }),
      );
    },
    [dispatch, id],
  );

  const onImageEdit = useCallback(() => {
    dispatch(mobileImagesSlice.actions.setCurrentMobileImage({ id, src, alt: src, hyperlink }));

    showModal({
      modalType: MODAL_TYPES.editMobileImage,
      title: t('modals.mobileImage.editHeader'),
      onSuccess: handleEditMobileImage,
      onClose: handleResetCurrentMobileImage,
    });
  }, [
    dispatch,
    handleEditMobileImage,
    handleResetCurrentMobileImage,
    hyperlink,
    id,
    showModal,
    src,
    t,
  ]);

  const onDelete = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.deleteConfirmation,
      title: t('modals.confirmDeleteMobileImage.header'),
      body: t('modals.confirmDeleteMobileImage.body'),
      onSuccess: handleDeleteMobileImage,
    });
  }, [handleDeleteMobileImage, showModal, t]);

  return (
    <ImageWithTextListItem
      src={`https://platform.pommedepain.fr/media/thumbnail/png/512/512/${src}`}
      alt={src}
      text={hyperlink}
      onTextEdit={onTextEdit}
      onImageEdit={onImageEdit}
      onDelete={onDelete}
    />
  );
};

MobileImage.propTypes = {
  id: PropTypes.number.isRequired,
};
