import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogActions, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ChangePasswordForm } from 'components/common/ChangePasswordForm';
import { Form, SubmitButtonWithOnlyValidData } from 'components/common/Form';
import { passwordChangeSchema } from 'schemas';

const WarningButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.button.warning.hover,
  },
}));

const SubmitButton = styled(SubmitButtonWithOnlyValidData)(({ theme }) => ({
  backgroundColor: theme.palette.button.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.button.primary.hover,
  },
  '&:disabled': {
    opacity: 0.65,
    color: theme.palette.white,
    backgroundColor: theme.palette.button.primary.main,
  },
}));

export const ChangePasswordComponent = React.memo(({ handleSuccess, handleCancel }) => {
  const { t } = useTranslation();

  return (
    <Box ml={1} mr={1}>
      <Form onSubmit={handleSuccess} validationSchema={passwordChangeSchema}>
        <ChangePasswordForm />
        <DialogActions>
          <WarningButton variant="contained" onClick={handleCancel}>
            {t('modals.changePasswordModal.cancel')}
          </WarningButton>
          <SubmitButton> {t('modals.changePasswordModal.confirm')}</SubmitButton>
        </DialogActions>
      </Form>
    </Box>
  );
});

ChangePasswordComponent.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
