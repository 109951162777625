import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { pushNotificationsSlice } from 'ducks/pushNotifications';
import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { HistoryTableActionsComponent } from 'components/pushNotifications/AutoHistory/HistoryTableActionsComponent';

export const HistoryTableActions = ({ row }) => {
  const { id, active } = row;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { showModal } = useModal();

  const handleEdit = useCallback(() => {
    dispatch(pushNotificationsSlice.actions.setTriggerTemplate(row));
  }, [dispatch, row]);

  const handleDelete = useCallback(() => {
    dispatch(pushNotificationsSlice.actions.deleteAutoNotification({ id }));
  }, [dispatch, id]);

  const showConfirmModal = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.deleteConfirmation,
      title: t('modals.deleteNotificationModal.header'),
      body: t('modals.deleteNotificationModal.body'),
      onSuccess: handleDelete,
    });
  }, [handleDelete, showModal, t]);

  return (
    <HistoryTableActionsComponent
      isActive={active}
      onDelete={showConfirmModal}
      onEdit={handleEdit}
    />
  );
};

HistoryTableActions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    active: PropTypes.bool.isRequired,
  }).isRequired,
};
