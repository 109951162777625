import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { USER_ROLES } from 'constants/userRoles';
import { PasswordField } from 'components/common/PasswordField';
import { SelectField } from 'components/common/SelectField';
import { TextField } from 'components/common/TextField';
import { MultiSelectField } from 'components/common/MultiSelectField';
import { Loader } from 'components/common/Loader';

const triggerItems = ['plainPassword', 'confirmPassword'];

export const UserForm = React.memo(
  ({ user, role, isRestaurantsListLoading, restaurantsList, getRestaurantsList }) => {
    const { t } = useTranslation();

    const { reset, watch } = useFormContext();

    const isUserCreation = user.username === '';

    const selectedRole = watch('roles', '');

    useEffect(() => {
      if (user) {
        reset({ ...user, plainPassword: '', confirmPassword: '' });
      }
    }, [user, reset]);

    useEffect(() => {
      if (selectedRole === USER_ROLES.manager) {
        getRestaurantsList();
      }
    }, [getRestaurantsList, selectedRole]);

    const roleOptions = useMemo(
      () =>
        Object.values(USER_ROLES)
          .map((value) => ({
            id: value,
            value: t(`accountInfo.role.${value}`),
          }))
          .filter(({ id }) => {
            if (role !== USER_ROLES.superAdmin) {
              return id !== USER_ROLES.superAdmin;
            }
            return true;
          }),
      [role, t],
    );

    return (
      <Box display="flex" flexDirection="column" justifyContent="center">
        <TextField name="username" label={t('user.username')} isErrorDisplay />
        <PasswordField
          isErrorDisplay
          name="plainPassword"
          label={isUserCreation ? t('user.createPassword') : t('user.changePassword')}
          namesToTrigger={triggerItems}
        />
        <PasswordField
          isErrorDisplay
          name="confirmPassword"
          label={isUserCreation ? t('user.confirmPassword') : t('user.confirmNewPassword')}
        />
        <TextField name="email" label={t('user.email')} isErrorDisplay />
        <SelectField name="roles" options={roleOptions} label={t('user.role')} />
        {selectedRole === USER_ROLES.manager &&
        Boolean(restaurantsList?.length) &&
        !isRestaurantsListLoading ? (
          <MultiSelectField
            label={t('user.restaurants')}
            name="restaurants"
            defaultValues={user?.managedStores?.map(({ id, name }) => ({ id, value: name }))}
            options={restaurantsList}
          />
        ) : (
          <Loader isLoading={isRestaurantsListLoading} />
        )}
      </Box>
    );
  },
);

UserForm.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.string,
    managedStores: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
  role: PropTypes.oneOf(Object.values(USER_ROLES).concat('')).isRequired,
  restaurantsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  getRestaurantsList: PropTypes.func,
  isRestaurantsListLoading: PropTypes.bool,
};

UserForm.defaultProps = {
  user: {
    username: '',
    email: '',
    roles: '',
    managedStores: [],
  },
  restaurantsList: [],
  getRestaurantsList: () => {},
  isRestaurantsListLoading: false,
};
