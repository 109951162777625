import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs } from 'components/common/Breadcrumbs/Breadcrumbs';
import { ROUTES } from 'constants/routes';
import { OrdersDetailedInformation } from 'components/orders/OrdersDetailed/OrdersDetailedInformation';

export const OrdersDetailed = React.memo(({ orderData, orderId }) => {
  const { t } = useTranslation();

  const breadcrumbsItemsArray = useMemo(
    () => [
      { text: t('orders.breadcrumbs.orders'), route: ROUTES.ordersPendingTab },
      { text: `${t('orders.breadcrumbs.order')} ${orderId}` },
      { text: t('orders.breadcrumbs.details') },
    ],
    [t, orderId],
  );

  return (
    <Box sx={{ backgroundColor: 'white' }} p={2}>
      <Breadcrumbs breadcrumbsItemsArray={breadcrumbsItemsArray} />
      <OrdersDetailedInformation orderData={orderData} />
    </Box>
  );
});

OrdersDetailed.propTypes = {
  orderData: PropTypes.shape({}),
  orderId: PropTypes.string,
};

OrdersDetailed.defaultProps = {
  orderData: '',
  orderId: '',
};
