import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import {
  pushNotificationsSlice,
  selectNotificationMeta,
  selectNotifications,
  selectNotificationsState,
} from 'ducks/pushNotifications';
import { HistoryTable } from 'components/pushNotifications/HistoryTable';
import { useQuery } from 'helpers/hooks';
import { formatDate } from 'helpers/helpers';
import { DATE_FORMATS } from 'constants/dateFormats';
import { HistoryTableActions } from 'containers/pushNotifications/ManualHistory/HistoryTableActions';

export const ManualNotificationsHistory = () => {
  const { t } = useTranslation();

  const rows = useSelector(selectNotifications);

  const { isLoading } = useSelector(selectNotificationsState);

  const { notificationsPage, notificationsLastPage } = useSelector(selectNotificationMeta);

  const dispatch = useDispatch();

  const { query, onChangeQuery } = useQuery({
    initialQuery: {
      page: notificationsPage,
      limit: 20,
      automatic: false,
      sort: { orderBy: 'created_at', order: 'desc' },
    },
  });

  const onChangePage = useCallback(
    (event, newPage) => {
      if (newPage) {
        onChangeQuery({ page: newPage });
      }
    },
    [onChangeQuery],
  );

  const columns = useMemo(
    () => [
      {
        name: t('pushNotificationsPage.history.name'),
        field: 'title',
        cell: ({ row }) => (
          <Typography fontSize={13} sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
            {row.title}
          </Typography>
        ),
      },
      {
        name: t('pushNotificationsPage.history.content'),
        field: 'content',
        cell: ({ row }) => (
          <Typography fontSize={13} sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
            {row.content}
          </Typography>
        ),
      },
      {
        name: t('pushNotificationsPage.history.dateAndTime'),
        cell: ({ row }) => formatDate(row.schedule, DATE_FORMATS.accountDateFormat),
      },
      {
        name: t('pushNotificationsPage.history.createdAt'),
        cell: ({ row }) => formatDate(row.createdAt, DATE_FORMATS.accountDateFormat),
      },
      {
        name: t('pushNotificationsPage.history.updatedAt'),
        cell: ({ row }) => formatDate(row.updatedAt, DATE_FORMATS.accountDateFormat),
      },
      {
        name: t('pushNotificationsPage.history.actions'),
        cell: HistoryTableActions,
      },
    ],
    [t],
  );

  useEffect(() => {
    dispatch(pushNotificationsSlice.actions.getManualNotificationList(query));
  }, [dispatch, query]);

  useEffect(() => {
    return () => {
      dispatch(pushNotificationsSlice.actions.setNotificationsPage(1));
    };
  }, [dispatch]);

  return (
    <HistoryTable
      isLoading={isLoading}
      rows={rows}
      columns={columns}
      page={notificationsPage}
      lastPage={notificationsLastPage}
      onChangePage={onChangePage}
    />
  );
};
