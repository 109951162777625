import * as yup from 'yup';
import { parse, isValid, differenceInYears } from 'date-fns';
import { enGB } from 'date-fns/locale';

import { GENRES } from 'constants/genres';

yup.addMethod(yup.string, 'isDateValid', function isDateValid(errorMessage) {
  return this.test('testDate', errorMessage, function testDate(value) {
    if (value === null) {
      return true;
    }
    const { path, createError } = this;
    const dateArray = value.split('-');
    const parsed = parse(`${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`, 'P', new Date(), {
      locale: enGB,
    });

    const yearsDifference = differenceInYears(new Date(), parsed);

    return (
      value === '' ||
      null ||
      (isValid(parsed) && yearsDifference >= 15 && yearsDifference <= 120) ||
      createError({ path, message: errorMessage })
    );
  });
});

export const profileSchema = yup.object().shape({
  gender: yup.string().required('accountInfo.error.mandatory').oneOf(Object.keys(GENRES)),
  firstName: yup
    .string()
    .matches(
      /^([A-Za-z\-\u2010\u2212\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      'accountInfo.error.nomValid',
    )
    .required('accountInfo.error.mandatory'),
  lastName: yup
    .string()
    .matches(
      /^([A-Za-z\-\u2010\u2212\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      'accountInfo.error.prenomValid',
    )
    .required('accountInfo.error.mandatory'),
  email: yup
    .string()
    .email('user.error.email')
    .matches(/^[A-Za-z\d_\-+.]+@[A-Za-z\d.]+\.[A-Za-z]{2,}$/, 'user.error.email')
    .required('accountInfo.error.mandatory'),
  birthday: yup
    .string()
    .nullable()
    .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, {
      message: 'accountInfo.error.badDate',
      excludeEmptyString: true,
    })
    .isDateValid('accountInfo.error.dateNotValid')
    .required('accountInfo.error.mandatory'),
});
