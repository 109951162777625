import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { useCallback, useState } from 'react';

import { modalsSlice } from 'ducks/modals';
import { notificationsSlice } from 'ducks/notifications';
import { selectModalById } from 'ducks/modals/selectors';
import { selectNotificationById } from 'ducks/notifications/selectors';
import { PERMISSIONS, ROUTE_MAP_WITH_SCOPES } from 'constants/userRoles';
import { selectRole } from 'ducks/currentUser';

/**
 * Returns the object of functions for working with modals
 * @param {string} [id]
 */
export const useModal = (id) => {
  const dispatch = useDispatch();

  const modalDetails = useSelector((state) => selectModalById(state, id));

  const showModal = useCallback(
    (modal) => {
      const generatedId = uuidV4();

      dispatch(
        modalsSlice.actions.showModal({
          id: id || generatedId,
          ...modal,
        }),
      );
    },
    [dispatch, id],
  );

  const closeModal = useCallback(() => {
    dispatch(modalsSlice.actions.closeModal({ id }));
  }, [dispatch, id]);

  const closeAllModals = useCallback(() => {
    dispatch(modalsSlice.actions.closeAllModals());
  }, [dispatch]);

  const getModalDetails = useCallback(() => modalDetails, [modalDetails]);

  if (!id) {
    return { showModal, closeAllModals };
  }

  return { showModal, getModalDetails, closeModal, closeAllModals };
};

/**
 * Returns the object of functions for working with notifications
 * @param {string} [id]
 */
export const useNotification = (id) => {
  const dispatch = useDispatch();

  const notificationDetails = useSelector((state) => selectNotificationById(state, id));

  const showNotification = useCallback(
    ({ notificationType, title, durationInMilliseconds, onClose }) => {
      const generatedId = uuidV4();

      dispatch(
        notificationsSlice.actions.showNotification({
          id: id || generatedId,
          notificationType,
          title,
          durationInMilliseconds,
          onClose,
        }),
      );
    },
    [dispatch, id],
  );

  const closeNotification = useCallback(() => {
    dispatch(notificationsSlice.actions.closeNotification({ id }));
  }, [dispatch, id]);

  const closeAllNotifications = useCallback(() => {
    dispatch(notificationsSlice.actions.closeAllNotifications());
  }, [dispatch]);

  const getNotificationDetails = useCallback(() => notificationDetails, [notificationDetails]);

  if (!id) {
    return { showNotification, closeAllNotifications };
  }

  return { showNotification, getNotificationDetails, closeNotification, closeAllNotifications };
};

export const useToggle = (initialState) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggleIsOpen = useCallback(() => setIsOpen((state) => !state), []);
  return [isOpen, toggleIsOpen];
};

export const useQuery = ({ initialQuery }) => {
  const [query, setQuery] = useState(initialQuery);

  const onChangeQuery = useCallback((newQuery) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      ...newQuery,
    }));
  }, []);

  return { query, onChangeQuery };
};
/**
 * Returns object of function with boolean parameter indicating whether the given role has access to the given scope
 */
export const usePermission = () => {
  const role = useSelector(selectRole);
  const permissions = PERMISSIONS[role];

  const hasPermission = useCallback(
    (scope) => {
      if (!role) {
        return true;
      }
      return permissions
        ? permissions.some((permission) => permission === ROUTE_MAP_WITH_SCOPES[scope])
        : false;
    },
    [permissions, role],
  );

  return { hasPermission };
};
