import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBoolean } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ManualNotificationForm } from 'components/pushNotifications/ManualNotificationForm';
import { Form } from 'components/common/Form';
import {
  pushNotificationsSlice,
  selectNotificationsState,
  selectNotificationTemplate,
} from 'ducks/pushNotifications';
import { STATUSES } from 'constants/statuses';
import { useNotification } from 'helpers/hooks';
import { manualNotificationSchema } from 'schemas';

export const EditManualNotification = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { id, ...pushNotificationTemplate } = useSelector(selectNotificationTemplate);
  const { isEdited } = useSelector(selectNotificationsState);

  const { showNotification } = useNotification();

  const onSubmit = useCallback(
    (data) => {
      dispatch(
        pushNotificationsSlice.actions.editManualNotification({
          ...data,
          id,
        }),
      );
    },
    [dispatch, id],
  );

  const onCancel = useCallback(() => {
    dispatch(pushNotificationsSlice.actions.resetNotificationTemplate());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(pushNotificationsSlice.actions.resetNotificationTemplate());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isBoolean(isEdited)) {
      showNotification({
        notificationType: isEdited ? STATUSES.success : STATUSES.error,
        title: isEdited
          ? t('notifications.pushNotificationEdited')
          : t('notifications.pushNotificationNotEdited'),
      });
    }
  }, [isEdited, showNotification, t]);

  return (
    <Form onSubmit={onSubmit} validationSchema={manualNotificationSchema}>
      <ManualNotificationForm
        defaultValues={pushNotificationTemplate}
        onCancel={onCancel}
        buttonText="pushNotificationsPage.buttons.edit"
      />
    </Form>
  );
};
