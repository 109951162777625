import { takeLatest, put, call } from 'redux-saga/effects';

import { authSlice } from 'ducks/auth';

import { getAccountsList } from './services';
import { accountsSlice } from './reducer';

function* getAccountsListSaga({ payload }) {
  try {
    const {
      sort: { order, orderBy },
      ...rest
    } = payload;

    const {
      pages,
      page,
      embedded: { items },
    } = yield call(getAccountsList, { ...rest, orderBy: orderBy.toLowerCase(), order });

    const correctItems = items.map(
      ({ accountId, userId, balance, emailCanonical, enabled, firstName, lastName, number }) => ({
        id: userId,
        number,
        user: { firstName, lastName, email: emailCanonical, id: userId, enabled },
        account: { accountId, balance },
      }),
    );

    yield put(accountsSlice.actions.getAccountsSuccess({ pages, page, items: correctItems }));
  } catch (error) {
    yield put(accountsSlice.actions.getAccountsError(error?.response?.data.message));

    if (error?.response?.data?.code === 403) {
      yield put(authSlice.actions.logout());
    }
  }
}

export function* watchAccounts() {
  yield takeLatest(accountsSlice.actions.getAccountsList, getAccountsListSaga);
}
