import React from 'react';
import MuiTextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const TextField = React.memo(
  ({ name, label, isErrorDisplay, defaultValue, triggerAnotherFieldOnChange, ...props }) => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, ...field }, fieldState: { error } }) => {
          const handleChange = (value) => {
            onChange(value);
            triggerAnotherFieldOnChange();
          };
          return (
            <Box mb={2}>
              <MuiTextField
                {...props}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
                error={isErrorDisplay && Boolean(error?.message)}
                label={label}
                helperText={isErrorDisplay && error?.message && t(`${error.message}`)}
                {...field}
              />
            </Box>
          );
        }}
      />
    );
  },
);

TextField.defaultProps = {
  label: '',
  defaultValue: '',
  triggerAnotherFieldOnChange: () => {},
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isErrorDisplay: PropTypes.bool.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  triggerAnotherFieldOnChange: PropTypes.func,
};
