import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, IconButton, InputAdornment, Box, styled } from '@mui/material';
import { Search, ExpandMore } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { SearchTextField } from 'components/common/SearchTextField';

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.button.primary.main,
  color: theme.palette.white,
  alignSelf: 'flex-start',
  margin: theme.spacing(0, 1),
  padding: theme.spacing(1),
  minWidth: 40,
  '&:hover': {
    backgroundColor: theme.palette.button.primary.hover,
  },
}));

export const SearchField = React.memo(
  ({ name, label, children, onSubmit, isResetNeeded, toggleIsResetNeeded }) => {
    const {
      handleSubmit,
      getValues,
      setValue,
      formState: { isSubmitted },
      reset,
    } = useFormContext();

    const [anchorEl, setAnchorEl] = useState(null);

    const [nameStorage, setNameStorage] = useState('');

    const [formStorage, setFormStorage] = useState({});

    const { t } = useTranslation();

    const handleOpenMenu = useCallback(
      (event) => {
        const { currentTarget } = event;

        Object.entries(formStorage).map(([key, value]) => {
          if (key !== name) {
            setValue(key, value);
          }
          return key;
        });

        setNameStorage(getValues(name));
        setValue(name, '');
        setAnchorEl(currentTarget);
      },
      [formStorage, getValues, name, setValue],
    );

    const handleCloseMenu = useCallback(() => {
      const values = getValues();

      setNameStorage(values[name]);
      setFormStorage(values);

      Object.entries(values).map(([key]) => setValue(key, ''));

      setAnchorEl(null);
    }, [getValues, name, setValue]);

    useEffect(() => {
      setValue(name, nameStorage);
    }, [anchorEl, name, nameStorage, setValue]);

    useEffect(() => {
      if (isSubmitted) {
        reset({}, { keepValues: true });
      }
    }, [isSubmitted, reset]);

    useEffect(() => {
      if (isResetNeeded) {
        Object.keys(getValues()).map((key) => setValue(key, ''));
        setFormStorage({});
        toggleIsResetNeeded();
      }
    }, [getValues, isResetNeeded, setValue, toggleIsResetNeeded]);

    const isClosed = !anchorEl;

    return (
      <>
        <Box display="flex">
          <SearchTextField
            sx={{ minWidth: 400 }}
            placeholder={isClosed ? label : t('search.search')}
            name={isClosed ? name : 'search'}
            isErrorDisplay={false}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleOpenMenu}>
                    <ExpandMore />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isClosed ? (
            <Button onClick={handleSubmit(onSubmit)}>
              <Search />
            </Button>
          ) : (
            <Box minWidth={56} minHeight={40} />
          )}
        </Box>
        <Dropdown anchorEl={anchorEl} handleCloseMenu={handleCloseMenu}>
          {children}
        </Dropdown>
      </>
    );
  },
);

SearchField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  isResetNeeded: PropTypes.bool,
  toggleIsResetNeeded: PropTypes.func,
};
SearchField.defaultProps = {
  children: () => <div />,
  isResetNeeded: false,
  toggleIsResetNeeded: () => {},
};
