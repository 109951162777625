import React from 'react';
import PropTypes from 'prop-types';

import { CollapseItem } from 'components/common/CollapseBlock/CollapseItem';

export const CollapseBlock = React.memo(({ items }) => {
  const [expanded, setExpanded] = React.useState({});

  const handleChange = (panel, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  return (
    <>
      {items.map(({ title, component, action, isHidden }, index) => {
        const isFirst = index === 0;
        const isExpanded = isFirst ? true : expanded[title] === true;
        return (
          !isHidden && (
            <CollapseItem
              isFirst={isFirst}
              key={title}
              isExpanded={isExpanded}
              title={title}
              component={component}
              action={action}
              onChange={handleChange}
            />
          )
        );
      })}
    </>
  );
});

CollapseBlock.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      component: PropTypes.elementType,
      action: PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      }),
      isHidden: PropTypes.bool,
    }),
  ),
};

CollapseBlock.defaultProps = {
  items: [],
};
