import React, { useCallback, useMemo, useState } from 'react';
import { Grid, styled, Toolbar, Typography } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import { AccountCircle } from '@mui/icons-material';
import PropTypes from 'prop-types';
import MuiAppBar from '@mui/material/AppBar';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { SIDE_BAR_WIDTH } from 'constants/sideBarWidth';
import { ROUTES } from 'constants/routes';
import { Dropdown } from 'components/common/Dropdown';

/**
 * Modify MuiAppBar to render properly with SideBar
 */
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => ({
  boxShadow: 'none',
  width: `calc(100% - ${SIDE_BAR_WIDTH.close}px)`,
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.topBar.main,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isOpen && {
    marginLeft: SIDE_BAR_WIDTH.open,
    width: `calc(100% - ${SIDE_BAR_WIDTH.open}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

/**
 * Modify style of icon in topBar on hover
 */
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  '&: hover': {
    backgroundColor: theme.palette.topBar.profileHover,
  },
}));

const topBarTitlesMap = {
  [ROUTES.accountsList]: 'topBar.loyaltyUsers',
  [ROUTES.accountTemplate]: 'topBar.account',
  [ROUTES.usersList]: 'topBar.adminUsers',
  [ROUTES.createUser]: 'topBar.adminUsers',
  [ROUTES.editUserTemplate]: 'topBar.adminUsers',
  [ROUTES.storesList]: 'topBar.stores',
  [ROUTES.storeMenuTemplate]: 'topBar.storeMenu',
  [ROUTES.storeInformationTemplate]: 'topBar.storeInformation',
  [ROUTES.storeCustomTemplate]: 'topBar.storeCustom',
  [ROUTES.ordersPendingTab]: 'topBar.ordersPending',
  [ROUTES.ordersPreparedTab]: 'topBar.ordersPrepared',
  [ROUTES.ordersDeliveredTab]: 'topBar.ordersDelivered',
  [ROUTES.ordersCanceledTab]: 'topBar.ordersCanceled',
  [ROUTES.ordersErrorTab]: 'topBar.ordersError',
  [ROUTES.ordersSearchTab]: 'topBar.search',
  [ROUTES.orderTemplate]: 'topBar.order',
  [ROUTES.mobileImages]: 'topBar.mobileImages',
  [ROUTES.news]: 'topBar.news',
  [ROUTES.notificationsTemplate]: 'topBar.pushNotifications',
};

export const TopBarComponent = ({ isOpen, onLogout, onPasswordChange, username }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();

  const location = useLocation();

  const handleOpenMenu = useCallback((event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    handleCloseMenu();
    onLogout();
  }, [handleCloseMenu, onLogout]);

  const handleChangePassword = useCallback(() => {
    handleCloseMenu();
    onPasswordChange();
  }, [handleCloseMenu, onPasswordChange]);

  const locationTemplate = location.pathname.split('/').slice(0, -1).join('/');

  const menuItemsArray = useMemo(
    () => [
      { text: username, isDividerNeeded: true },
      { text: t('account.changePassword'), onClick: handleChangePassword },
      { text: t('account.logout'), onClick: handleLogout },
    ],
    [username, t, handleChangePassword, handleLogout],
  );

  return (
    <AppBar position="fixed" isOpen={isOpen}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={4} sm={2} />

          <Grid container item xs={4} sm={8} justifyContent="center">
            <Typography fontSize={18} color="topBar.text" noWrap component="div">
              {t(topBarTitlesMap[location.pathname] || topBarTitlesMap[locationTemplate])}
            </Typography>
          </Grid>

          <Grid container item xs={4} sm={2} justifyContent="flex-end">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Dropdown
              anchorEl={anchorEl}
              handleCloseMenu={handleCloseMenu}
              menuItemsArray={menuItemsArray}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

TopBarComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};
