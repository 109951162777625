import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const Services = ({ services, servicesList, onToggleService }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5">{t('storePage.services')}</Typography>
      <Box display="flex" flexDirection="row">
        {servicesList.map((service) => {
          const { id, name, slug, type } = service;
          const isActive = services.find((serviceItem) => serviceItem.id === id);

          return (
            <Box key={id} display="flex" flexDirection="column" alignItems="center" m={1}>
              <IconButton id={type} onClick={onToggleService}>
                <img
                  id={type}
                  src={`https://platform.pommedepain.fr/media/thumbnail/png/25/25/${slug}`}
                  alt={name}
                  style={{ filter: isActive ? 'none' : 'grayscale(var(--value, 100%))' }}
                />
              </IconButton>
              <Typography variant="body2">{name}</Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

Services.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  servicesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  onToggleService: PropTypes.func,
};

Services.defaultProps = {
  services: [],
  servicesList: [],
  onToggleService: () => {},
};
