import request from 'helpers/request';

export const getNewsList = () =>
  request.get('/api/content-management', { params: { category: 'news', paginate: false } });

export const addNewsItem = ({ image, title, text }) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('category', 'news');
  formData.append('title', title);
  formData.append('description', text);

  return request.post('/api/content-management/images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const editNewsItem = ({ id, image, title, text }) => {
  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
  formData.append('category', 'news');
  if (title || title === '') {
    formData.append('title', title);
  }
  if (text || text === '') {
    formData.append('description', text);
  }

  return request.post(`/api/content-management/${id}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteNewsItem = ({ id }) => request.delete(`/api/content-management/${id}/images`);

export const sendNewListOrder = ({ newOrder }) =>
  request.post('/api/content-management/images-order', {
    category: 'news',
    order: newOrder,
  });
