import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/routes';
import { LoyaltyUsersPage } from 'pages/LoyaltyUsersPage';
import { AdminUsersPage } from 'pages/AdminUsersPage';
import { StoresPage } from 'pages/StoresPage';
import { OrdersPage } from 'pages/OrdersPage';
import { Page } from 'components/common/Page';
import { EditUserPage } from 'pages/EditUserPage';
import { LoginPage } from 'pages/LoginPage';
import { PrivateRoute } from 'components/common/PrivateRoute';
import { AccountPage } from 'pages/AccountPage';
import { StorePage } from 'pages/StorePage';
import { OrderPage } from 'pages/OrderPage';
import { PushNotificationsPage } from 'pages/PushNotificationsPage';
import { MobileImagesPage } from 'pages/MobileImagesPage';
import { NewsPage } from 'pages/NewsPage';

export const AppComponent = ({ isAuthenticated, route }) => (
  <Page>
    <Switch>
      <PrivateRoute
        isAuthenticated={!isAuthenticated}
        redirectRoute={route}
        exact
        path={ROUTES.login}
        component={LoginPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.accountsList}
        component={LoyaltyUsersPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.usersList}
        component={AdminUsersPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.storesList}
        component={StoresPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={[ROUTES.storeMenu, ROUTES.storeInformation, ROUTES.storeCustom]}
        component={StorePage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={[
          ROUTES.ordersPendingTab,
          ROUTES.ordersPreparedTab,
          ROUTES.ordersDeliveredTab,
          ROUTES.ordersCanceledTab,
          ROUTES.ordersErrorTab,
          ROUTES.ordersSearchTab,
        ]}
        component={OrdersPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.order}
        component={OrderPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.editUser}
        component={EditUserPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.account}
        component={AccountPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.createUser}
        component={EditUserPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={[
          ROUTES.notificationsAutomaticTab,
          ROUTES.notificationsManualTab,
          ROUTES.notificationsHistoryTab,
        ]}
        component={PushNotificationsPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.mobileImages}
        component={MobileImagesPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path={ROUTES.news}
        component={NewsPage}
      />
      <Redirect from={ROUTES.main} to={ROUTES.accountsList} />
    </Switch>
  </Page>
);

AppComponent.defaultProps = {
  route: undefined,
};

AppComponent.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  route: PropTypes.string,
};
