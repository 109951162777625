import React from 'react';
import { styled, TableCell as MuiTableCell, TableRow as MuiTableRow } from '@mui/material';
import PropTypes from 'prop-types';

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 13,
  borderWidth: 0,
  borderRightWidth: 1,
  borderBottomWidth: 1,
  borderColor: theme.palette.table.border,
  borderRightStyle: 'dashed',
  borderBottomStyle: 'solid',
  '&:last-of-type': {
    borderRightWidth: 0,
  },
}));

const StyledTableRow = styled(MuiTableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.table.columnOdd,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.table.columnEven,
  },
  '&:hover': {
    backgroundColor: theme.palette.table.hover,
  },
}));

export const TableRow = React.memo(({ row, columns }) => (
  <StyledTableRow>
    {columns.map(({ name, field, cell: Cell }) => (
      <TableCell key={name}>{Cell ? <Cell row={row} /> : row[field]}</TableCell>
    ))}
  </StyledTableRow>
));

TableRow.propTypes = {
  row: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      field: PropTypes.string,
      cell: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }).isRequired,
  ).isRequired,
};
