import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export const Image = React.memo(({ src, alt, ...props }) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    sx={{
      width: '100%',
      height: 'auto',
      ...props,
    }}
  />
));

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
