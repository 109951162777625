import React from 'react';
import PropTypes from 'prop-types';

export const TabPanel = React.memo(({ selectedValue, value, component: Component }) => {
  return <>{value === selectedValue ? <Component /> : null}</>;
});

TabPanel.defaultProps = {
  component: () => {
    <></>;
  },
};
TabPanel.propTypes = {
  component: PropTypes.elementType,
  selectedValue: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
