import React from 'react';
import {
  AccordionSummary as MuiAccordionSummary,
  Button as MuiButton,
  Typography,
  styled,
} from '@mui/material';
import { ExpandMore as MuiIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

const ExpandMore = styled(MuiIcon, { shouldForwardProp: (prop) => prop !== 'expanded' })(
  ({ theme, expanded }) => ({
    color: theme.palette.accordion.color,
    height: 'auto',
    transform: expanded ? 'rotate(180deg)' : 'none',
  }),
);

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  color: theme.palette.black,
  backgroundColor: theme.palette.accordion.title,
  borderBottom: 0,
  borderTopRightRadius: 1,
  borderTopLeftRadius: 1,
  borderColor: theme.palette.accordion.border,
  maxHeight: 55,
  '& .MuiAccordionSummary-content': {
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& svg': {
    maxHeight: 33,
  },
}));

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.accordion.button,
  color: theme.palette.accordion.details,
  borderRadius: 0,
  fontSize: 12,
  minWidth: '90px',
  textDecoration: 'none',
  maxHeight: 33,
  '&:hover': {
    backgroundColor: theme.palette.accordion.hover,
    textDecoration: 'none',
  },
}));

export const AccordionHeader = React.memo(
  ({ expanded, isFirst, title, action: { label, onClick } }) => (
    <AccordionSummary
      expandIcon={
        <>
          {!isFirst && <ExpandMore expanded={expanded} />}
          <Button onClick={onClick}>{label}</Button>
        </>
      }
    >
      <Typography variant="h5">{title}</Typography>
    </AccordionSummary>
  ),
);

AccordionHeader.propTypes = {
  expanded: PropTypes.bool,
  isFirst: PropTypes.bool.isRequired,
  title: PropTypes.string,
  action: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
};
AccordionHeader.defaultProps = {
  expanded: true,
  title: 'Title',
  action: {
    label: 'Voir',
    onClick: () => {},
  },
};
