import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { SideBar } from 'components/common/SideBar';
import { useToggle } from 'helpers/hooks';
import { TopBar } from 'containers/common/TopBar';
import { NavBar } from 'containers/common/NavBar';

export const CommonLayout = ({ children }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <Box display="flex">
      <TopBar isOpen={isOpen} />
      <SideBar isOpen={isOpen} handleToggleSideBar={toggleIsOpen}>
        <NavBar isOpen={isOpen} />
      </SideBar>
      <Box component="main" flexGrow={1} p={3} mt={8}>
        {children}
      </Box>
    </Box>
  );
};

CommonLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
