import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  selectStoreIsLoading,
  selectStoreMeta,
  selectStoreProducts,
  storeSlice,
} from 'ducks/store';
import { useModal, useQuery } from 'helpers/hooks';
import { getKeysWithSpecifiedValue } from 'helpers/helpers';
import { MODAL_TYPES } from 'constants/modals';
import { StoreTable } from 'components/store/StoreTable';
import { Form } from 'components/common/Form';
import { StoreSearch } from 'components/store/StoreSearch';
import { getFilteredProductNamesWithSameOrder } from 'helpers/store';

export const StoreMenu = () => {
  const [isSuccessClicked, setIsSuccessClicked] = useState(false);

  const dispatch = useDispatch();

  const items = useSelector(selectStoreProducts);

  const { currentPage: page, lastPage } = useSelector(selectStoreMeta);

  const isLoading = useSelector(selectStoreIsLoading);

  const { storeId } = useParams();

  const { showModal } = useModal();

  const { t } = useTranslation();

  const { query, onChangeQuery } = useQuery({
    initialQuery: { page, storeId, search: {}, sort: { order: 'none' } },
  });

  const onSubmit = useCallback(
    (data) => {
      const handleChangeDeliveryStatus = () => {
        getKeysWithSpecifiedValue(data, true).map((productId) =>
          dispatch(
            storeSlice.actions.changeDeliveryStatus({
              storeId,
              productId,
              issue: 'Temporary disable',
            }),
          ),
        );

        setIsSuccessClicked(true);
      };

      setIsSuccessClicked(false);

      showModal({
        modalType: MODAL_TYPES.confirmationWithList,
        title: t('modals.confirmTemporaryDisableModal.header'),
        body: t('modals.confirmTemporaryDisableModal.body'),
        list: getFilteredProductNamesWithSameOrder({
          productItems: items,
          filteredProductsIds: getKeysWithSpecifiedValue(data, true),
        }),
        onSuccess: handleChangeDeliveryStatus,
      });
    },
    [dispatch, items, showModal, storeId, t],
  );

  useEffect(() => {
    dispatch(storeSlice.actions.getProductsList(query));
  }, [dispatch, query]);

  useEffect(() => {
    return () => {
      dispatch(storeSlice.actions.setCurrentPage(1));
    };
  }, [dispatch]);

  return (
    <>
      <StoreSearch onChangeQuery={onChangeQuery} />
      <Form onSubmit={onSubmit}>
        <StoreTable
          rows={items}
          page={page}
          lastPage={lastPage}
          isLoading={isLoading}
          query={query}
          onChangeQuery={onChangeQuery}
          isSuccessClicked={isSuccessClicked}
        />
      </Form>
    </>
  );
};
