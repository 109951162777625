import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button as MuiButton, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ProfileForm } from 'containers/account/ProfileForm';

import { ProfileList } from './ProfileList';

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.accordion.button,
  '&:hover': {
    backgroundColor: theme.palette.accordion.hover,
  },
  color: theme.palette.accordion.details,
  borderRadius: 0,
  fontSize: 12,
  padding: theme.spacing(0, 2),
  margin: theme.spacing(0, 0, 5, 24),
}));

export const ProfileCollapseComponent = React.memo(
  ({ isModified, profile, handleReset, setIsModified }) => {
    const { t } = useTranslation();
    const handleClick = useCallback(() => handleReset(profile.id), [profile.id, handleReset]);
    return (
      <Box border="1px solid #e9e9e9" marginTop={1}>
        {isModified ? (
          <ProfileForm profile={profile} setIsModified={setIsModified} />
        ) : (
          <ProfileList profile={profile} />
        )}
        <Button onClick={handleClick}>{t('accountInfo.resetPassword')}</Button>
      </Box>
    );
  },
);

ProfileCollapseComponent.propTypes = {
  setIsModified: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  isModified: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number,
    birthday: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.shape({
      id: PropTypes.number,
      shortTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
};
