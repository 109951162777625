import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { TextField } from 'components/common/TextField/TextField';

export const EditBalanceForm = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" ml={1} mr={1}>
      <TextField
        type="number"
        name="amount"
        label={t('modals.changeBalanceModal.amountToAdd')}
        isErrorDisplay
        defaultValue={0}
        InputLabelProps={{ shrink: true }}
      />
      <TextField name="complaint" label={t('modals.changeBalanceModal.complaint')} isErrorDisplay />
    </Box>
  );
});
