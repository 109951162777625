import React from 'react';
import { List } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import {
  faHome,
  faMobileAlt,
  faShoppingCart,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import { NavBarItem } from 'components/common/NavBar/NavBarItem';
import { NavBarGreeting } from 'containers/common/NavBar/NavBarGreeting';

export const NavBarComponent = React.memo(({ isOpen, listDataArray }) => {
  const location = useLocation();

  return (
    <>
      <NavBarGreeting isOpen={isOpen} />
      <List>
        {listDataArray.map(
          ({ title, subtitles, permissionGranted, route, routeTemplate, icon }, index) => {
            const isCurrent =
              location.pathname === route || location.pathname.includes(routeTemplate);

            return permissionGranted ? (
              <NavBarItem
                key={title}
                sx={index + 1 === listDataArray.length ? { mt: 3 } : {}}
                isOpen={isOpen}
                isCurrent={isCurrent}
                title={title}
                icon={icon}
                route={route}
                subtitles={subtitles}
              />
            ) : null;
          },
        )}
      </List>
    </>
  );
});

NavBarComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  listDataArray: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOf([faUser, faUsers, faHome, faShoppingCart, faMobileAlt]),
      route: PropTypes.string.isRequired,
      routeTemplate: PropTypes.string,
      title: PropTypes.string.isRequired,
      subtitles: PropTypes.arrayOf(
        PropTypes.shape({
          subtitle: PropTypes.string.isRequired,
          onClick: PropTypes.func,
        }),
      ),
    }),
  ).isRequired,
};
