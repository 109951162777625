import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { parseISO } from 'date-fns';

import { WEEKDAYS } from 'constants/weekDays';
import { TimePickerField } from 'components/common/Timepicker';
import { SelectField } from 'components/common/SelectField';
import { Checkbox } from 'components/common/Checkbox/Checkbox';

export const ScheduleForm = React.memo(({ schedules }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const selectOptions = useMemo(
    () => schedules.map(({ id, name }) => ({ id, value: t(`storePage.select.${name}`) })),
    [schedules, t],
  );

  const currentSelectId = watch('type', schedules[0]?.id);

  const schedule = useMemo(
    () =>
      schedules
        .find((item) => item.id === currentSelectId)
        ?.scheduleLines.map((line) => {
          return {
            ...line,
            openClose: {
              open: parseISO(
                `2021-01-01T${line.openClose.open.slice(0, 2) || '00'}:${
                  line.openClose.open.slice(2) || '00'
                }:00`,
              ),
              close: parseISO(
                `2021-01-01T${line.openClose.close.slice(0, 2) || '00'}:${
                  line.openClose.close.slice(2) || '00'
                }:00`,
              ),
            },
          };
        }),
    [currentSelectId, schedules],
  );

  const currentRestaurantStates = schedule?.map(({ closed }, index) =>
    watch(`isClosed${index}`, closed),
  );

  useEffect(() => {
    schedule?.map(({ closed }, index) => setValue(`isClosed${index}`, closed));
  }, [setValue, schedule, currentSelectId]);

  return (
    <>
      <SelectField
        label={t('storePage.scheduleType')}
        options={selectOptions}
        defaultValue={schedules[0]?.id}
        name="type"
        sx={{ width: '30%', minWidth: 200 }}
      />
      {schedule?.map(({ dayOfWeek, closed, openClose: { open, close } }, index) => (
        <Box key={dayOfWeek} display="flex" flexDirection="row" alignItems="center" m={0.5}>
          <Typography minWidth={80}> {t(`weekDays.${WEEKDAYS[dayOfWeek]}`)}</Typography>
          <TimePickerField
            schedule={open}
            isClosed={currentRestaurantStates[index]}
            label={t('storePage.timepicker.open')}
            fieldName={`open${index}`}
          />
          -
          <TimePickerField
            schedule={close}
            isClosed={currentRestaurantStates[index]}
            label={t('storePage.timepicker.close')}
            fieldName={`close${index}`}
          />
          <Checkbox name={`isClosed${index}`} defaultValue={closed} />
          <Typography ml={1}>{t(`weekDays.isClosed`)}</Typography>
        </Box>
      ))}
    </>
  );
});

ScheduleForm.propTypes = {
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      scheduleLines: PropTypes.arrayOf(
        PropTypes.shape({
          dayOfWeek: PropTypes.number,
          openClose: PropTypes.shape({
            open: PropTypes.string,
            close: PropTypes.string,
          }),
        }),
      ),
    }),
  ).isRequired,
};
