import React, { useCallback, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { MODAL_TYPES } from 'constants/modals';
import { Loader } from 'components/common/Loader';
import { useModal } from 'helpers/hooks';
import { StoreCustomListItem } from 'containers/store/StoreCustomListItem';
import {
  selectIsStoreContentIdsEqual,
  selectStoreContentIds,
  selectStoreContentIsLoading,
  storeSlice,
} from 'ducks/store';
import { DraggableElement } from 'components/common/DraggableElement';

export const StoreCustom = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { storeId } = useParams();

  const storeContentIds = useSelector(selectStoreContentIds);

  const isLoading = useSelector(selectStoreContentIsLoading);

  const isIdsEqual = useSelector(selectIsStoreContentIdsEqual);

  const { showModal } = useModal();

  const handleCreateStoreContent = useCallback(
    (data) => {
      dispatch(
        storeSlice.actions.addStoreContent({
          image: data.image[0],
          text: data.text,
          storeId,
        }),
      );
    },
    [dispatch, storeId],
  );

  const onAddClick = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.addStoreContent,
      title: t('modals.storeContent.addHeader'),
      onSuccess: handleCreateStoreContent,
    });
  }, [handleCreateStoreContent, showModal, t]);

  const onFinishReorderClick = useCallback(() => {
    dispatch(storeSlice.actions.sendNewListOrder({ newOrder: storeContentIds, storeId }));
  }, [dispatch, storeContentIds, storeId]);

  const moveElement = useCallback(
    (dragIndex, hoverIndex) => {
      dispatch(storeSlice.actions.reorderItems({ dragIndex, hoverIndex }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(storeSlice.actions.getStoreContentList({ storeId }));
  }, [dispatch, storeId]);

  useEffect(() => {
    return () => {
      dispatch(storeSlice.actions.resetStoreContent());
    };
  }, [dispatch]);

  return (
    <>
      <Loader isLoading={isLoading}>
        {storeContentIds.map((id, index) => (
          <DraggableElement
            key={id}
            dragType="mobileImage"
            moveElement={moveElement}
            id={id}
            index={index}
          >
            <StoreCustomListItem id={id} key={id} />
          </DraggableElement>
        ))}
      </Loader>
      <Box display="flex" flexDirection="column">
        {!isIdsEqual && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              sx={{ maxWidth: 180, mb: 2, mt: 2 }}
              variant="contained"
              onClick={onFinishReorderClick}
            >
              {t('storeContent.finishReorder')}
            </Button>
          </Box>
        )}
        <Button sx={{ maxWidth: 180 }} variant="contained" onClick={onAddClick}>
          {t('storeContent.addButton')}
        </Button>
      </Box>
    </>
  );
};
