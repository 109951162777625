import { takeLatest, put, call } from 'redux-saga/effects';

import {
  addNewsItem,
  deleteNewsItem,
  editNewsItem,
  getNewsList,
  sendNewListOrder,
} from 'ducks/news/services';
import { newsSlice } from 'ducks/news/reducer';
import { normalize } from 'ducks/mapper';

function* getNewsListSaga() {
  try {
    const items = yield call(getNewsList);

    yield put(newsSlice.actions.getNewsListSuccess({ items: normalize(items) }));
  } catch (error) {
    yield put(newsSlice.actions.getNewsListFailed(error?.response?.data));
  }
}

function* addMewsItemSaga({ payload: { image, title, text } }) {
  try {
    yield call(addNewsItem, { image, title, text });

    yield put(newsSlice.actions.addNewsItemSuccess());

    yield put(newsSlice.actions.getNewsList());
  } catch (error) {
    yield put(newsSlice.actions.addNewsItemFailed(error?.response?.data));
  }
}

function* editNewsItemImageSaga({ payload: { id, image } }) {
  try {
    yield call(editNewsItem, { id, image });

    yield put(newsSlice.actions.editNewsItemImageSuccess({ id, image }));

    yield put(newsSlice.actions.getNewsList());
  } catch (error) {
    yield put(newsSlice.actions.editNewsItemImageFailed(error?.response?.data));
  }
}

function* editNewsItemTextSaga({ payload: { id, title, text } }) {
  try {
    yield call(editNewsItem, { id, title, text });

    yield put(newsSlice.actions.editNewsItemTextSuccess({ id, title, text }));

    yield put(newsSlice.actions.getNewsList());
  } catch (error) {
    yield put(newsSlice.actions.editNewsItemTextFailed(error?.response?.data));
  }
}

function* deleteNewsItemSaga({ payload: { id } }) {
  try {
    yield call(deleteNewsItem, { id });

    yield put(newsSlice.actions.deleteNewsItemSuccess({ id }));
  } catch (error) {
    yield put(newsSlice.actions.deleteNewsItemFailed(error?.response?.data));
  }
}

function* sendNewListOrderSaga({ payload: { newOrder } }) {
  try {
    yield call(sendNewListOrder, { newOrder });

    yield put(newsSlice.actions.sendNewListOrderSuccess({ newOrder }));

    yield put(newsSlice.actions.getNewsList());
  } catch (error) {
    yield put(newsSlice.actions.sendNewListOrderFailed(error?.response?.data));
  }
}

export function* watchNews() {
  yield takeLatest(newsSlice.actions.getNewsList, getNewsListSaga);
  yield takeLatest(newsSlice.actions.addNewsItem, addMewsItemSaga);
  yield takeLatest(newsSlice.actions.editNewsItemImage, editNewsItemImageSaga);
  yield takeLatest(newsSlice.actions.editNewsItemText, editNewsItemTextSaga);
  yield takeLatest(newsSlice.actions.deleteNewsItem, deleteNewsItemSaga);
  yield takeLatest(newsSlice.actions.sendNewListOrder, sendNewListOrderSaga);
}
