import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPES } from 'constants/modals';
import { useNotification, useModal } from 'helpers/hooks';
import { NOTIFICATION_TYPES } from 'constants/notifications';
import {
  accountSlice,
  selectHistory,
  selectInformation,
  selectIsLoading,
  selectPasswordStatus,
  selectProfile,
  selectStatus,
  selectError,
  selectAccountHistoryMeta,
  selectIsAdditionalHistoryLoading,
} from 'ducks/account';
import { CollapseBlock } from 'components/common/CollapseBlock';
import { ProfileCollapse } from 'containers/account/ProfileCollapse';
import { HistoryCollapse } from 'components/account/HistoryCollapse/HistoryCollapse';
import { DeactivateCollapse } from 'containers/account/DeactivateCollapse';
import { InformationCollapse } from 'containers/account/InformationCollapse';
import { Loader } from 'components/common/Loader/Loader';
import { selectRole } from 'ducks/currentUser';
import { USER_ROLES } from 'constants/userRoles';

const roleWithDeactivationPermissions = [USER_ROLES.superAdmin, USER_ROLES.pdpAdmin];

export const AccountPage = () => {
  const [isModified, setIsModified] = useState(false);

  const { t } = useTranslation();
  const { accountId } = useParams();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);

  const role = useSelector(selectRole);
  const profile = useSelector(selectProfile);
  const history = useSelector(selectHistory);
  const information = useSelector(selectInformation);

  const status = useSelector(selectStatus);
  const resetPasswordStatus = useSelector(selectPasswordStatus);

  const { showModal } = useModal();
  const { showNotification } = useNotification();

  const error = useSelector(selectError);

  const { page, lastPage } = useSelector(selectAccountHistoryMeta);

  const isAdditionalHistoryLoading = useSelector(selectIsAdditionalHistoryLoading);

  useEffect(() => {
    if (error) {
      showNotification({
        notificationType: NOTIFICATION_TYPES.error,
        title: t(`account.${error}`, error),
        durationInMilliseconds: 5000,
        onClose: () => {
          dispatch(accountSlice.actions.clearError());
        },
      });
    }
  }, [t, error, showNotification, dispatch]);

  useEffect(() => {
    if (accountId) {
      dispatch(accountSlice.actions.getAccount(accountId));
    }
  }, [accountId, dispatch]);

  const handleClick = useCallback(() => {
    if (profile.gender) {
      setIsModified(!isModified);
    }
  }, [isModified, profile.gender]);

  const handleShowMoreHistory = useCallback(() => {
    dispatch(
      accountSlice.actions.getHistory({
        id: profile.userId,
        params: {
          page: page + 1,
          scope: 'order_full,promotion_uses_full',
        },
      }),
    );
  }, [dispatch, page, profile.userId]);

  useEffect(() => {
    if (status) {
      showNotification({
        notificationType: NOTIFICATION_TYPES.success,
        title: t(`notifications.${status}`),
        durationInMilliseconds: 5000,
        onClose: () => {
          dispatch(accountSlice.actions.clearStatus());
        },
      });
    }
  }, [t, status, showNotification, dispatch]);

  useEffect(() => {
    if (resetPasswordStatus) {
      showModal({
        modalType: MODAL_TYPES.acknowledgment,
        body: t(`modals.${resetPasswordStatus}.body`),
        title: t(`modals.${resetPasswordStatus}.header`),
        onSuccess: () => {
          dispatch(accountSlice.actions.clearPasswordStatus());
        },
      });
    }
  }, [t, showModal, resetPasswordStatus, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(accountSlice.actions.resetHistory());
    };
  }, [dispatch]);

  const items = React.useMemo(
    () => [
      {
        title: t('account.userProfileTitle', {
          firstname: profile.firstName || '',
          lastname: profile.lastName || '',
        }),
        component: () => (
          <ProfileCollapse
            isModified={isModified}
            profile={profile}
            setIsModified={setIsModified}
          />
        ),
        action: {
          label: isModified ? t('common.view') : t('common.modify'),
          onClick: handleClick,
        },
      },
      {
        title: t('account.loyaltyInfosTitle'),
        component: () => (
          <InformationCollapse
            information={{ ...information, userId: profile.userId }}
            enabled={profile.enabled}
          />
        ),
        action: {
          label: t('common.view'),
        },
      },
      {
        title: t('account.loyaltyTransactionsTitle'),
        component: () => (
          <HistoryCollapse
            history={history}
            initialBalance={information?.balance}
            isButtonVisible={page !== lastPage && !isAdditionalHistoryLoading}
            onClick={handleShowMoreHistory}
          />
        ),
        action: {
          label: t('common.view'),
        },
      },
      {
        title: t('account.disableAccountTitle'),
        component: () => <DeactivateCollapse userId={profile.userId} />,
        action: {
          label: t('common.view'),
        },
        isHidden: !roleWithDeactivationPermissions.includes(role),
      },
    ],
    [
      t,
      profile,
      isModified,
      handleClick,
      role,
      information,
      history,
      page,
      lastPage,
      isAdditionalHistoryLoading,
      handleShowMoreHistory,
    ],
  );
  return (
    <Loader isLoading={isLoading}>
      <CollapseBlock items={items} />
    </Loader>
  );
};
