import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Typography, styled, Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const Link = styled(MuiLink)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.link.main,
  '&:hover': {
    color: theme.palette.link.hover,
  },
}));

const BreadcrumbsContainer = styled(MuiBreadcrumbs)(() => ({
  '& .MuiBreadcrumbs-separator': {
    marginRight: 4,
    marginLeft: 4,
    fontSize: 18,
  },
}));

export const Breadcrumbs = ({ breadcrumbsItemsArray }) => (
  <BreadcrumbsContainer aria-label="breadcrumb" separator=">" component="h4">
    {breadcrumbsItemsArray.map(({ text, route }, index) => {
      const isFirst = index === 0;
      return isFirst ? (
        <Link key={text} component={RouterLink} to={route} underline="hover">
          {text}
        </Link>
      ) : (
        <Typography fontSize={18} key={text} color="body.text.main">
          {text}
        </Typography>
      );
    })}
  </BreadcrumbsContainer>
);

Breadcrumbs.propTypes = {
  breadcrumbsItemsArray: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      route: PropTypes.string,
    }),
  ).isRequired,
};
