/**
 * Returns the array of product names based on filteredProductsIds array
 * @param {{id: number, productName: string}[]} productItems
 * @param {string[]} filteredProductsIds
 */
export const getFilteredProductNamesWithSameOrder = ({ productItems, filteredProductsIds }) =>
  productItems.reduce((acc, { id, productName }) => {
    if (filteredProductsIds.includes(String(id))) {
      acc.push(productName);
    }

    return acc;
  }, []);
