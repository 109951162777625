// The file importing all language files.

const requireModule = require.context('.', true, /(\.json)$/);

const files = requireModule.keys();

export const messages = files.reduce((acc, fileName) => {
  const [, language] = fileName.match(/\/(\w+).json/);

  if (language && language !== 'index') {
    acc[language] = requireModule(fileName);
  }

  return acc;
}, {});
