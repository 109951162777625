import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

export const selectStore = (state) => state.store;

export const selectStoreProductsById = (state) => state.store.productsById;
export const selectStoreProductsIds = (state) => state.store.productsIds;

export const selectStoreIsLoading = (state) => state.store.isLoading;

export const selectCurrentStore = (state) => state.store.store;

export const selectStoreMeta = (state) => state.store.meta;

export const selectStoreProducts = createSelector(
  selectStoreProductsById,
  selectStoreProductsIds,
  (productsById, productsIds) => productsIds.map((id) => productsById[id]),
);

export const selectServicesList = ({ store: { servicesById, servicesIds } }) =>
  servicesIds.map((id) => servicesById[id]);

export const selectStoreContentIds = (state) => state.store.listIds;
export const selectStoreContentById = (state) => state.store.listById;

export const selectStoreContentIsLoading = (state) => state.store.isLoading;

export const selectCurrentStoreContent = (state) => state.store.currentStoreContent;

export const selectIsStoreContentIdsEqual = (state) =>
  isEqual(state.store.listIds, state.store.initialListIds);
