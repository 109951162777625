import { createSlice } from '@reduxjs/toolkit';

import { STATUSES } from 'constants/statuses';

const initialState = {
  status: '',
  isLoading: false,
  error: {},
};

export const exportContactsSlice = createSlice({
  name: '@@exportContacts',
  initialState,
  reducers: {
    exportContacts: (state) => {
      state.status = initialState.status;
      state.isLoading = true;
    },
    exportContactsSuccess: (state) => {
      state.status = STATUSES.success;
      state.isLoading = false;
    },
    exportContactsFailed: (state, action) => {
      state.status = STATUSES.error;
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});
