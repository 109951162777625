import { all, call } from 'redux-saga/effects';

import { watchUsers } from 'ducks/users/sagas';
import { watchAuth } from 'ducks/auth/sagas';
import { watchCurrentUser } from 'ducks/currentUser/sagas';
import { watchOrder } from 'ducks/orders/sagas';
import { watchAccount } from 'ducks/account/sagas';
import { watchAccounts } from 'ducks/accounts/sagas';
import { watchStore } from 'ducks/store/sagas';
import { watchStores } from 'ducks/stores/sagas';
import { watchExportContacts } from 'ducks/exportContacts/sagas';
import { watchPushNotifications } from 'ducks/pushNotifications/sagas';
import { watchMobileImages } from 'ducks/mobileImages/sagas';
import { watchNews } from 'ducks/news/sagas';

export function* rootSaga() {
  yield all([
    call(watchAuth),
    call(watchCurrentUser),
    call(watchUsers),
    call(watchOrder),
    call(watchAccount),
    call(watchAccounts),
    call(watchStore),
    call(watchStores),
    call(watchExportContacts),
    call(watchPushNotifications),
    call(watchMobileImages),
    call(watchNews),
  ]);
}
