import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextField } from 'components/common/TextField';
import { PasswordField } from 'components/common/PasswordField';

export const LoginForm = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <TextField name="username" label={t('loginPage.username')} isErrorDisplay={false} />
      <PasswordField label={t('loginPage.password')} isErrorDisplay={false} />
    </Box>
  );
});
