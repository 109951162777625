import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  loginSchema,
  passwordChangeSchema,
  userSchema,
  balanceSchema,
  profileSchema,
  autoNotificationSchema,
  manualNotificationSchema,
  mobileImageSchema,
  scheduleSchema,
  productSearchSchema,
  textWithoutImageSchema,
} from 'schemas';

export const Form = React.memo(({ children, onSubmit, validationSchema }) => {
  const { handleSubmit, ...methods } = useForm({
    mode: 'onChange',
    resolver: validationSchema && yupResolver(validationSchema),
  });

  return (
    <FormProvider handleSubmit={handleSubmit} {...methods}>
      <Box
        mt={3}
        mb={3}
        component="form"
        display="flex"
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
      >
        {children}
      </Box>
    </FormProvider>
  );
});

Form.defaultProps = {
  validationSchema: null,
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.oneOf([
    loginSchema,
    passwordChangeSchema,
    userSchema,
    balanceSchema,
    profileSchema,
    autoNotificationSchema,
    manualNotificationSchema,
    mobileImageSchema,
    scheduleSchema,
    productSearchSchema,
    textWithoutImageSchema,
    null,
  ]),
};
