import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { authSlice, selectAuthStatus, selectIsAuthenticated } from 'ducks/auth';
import { currentUserSlice, selectIsForcedLogout } from 'ducks/currentUser';
import { AppComponent } from 'components/common/App';
import { NOTIFICATION_TYPES } from 'constants/notifications';
import { useNotification } from 'helpers/hooks';
import { selectRole } from 'ducks/currentUser/selectors';
import { ROUTE_MAP_WITH_ROLES, USER_ROLES } from 'constants/userRoles';

export const App = () => {
  const { t } = useTranslation();

  const authStatus = useSelector(selectAuthStatus);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isForcedLogout = useSelector(selectIsForcedLogout);
  const role = useSelector(selectRole);
  const route =
    ROUTE_MAP_WITH_ROLES[Object.keys(USER_ROLES).find((key) => USER_ROLES[key] === role)];

  const dispatch = useDispatch();

  const { showNotification, closeAllNotifications } = useNotification();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(currentUserSlice.actions.fetchCurrentUser());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (authStatus) {
      showNotification({
        notificationType:
          authStatus === 'success' ? NOTIFICATION_TYPES.success : NOTIFICATION_TYPES.error,
        title:
          authStatus === 'success' ? t('notifications.successAuth') : t('notifications.failedAuth'),
        durationInMilliseconds: 5000,
      });
    }
  }, [authStatus, showNotification, t]);

  useEffect(() => {
    if (isForcedLogout) {
      closeAllNotifications();
      dispatch(authSlice.actions.logout());
      showNotification({
        notificationType: NOTIFICATION_TYPES.error,
        title: t('notifications.failedAuth'),
        durationInMilliseconds: 5000,
      });
    }
  }, [closeAllNotifications, dispatch, isForcedLogout, showNotification, t]);

  return <AppComponent isAuthenticated={isAuthenticated} route={route} />;
};
