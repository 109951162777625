import React, { useCallback, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { UsersToolbarActions } from 'components/users/UsersToolbarActions/UsersToolbarActions';
import { UsersTable } from 'components/users/UsersTable/UsersTable';
import { UsersSearchField } from 'containers/users/UsersSearchField';
import { useModal, useQuery } from 'helpers/hooks';
import {
  usersSlice,
  selectPageCount,
  selectUserList,
  selectIsLoading,
  selectCurrentPage,
  selectStatus,
} from 'ducks/users';
import { MODAL_TYPES } from 'constants/modals';

export const AdminUsersPage = () => {
  const { t } = useTranslation();
  const page = useSelector(selectCurrentPage);

  const { query, onChangeQuery } = useQuery({
    initialQuery: { limit: 20, onlyAdmin: 1, page, scope: 'user_full' },
  });

  const userList = useSelector(selectUserList);
  const isLoading = useSelector(selectIsLoading);
  const pageCount = useSelector(selectPageCount);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const { showModal } = useModal();

  useEffect(() => {
    if (status) {
      showModal({
        modalType: MODAL_TYPES.acknowledgment,
        body: status,
        onSuccess: () => {
          dispatch(usersSlice.actions.clearStatus());
        },
      });
    }
  }, [status, showModal, dispatch, page]);

  useEffect(() => {
    dispatch(usersSlice.actions.getUsersList(query));
    dispatch(usersSlice.actions.clearUser());
  }, [dispatch, query]);

  useEffect(() => {
    return () => {
      dispatch(usersSlice.actions.setCurrentPage(1));
    };
  }, [dispatch]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (newPage) {
        onChangeQuery({ page: newPage });
      }
    },
    [onChangeQuery],
  );

  return (
    <Paper sx={{ padding: 2 }}>
      <UsersToolbarActions />
      <Typography variant="h6">{t('topBar.adminUsers')}</Typography>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <UsersSearchField onChangeQuery={onChangeQuery} />
      </Box>
      <UsersTable
        isLoading={isLoading}
        userList={userList}
        pageCount={pageCount}
        page={page}
        handleChangePage={handleChangePage}
      />
    </Paper>
  );
};
