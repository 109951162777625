import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseModalDialog } from 'components/common/BaseModalDialog';
import { BaseModalDialogTitle } from 'components/common/BaseModalDialog/BaseModalDialogTitle';
import { useModal } from 'helpers/hooks';
import { AddEditMobileImageComponent } from 'components/modals/AddEditMobileImageComponent';
import { selectCurrentNewsItem } from 'ducks/news';
import { TextField } from 'components/common/TextField';

export const AddNews = ({ id, title, onSuccess, onCancel, onClose }) => {
  const { t } = useTranslation();

  const { closeModal } = useModal(id);

  const defaultValues = useSelector(selectCurrentNewsItem);

  const handleClose = useCallback(() => {
    onClose();
    closeModal();
  }, [closeModal, onClose]);

  const handleSuccess = useCallback(
    (data) => {
      onSuccess(data);
      handleClose();
    },
    [handleClose, onSuccess],
  );

  const handleCancel = useCallback(() => {
    onCancel();
    handleClose();
  }, [handleClose, onCancel]);

  return (
    <BaseModalDialog handleClose={handleClose}>
      <BaseModalDialogTitle>{title}</BaseModalDialogTitle>
      <AddEditMobileImageComponent
        defaultValues={defaultValues}
        handleCancel={handleCancel}
        handleSuccess={handleSuccess}
      >
        <TextField isErrorDisplay name="title" label={t('news.title')} />
        <TextField isErrorDisplay={false} multiline name="text" label={t('news.text')} />
      </AddEditMobileImageComponent>
    </BaseModalDialog>
  );
};

AddNews.defaultProps = {
  title: '',
  onSuccess: () => {},
  onCancel: () => {},
  onClose: () => {},
};

AddNews.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};
