import React, { useCallback } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

export const ImageField = React.memo(({ name }) => {
  const { t } = useTranslation();

  const { register, watch, setValue, trigger } = useFormContext();

  const image = watch(name, '');

  const onDeleteClick = useCallback(() => {
    setValue(name, '');
    trigger();
  }, [name, setValue, trigger]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
      <Button htmlFor="fileUpload" variant="contained" component="label">
        {t('modals.mobileImage.fileUpload')}
        <input id="fileUpload" defaultValue="" type="file" hidden {...register(name)} />
      </Button>
      <Typography ml={1}>{image[0]?.name || t('modals.mobileImage.noFileUploaded')}</Typography>
      {image[0]?.name && (
        <IconButton sx={{ ml: 1 }} onClick={onDeleteClick}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
});

ImageField.propTypes = {
  name: PropTypes.string.isRequired,
};
