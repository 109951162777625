import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalsById: {},
  modalsIds: [],
};

export const modalsSlice = createSlice({
  name: '@@modals',
  initialState,
  reducers: {
    showModal: (state, { payload: { id, ...modal } }) => {
      if (!state.modalsIds.includes(id)) {
        state.modalsIds.push(id);
      }
      state.modalsById[id] = {
        id,
        ...modal,
      };
    },
    closeModal: (state, { payload: { id } }) => {
      state.modalsIds = state.modalsIds.filter((modalId) => modalId !== id);
      delete state.modalsById[id];
    },
    closeAllModals: (state) => {
      state.modalsIds = initialState.modalsIds;
      state.modalsById = initialState.modalsById;
    },
  },
});
