export const ROUTES = {
  main: '/',
  login: '/me/login',
  loginTemplate: '/me',
  accountsList: '/account-management/search',
  account: '/account-management/account/:accountId',
  accountTemplate: '/account-management/account',
  usersList: '/user/list',
  createUser: '/user/add',
  editUser: '/user/edit/:userId',
  editUserTemplate: '/user/edit',
  storesList: '/stores/index',
  storeMenu: '/stores/menu/:storeId',
  storeMenuTemplate: '/stores/menu',
  storeInformation: '/stores/view/:storeId',
  storeInformationTemplate: '/stores/view',
  storeCustom: '/stores/custom/:storeId',
  storeCustomTemplate: '/stores/custom',
  orders: '/orders/index',
  order: '/orders/view/:orderId',
  orderTemplate: '/orders/view',
  ordersTemplate: '/orders/index',
  ordersPendingTab: '/orders/index/waiting',
  ordersPreparedTab: '/orders/index/pickup',
  ordersDeliveredTab: '/orders/index/delivered',
  ordersCanceledTab: '/orders/index/canceled',
  ordersErrorTab: '/orders/index/failed',
  ordersSearchTab: '/orders/index/search',
  mobileTemplate: '/mobile',
  mobileImages: '/mobile/carousel',
  pushNotifications: '/mobile/notifications',
  accountsTemplate: '/account-management',
  usersTemplate: '/user',
  storesTemplate: '/stores',
  news: '/mobile/news',
  notificationsTemplate: '/mobile/notifications',
  notificationsAutomaticTab: '/mobile/notifications/auto',
  notificationsManualTab: '/mobile/notifications/manual',
  notificationsHistoryTab: '/mobile/notifications/history',
};
