import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Typography as MuiTypography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Typography = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.body.text.main,
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: theme.spacing(2),
  width: 220,
  float: 'left',
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const ProfileList = React.memo(
  ({ profile: { firstName, lastName, birthday, email, pendingEmail } }) => {
    const { t } = useTranslation();
    return (
      <List>
        {firstName && (
          <ListItem>
            <Typography>{t('accountInfo.firstName')}:</Typography>
            <MuiTypography variant="body2">{firstName}</MuiTypography>
          </ListItem>
        )}
        {lastName && (
          <ListItem>
            <Typography>{t('accountInfo.lastName')}:</Typography>
            <MuiTypography variant="body2">{lastName}</MuiTypography>
          </ListItem>
        )}
        <ListItem>
          <Typography>{t('accountInfo.email')}:</Typography>
          <MuiTypography variant="body2">{email}</MuiTypography>
        </ListItem>
        {pendingEmail && (
          <ListItem>
            <Typography>{t('accountInfo.pendingEmail')}:</Typography>
            <MuiTypography variant="body2">{pendingEmail}</MuiTypography>
          </ListItem>
        )}
        {birthday && (
          <ListItem>
            <Typography>{t('accountInfo.dateOfBirth')}:</Typography>
            <MuiTypography variant="body2">{birthday}</MuiTypography>
          </ListItem>
        )}
      </List>
    );
  },
);

ProfileList.propTypes = {
  profile: PropTypes.shape({
    pendingEmail: PropTypes.string,
    birthday: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.shape({
      id: PropTypes.number,
      shortTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
};
