import { createSelector } from '@reduxjs/toolkit';

export const selectNotificationsById = (state) => state.notifications.notificationsById;
export const selectNotificationsIds = (state) => state.notifications.notificationsIds;
export const selectNotificationById = (state, id) =>
  id && state.notifications.notificationsById[id];

export const selectNotifications = createSelector(
  selectNotificationsById,
  selectNotificationsIds,
  (notificationsById, notificationsIds) => notificationsIds.map((id) => notificationsById[id]),
);
