import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Search } from '@mui/icons-material';

import { SelectField } from 'components/common/SelectField';
import { STORE_MENU_SELECT_OPTIONS } from 'constants/selectOptions';
import { DatepickerField } from 'components/common/DatepickerField';
import { SearchTextField } from 'components/common/SearchTextField';
import { SubmitButtonWithOnlyValidData } from 'components/common/Form';

export const StoreForm = React.memo(({ onClose }) => {
  const {
    reset,
    formState: { isSubmitted, errors },
    trigger,
  } = useFormContext();

  const { t } = useTranslation();

  const selectOptions = useMemo(
    () => [
      {
        id: STORE_MENU_SELECT_OPTIONS.all,
        value: t('storePage.select.all'),
      },
      {
        id: STORE_MENU_SELECT_OPTIONS.active,
        value: t('storePage.select.active'),
      },
      {
        id: STORE_MENU_SELECT_OPTIONS.inactive,
        value: t('storePage.select.inactive'),
      },
      {
        id: STORE_MENU_SELECT_OPTIONS.temporaryOff,
        value: t('storePage.select.temporaryOff'),
      },
    ],
    [t],
  );

  useEffect(() => {
    reset({}, { keepValues: true });
  }, [reset]);

  useEffect(() => {
    if (isSubmitted) {
      onClose();
    }
  }, [isSubmitted, onClose]);

  return (
    <Box display="flex" flexDirection="column" p={1} minWidth={400}>
      <SearchTextField
        name="productName"
        label={t('storePage.search.name')}
        isErrorDisplay={false}
      />
      <SearchTextField
        name="systemId"
        label={t('storePage.search.systemId')}
        isErrorDisplay={false}
      />
      <SearchTextField
        name="category"
        label={t('storePage.search.category')}
        isErrorDisplay={false}
      />
      <SelectField
        name="deliveryStatus"
        label={t('storePage.search.status')}
        options={selectOptions}
        defaultValue={STORE_MENU_SELECT_OPTIONS.all}
      />
      <DatepickerField
        name="startDateIntervalFrom"
        label={t('storePage.search.startDateFrom')}
        customOnChange={trigger}
        error={errors.startDateIntervalFrom?.message}
      />
      <DatepickerField
        name="startDateIntervalTo"
        label={t('storePage.search.startDateTo')}
        customOnChange={trigger}
        error={errors.startDateIntervalTo?.message}
      />
      <DatepickerField
        name="endDateIntervalFrom"
        label={t('storePage.search.endDateFrom')}
        customOnChange={trigger}
        error={errors.endDateIntervalFrom?.message}
      />
      <DatepickerField
        name="endDateIntervalTo"
        label={t('storePage.search.endDateTo')}
        customOnChange={trigger}
        error={errors.endDateIntervalTo?.message}
      />
      <Box display="flex" justifyContent="flex-end">
        <SubmitButtonWithOnlyValidData>
          <Search />
        </SubmitButtonWithOnlyValidData>
      </Box>
    </Box>
  );
});

StoreForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};
