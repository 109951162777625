import React, { useCallback } from 'react';
import { Alert as MuiAlert, Fade, Snackbar as MuiSnackbar, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { NOTIFICATION_TYPES } from 'constants/notifications';
import { useNotification } from 'helpers/hooks';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const Snackbar = styled(MuiSnackbar)(() => ({
  marginTop: 48,
}));

const Alert = styled(MuiAlert)(({ theme, severity }) => ({
  minWidth: 400,
  minHeight: 40,
  backgroundColor: theme.palette.notification[severity],
}));

export const Notification = React.memo(
  ({ id, notificationType, title, durationInMilliseconds, onClose }) => {
    const { closeNotification } = useNotification(id);

    const handleClose = useCallback(() => {
      onClose();
      closeNotification();
    }, [closeNotification, onClose]);

    return (
      <Snackbar
        id={id}
        open
        autoHideDuration={durationInMilliseconds}
        anchorOrigin={anchorOrigin}
        TransitionComponent={Fade}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={notificationType} variant="filled">
          {title}
        </Alert>
      </Snackbar>
    );
  },
);

Notification.defaultProps = {
  title: '',
  durationInMilliseconds: null,
  onClose: () => {},
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  notificationType: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)).isRequired,
  title: PropTypes.string,
  durationInMilliseconds: PropTypes.number,
  onClose: PropTypes.func,
};
