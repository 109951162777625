import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCurrentUserId, selectRole } from 'ducks/currentUser';
import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { usersSlice, selectDeletedId } from 'ducks/users';
import { authSlice } from 'ducks/auth';
import { UsersActionsComponent } from 'components/users/UsersActions/UsersActionsComponent';

export const UsersActions = ({ row }) => {
  const { id } = row;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { showModal } = useModal();

  const role = useSelector(selectRole);
  const userId = useSelector(selectCurrentUserId);
  const deletedId = useSelector(selectDeletedId);

  useEffect(() => {
    if (deletedId && userId && deletedId === userId) {
      dispatch(authSlice.actions.logout());
    }
  }, [deletedId, dispatch, userId]);

  const handleDelete = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.deleteConfirmation,
      title: t('modals.deleteUserModal.header'),
      body: t('modals.deleteUserModal.body'),
      onSuccess: () => {
        dispatch(usersSlice.actions.deleteUser({ userId: id }));
      },
    });
  }, [showModal, dispatch, id, t]);

  return (
    <UsersActionsComponent
      userRole={role}
      currentUserId={userId}
      row={row}
      handleDelete={handleDelete}
    />
  );
};

UsersActions.propTypes = {
  row: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
  ).isRequired,
};
