import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { convertPrice } from 'helpers/converters';
import { DATE_FORMATS } from 'constants/dateFormats';
import { formatDate } from 'helpers/helpers';

import { Loader } from '../common/Loader';
import { TablePagination } from '../common/TablePagination';

import { OrderCard } from './OrderCard';

export const OrderCardsListComponent = React.memo(
  ({ cardList, isLoading, currentPage, maxPages, handleChangePage }) => {
    const { t } = useTranslation();

    return (
      <Box pl={2} pr={2}>
        {isLoading ? (
          <Loader />
        ) : (
          Boolean(cardList.length) &&
          cardList.map((current) => (
            <OrderCard
              key={current.id}
              id={current.id}
              total={convertPrice(current.total + Math.abs(current.loyaltyStatus.centsUsed))}
              payment={convertPrice(current.subTotal)}
              isPaymentByBalance={current.useBalance}
              paymentByBalance={convertPrice(Math.abs(current.loyaltyStatus.centsUsed))}
              currency={current.currency?.sign}
              client={current.user?.firstName}
              restaurant={current.store?.name}
              creationDate={formatDate(current.createdAt, DATE_FORMATS.orderDateFormat)}
              expectedDate={formatDate(
                current.expectedDeliveryDatetime,
                DATE_FORMATS.orderDateFormat,
              )}
              status={current.status}
            />
          ))
        )}
        {!isLoading && cardList.length === 0 && (
          <Typography
            variant="h5"
            component="h4"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 4,
            }}
          >
            {t('orders.ordersEmpty')}
          </Typography>
        )}
        <TablePagination
          page={currentPage}
          lastPage={maxPages}
          handleChangePage={handleChangePage}
        />
      </Box>
    );
  },
);

OrderCardsListComponent.propTypes = {
  cardList: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.number,
      subTotal: PropTypes.number,
      useBalance: PropTypes.bool,
      amountPayedByBalance: PropTypes.number,
      currency: PropTypes.shape({
        sign: PropTypes.string,
      }),
      user: PropTypes.shape({
        firstName: PropTypes.string,
      }),
      store: PropTypes.shape({
        name: PropTypes.string,
      }),
      createdAt: PropTypes.string,
      expectedDeliveryDatetime: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number,
  maxPages: PropTypes.number,
  handleChangePage: PropTypes.func,
};

OrderCardsListComponent.defaultProps = {
  isLoading: true,
  currentPage: 0,
  maxPages: 0,
  handleChangePage: () => {},
};
