import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Autorenew } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PUSH_NOTIFICATION_STATUSES } from 'constants/pushNotificationStatuses';

export const HistoryTableActionsComponent = React.memo(({ status, onEdit, onReuse }) => {
  const { t } = useTranslation();
  const isSent = status === PUSH_NOTIFICATION_STATUSES.sent;
  const isCancelled = status === PUSH_NOTIFICATION_STATUSES.canceled;

  return (
    <>
      {!isSent && !isCancelled && (
        <Tooltip title={t('pushNotificationsPage.tooltip.edit')} arrow placement="right">
          <IconButton size="small" onClick={onEdit}>
            <FontAwesomeIcon icon={faEdit} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('pushNotificationsPage.tooltip.reuse')} arrow placement="right">
        <IconButton size="small" onClick={onReuse}>
          <Autorenew color="info" />
        </IconButton>
      </Tooltip>
    </>
  );
});

HistoryTableActionsComponent.propTypes = {
  status: PropTypes.oneOf(Object.values(PUSH_NOTIFICATION_STATUSES)).isRequired,
  onEdit: PropTypes.func.isRequired,
  onReuse: PropTypes.func.isRequired,
};
