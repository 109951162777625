import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/routes';
import { usePermission } from 'helpers/hooks';

export const PrivateRoute = React.memo(
  ({ component: Component, isAuthenticated, redirectRoute, ...rest }) => {
    const { location } = rest;
    const { hasPermission } = usePermission();
    const locationTemplate = location.pathname.split('/').slice(0, -1).join('/');
    const permissionGranted = hasPermission(locationTemplate);

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated && permissionGranted ? (
            <Component {...props} />
          ) : (
            <Redirect to={redirectRoute} />
          )
        }
      />
    );
  },
);

PrivateRoute.defaultProps = {
  redirectRoute: ROUTES.login,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  redirectRoute: PropTypes.oneOf(Object.values(ROUTES)),
};
