import * as React from 'react';
import { PickersDay } from '@mui/lab';
import { styled, Typography } from '@mui/material';
import { isMonday, getISOWeek } from 'date-fns';
import PropTypes from 'prop-types';

const CustomPickersDay = styled(PickersDay)(({ theme }) => ({
  borderRadius: 0,
  border: 'none',
  backgroundColor: theme.palette.datepicker.main,
  color: theme.palette.datepicker.text,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.datepicker.hover,
  },
  '&.MuiPickersDay-today': {
    color: theme.palette.datepicker.today,
    border: 'none',
  },
  '&.MuiPickersDay-dayOutsideMonth': {
    color: theme.palette.datepicker.color,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.datepicker.selected,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.datepicker.selected,
    },
  },
  '&.weekCounter': {
    color: 'red',
    margin: '10px',
  },
}));

export const DatepickerDay = React.memo(({ date, ...props }) => {
  const weekNumber = getISOWeek(date);

  return (
    <>
      {isMonday(date) && (
        <Typography
          variant="caption"
          sx={{ margin: 1, fontStyle: 'italic', position: 'absolute', left: 0 }}
        >
          {weekNumber}
        </Typography>
      )}
      <CustomPickersDay key={date} today disableMargin disableHighlightToday {...props} />
    </>
  );
});

DatepickerDay.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};
