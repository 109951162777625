import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'helpers/hooks';
import { ConfirmationWithListComponent } from 'components/modals/ConfirmationWithListComponent';

export const ConfirmationWithList = ({ id, title, body, list, onSuccess, onCancel, onClose }) => {
  const { closeModal } = useModal(id);

  const handleCancel = useCallback(() => {
    onCancel();
    closeModal();
  }, [closeModal, onCancel]);

  const handleSuccess = useCallback(() => {
    onSuccess();
    closeModal();
  }, [closeModal, onSuccess]);

  const handleClose = useCallback(() => {
    onClose();
    closeModal();
  }, [closeModal, onClose]);

  return (
    <ConfirmationWithListComponent
      title={title}
      body={body}
      list={list}
      onClose={handleClose}
      onCancel={handleCancel}
      onSuccess={handleSuccess}
    />
  );
};

ConfirmationWithList.defaultProps = {
  title: '',
  body: '',
  onSuccess: () => {},
  onCancel: () => {},
  onClose: () => {},
};

ConfirmationWithList.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};
