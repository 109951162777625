import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationsById: {},
  notificationsIds: [],
};

export const notificationsSlice = createSlice({
  name: '@@notifications',
  initialState,
  reducers: {
    showNotification: (
      state,
      { payload: { id, notificationType, title, durationInMilliseconds, onClose } },
    ) => {
      if (!state.notificationsIds.includes(id)) {
        state.notificationsIds.push(id);
      }
      state.notificationsById[id] = {
        id,
        notificationType,
        title,
        durationInMilliseconds,
        onClose,
      };
    },
    closeNotification: (state, { payload: { id } }) => {
      state.notificationsIds = state.notificationsIds.filter(
        (notificationId) => notificationId !== id,
      );
      delete state.notificationsById[id];
    },
    closeAllNotifications: (state) => {
      state.notificationsIds = initialState.notificationsIds;
      state.notificationsById = initialState.notificationsById;
    },
  },
});
