import React from 'react';
import { Dialog as MuiDialog, styled } from '@mui/material';
import PropTypes from 'prop-types';

const Dialog = styled(MuiDialog)(() => ({
  '& .MuiDialog-paper': {
    width: 600,
  },
  '& .MuiDialog-scrollPaper': {
    alignItems: 'flex-start',
  },
}));

export const BaseModalDialog = React.memo(({ children, handleClose, ...props }) => (
  <Dialog open onClose={handleClose} {...props}>
    {children}
  </Dialog>
));

BaseModalDialog.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
};
