import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { upperFirst } from 'lodash';

import { StoreInformationComponent } from 'components/store/StoreInformationComponent';
import { storeSlice, selectStore, selectServicesList } from 'ducks/store';
import { Schedule } from 'components/store/Schedule';
import { Services } from 'components/store/Services';
import { WEEKDAYS } from 'constants/weekDays';

export const StoreInformation = React.memo(() => {
  const dispatch = useDispatch();
  const { store } = useSelector(selectStore);
  const {
    id: storeId,
    embedded: { services, schedules },
  } = store;
  const servicesList = useSelector(selectServicesList);

  useEffect(() => {
    dispatch(storeSlice.actions.getServicesList());
  }, [dispatch]);

  const handleToggleService = useCallback(
    ({ target: { id } }) => {
      dispatch(storeSlice.actions.toggleStoreService({ storeId, service: id }));
    },
    [storeId, dispatch],
  );

  const handleSubmit = useCallback(
    (data) => {
      const scheduleId = data.type;

      const scheduledData = Object.keys(data).filter(
        (key) => key !== 'type' && key.slice(0, key.length - 1) !== 'isClosed',
      );

      const openTime = scheduledData.map((key) => {
        return `${`0${new Date(data[key]).getHours()}`.slice(-2)}:${`0${String(
          new Date(data[key]).getMinutes(),
        )}`.slice(-2)}`;
      });

      const openDate = [];
      for (let i = openTime.length - 1, j = 1; i >= 0; i -= 2, j += 1) {
        if (!data[`isClosed${j - 1}`]) {
          openDate.push(`${upperFirst(WEEKDAYS[j])} - ${openTime[i]} - ${openTime[i - 1]}`);
        }
      }

      const scheduleLines = [];
      for (let i = 0; i < 7; i += 1) {
        scheduleLines.push({
          dayOfWeek: i + 1,
          closed: data[`isClosed${i}`],
          openClose: {
            open: `${`0${new Date(data[`open${i}`]).getHours()}`.slice(-2)}${`0${String(
              new Date(data[`open${i}`]).getMinutes(),
            )}`.slice(-2)}`,
            close: `${`0${new Date(data[`close${i}`]).getHours()}`.slice(-2)}${`0${String(
              new Date(data[`close${i}`]).getMinutes(),
            )}`.slice(-2)}`,
          },
        });
      }

      dispatch(
        storeSlice.actions.changeStoreSchedule({
          storeId,
          params: {
            scheduleId,
            schedule: openDate,
          },
          scheduleLines,
        }),
      );
    },
    [dispatch, storeId],
  );

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column">
        {Boolean(store.id) && <StoreInformationComponent storeData={store} />}
      </Box>
      <Box display="flex" flexDirection="column" m={2}>
        <Services
          services={services}
          servicesList={servicesList}
          onToggleService={handleToggleService}
        />
        <Schedule schedules={schedules} onSubmit={handleSubmit} />
      </Box>
    </Box>
  );
});
