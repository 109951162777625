import { call, put, takeLatest } from 'redux-saga/effects';

import { getPagesInfo, getOrderById } from 'ducks/orders/services';

import { orderSlice } from './reducer';

function* getOrderByIdSaga({ payload: id }) {
  try {
    const order = yield call(getOrderById, id);

    yield put(orderSlice.actions.getOrderByIdSuccess({ order }));
  } catch (error) {
    yield put(orderSlice.actions.getOrderByIdFailed(error?.response?.data));
  }
}

function* getPagesInfoSaga({ payload }) {
  try {
    const pagesInfo = yield call(getPagesInfo, payload);

    yield put(orderSlice.actions.getPagesInfoSuccess(pagesInfo));
  } catch (error) {
    yield put(orderSlice.actions.getPagesInfoFailed(error?.response?.data));
  }
}

export function* watchOrder() {
  yield takeLatest(orderSlice.actions.getOrderById, getOrderByIdSaga);
  yield takeLatest(orderSlice.actions.getPagesInfo, getPagesInfoSaga);
}
