import * as yup from 'yup';

export const passwordChangeSchema = yup.object().shape({
  oldPassword: yup.string().required(),
  password: yup
    .string()
    .min(8, 'modals.changePasswordModal.passwordPatternError')
    .matches(/^\S+$/, 'modals.changePasswordModal.passwordSpacesError')
    .matches(
      /^[a-zA-Z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/,
      'modals.changePasswordModal.passwordPatternError',
    )
    .matches(/[a-z]/, 'modals.changePasswordModal.passwordPatternError')
    .matches(/[A-Z]/, 'modals.changePasswordModal.passwordPatternError')
    .matches(/\d/, 'modals.changePasswordModal.passwordPatternError')
    .matches(
      /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
      'modals.changePasswordModal.passwordPatternError',
    )
    .required(''),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'modals.changePasswordModal.passwordIsNotMatch')
    .required(''),
});
