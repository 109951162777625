import { createSlice } from '@reduxjs/toolkit';

import { normalize } from 'ducks/mapper';

const initialState = {
  accountsById: {},
  accountsIds: [],

  error: {},
  pageCount: 1,
  currentPage: 1,
  isLoading: false,
};
export const accountsSlice = createSlice({
  name: '@@accounts',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload: page }) => {
      state.currentPage = page;
    },
    getAccountsList: (state) => {
      state.isLoading = true;
    },
    getAccountsSuccess: (state, { payload: { pages, page, items } }) => {
      const { byId, allIds } = normalize(items);
      state.accountsById = byId;
      state.accountsIds = allIds;
      state.pageCount = pages;
      state.currentPage = page;
      state.isLoading = false;
    },
    getAccountsError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.accountsById = {};
      state.accountsIds = [];
    },
  },
});
