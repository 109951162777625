import React from 'react';
import { Container, Paper, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Form, SubmitButtonWithOnlyValidData } from 'components/common/Form';
import { loginSchema } from 'schemas';
import { LoginForm } from 'components/login/LoginForm';

const SubmitButton = styled(SubmitButtonWithOnlyValidData)(({ theme }) => ({
  backgroundColor: theme.palette.button.success.main,
  '&:disabled': {
    opacity: 0.65,
    color: theme.palette.white,
    backgroundColor: theme.palette.button.success.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.button.success.hover,
  },
  marginBottom: 24,
}));

export const Login = React.memo(({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Container component={Paper} maxWidth="sm" sx={{ marginTop: 3 }}>
      <Typography pt={4} fontWeight={300} fontSize={30} textAlign="center" color="body.text.main">
        {t('loginPage.logo')}
      </Typography>
      <Typography mt={2} fontSize={24} textAlign="center" color="body.text.main">
        {t('loginPage.title')}
      </Typography>
      <Form onSubmit={onSubmit} validationSchema={loginSchema}>
        <LoginForm />
        <SubmitButton>{t('loginPage.submitButton')}</SubmitButton>
      </Form>
    </Container>
  );
});

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
