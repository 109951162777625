export const normalizeResponse = (response) => {
  if (Array.isArray(response)) {
    return response.reduce((acc, value) => {
      if (value instanceof Object) {
        acc.push(normalizeResponse(value));
      } else {
        acc.push(value);
      }

      return acc;
    }, []);
  }

  return Object.entries(response).reduce((acc, [key, value]) => {
    const modifiedKey = key.charAt(0) === '_' ? key.slice(1) : key;

    const newKey = modifiedKey
      .split('_')
      .map((str, index) => (index ? str.charAt(0).toUpperCase() + str.slice(1) : str))
      .join('');

    if (value instanceof Object) {
      acc[newKey] = normalizeResponse(value);
    } else {
      acc[newKey] = value;
    }

    return acc;
  }, {});
};

export const normalizeRequest = (request, ignoreList = []) => {
  if (!request) {
    return request;
  }

  if (Array.isArray(request)) {
    return request.reduce((acc, value) => {
      if (value instanceof Object) {
        acc.push(normalizeRequest(value, ignoreList));
      } else {
        acc.push(value);
      }

      return acc;
    }, []);
  }

  return Object.entries(request).reduce((acc, [key, value]) => {
    const newKey = ignoreList.includes(key)
      ? key
      : key
          .split('')
          .map((str) => (str === str.toUpperCase() ? `_${str.toLowerCase()}` : str))
          .join('');

    if (value instanceof Object) {
      acc[newKey] = normalizeRequest(value, ignoreList);
    } else {
      acc[newKey] = value;
    }

    return acc;
  }, {});
};
