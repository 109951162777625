import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { theme } from 'theme';
import 'i18n';
import { store, history } from 'ducks/root';
import { ModalManager } from 'components/common/ModalManager';
import { NotificationManager } from 'components/common/NotificationManager';
import { App } from 'containers/common/App';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ModalManager />
          <NotificationManager />
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </ThemeProvider>
      </React.StrictMode>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
