import { createSelector } from '@reduxjs/toolkit';

export const selectNotificationsById = (state) => state.pushNotifications.notificationsById;
export const selectNotificationsIds = (state) => state.pushNotifications.notificationsIds;

export const selectNotifications = createSelector(
  selectNotificationsById,
  selectNotificationsIds,
  (notificationsById, notificationsIds) => notificationsIds.map((id) => notificationsById[id]),
);

export const selectTriggerListById = (state) => state.pushNotifications.triggerListById;
export const selectTriggerListIds = (state) => state.pushNotifications.triggerListIds;

export const selectTriggerList = createSelector(
  selectTriggerListById,
  selectTriggerListIds,
  (triggerListById, triggerListIds) => triggerListIds.map((id) => triggerListById[id]),
);

export const selectNotificationsState = (state) => state.pushNotifications.notifications;
export const selectTriggersState = (state) => state.pushNotifications.triggers;

export const selectNotificationTemplate = (state) => state.pushNotifications.notificationTemplate;
export const selectTriggerTemplate = (state) => state.pushNotifications.triggerTemplate;

export const selectNotificationMeta = (state) => state.pushNotifications.meta;
