import { takeLatest, put, call } from 'redux-saga/effects';

import { exportContactsSlice } from 'ducks/exportContacts/reducer';
import { exportContacts } from 'ducks/exportContacts/services';

function* exportContactsSaga() {
  try {
    yield call(exportContacts);

    yield put(exportContactsSlice.actions.exportContactsSuccess());
  } catch (error) {
    yield put(exportContactsSlice.actions.exportContactsFailed(error?.response?.data));
  }
}

export function* watchExportContacts() {
  yield takeLatest(exportContactsSlice.actions.exportContacts, exportContactsSaga);
}
