import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectCurrentUsername } from 'ducks/currentUser';
import { NavBarGreetingComponent } from 'components/common/NavBar/NavBarGreetingComponent';

export const NavBarGreeting = ({ isOpen }) => {
  const username = useSelector(selectCurrentUsername);

  return <NavBarGreetingComponent isOpen={isOpen} username={username} />;
};

NavBarGreeting.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
