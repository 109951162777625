import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  styled,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BaseModalDialog } from 'components/common/BaseModalDialog';
import { BaseModalDialogTitle } from 'components/common/BaseModalDialog/BaseModalDialogTitle';

const PrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.button.warning.hover,
  },
}));

export const AcknowledgmentModalDialog = React.memo(
  ({ title, children, handleClose, handleSuccess }) => {
    const { t } = useTranslation();
    return (
      <BaseModalDialog handleClose={handleClose}>
        <BaseModalDialogTitle onClose={handleClose}>
          {t(`modals.acknowledgmentModal.${title}`, title)}
        </BaseModalDialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText whiteSpace="pre-line" color="body.text" fontSize={14}>
            {t(`modals.acknowledgmentModal.${children}`, children)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton variant="contained" onClick={handleSuccess} autoFocus>
            {t('common.ok')}
          </PrimaryButton>
        </DialogActions>
      </BaseModalDialog>
    );
  },
);

AcknowledgmentModalDialog.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};
