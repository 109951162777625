import * as yup from 'yup';

export const balanceSchema = yup.object().shape({
  amount: yup
    .string()
    .test('exp', 'accountInfo.error.exp', function checkExp(value) {
      return value.search(/e/) === -1;
    })
    .matches(/^-?[0-9]+$/, 'accountInfo.error.number')
    .required('accountInfo.error.mandatory')
    .test('max', 'accountInfo.error.max', function checkMax(value) {
      return Number(value) <= 500;
    }),
  complaint: yup.string().trim().required('accountInfo.error.mandatory'),
});
