import React from 'react';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

export const StoresAction = React.memo(({ onClick, isEnabled }) => (
  <IconButton size="small" onClick={onClick} color={isEnabled ? 'success' : 'error'}>
    <FontAwesomeIcon icon={faPowerOff} />
  </IconButton>
));

StoresAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};
