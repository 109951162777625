import React from 'react';
import { Box, DialogTitle as MuiDialogTitle, IconButton, styled } from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  paddingRight: 8,
  fontSize: 24,
  color: theme.palette.body.text.main,
}));

export const BaseModalDialogTitle = React.memo(({ children, onClose, ...props }) => (
  <DialogTitle {...props}>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {children}
      {onClose && (
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </Box>
  </DialogTitle>
));

BaseModalDialogTitle.defaultProps = {
  onClose: null,
};

BaseModalDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};
