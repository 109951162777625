import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

import { TextField } from 'components/common/TextField';

export const SearchTextField = React.memo(
  ({ name, label, isErrorDisplay, triggerAnotherFieldOnChange, ...props }) => {
    const { watch, setValue } = useFormContext();

    const isDirty = watch(name, '');

    const handleCancelClick = useCallback(() => {
      setValue(name, '');
    }, [name, setValue]);

    return (
      <TextField
        name={name}
        isErrorDisplay={isErrorDisplay}
        label={label}
        triggerAnotherFieldOnChange={triggerAnotherFieldOnChange}
        {...props}
        InputProps={{
          endAdornment: (
            <>
              {isDirty && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleCancelClick}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              )}
              {props.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  },
);

SearchTextField.defaultProps = {
  label: '',
  triggerAnotherFieldOnChange: () => {},
  InputProps: {},
};

SearchTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isErrorDisplay: PropTypes.bool.isRequired,
  triggerAnotherFieldOnChange: PropTypes.func,
  InputProps: PropTypes.shape({
    endAdornment: PropTypes.node,
  }),
};
