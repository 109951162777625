import { createSelector } from '@reduxjs/toolkit';

export const selectModalsById = (state) => state.modals.modalsById;
export const selectModalsIds = (state) => state.modals.modalsIds;
export const selectModalById = (state, id) => id && state.modals.modalsById[id];

export const selectModals = createSelector(
  selectModalsById,
  selectModalsIds,
  (modalsById, modalsIds) => modalsIds.map((id) => modalsById[id]),
);
