import React, { useCallback, useMemo } from 'react';
import { Link as MuiLink, styled, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { generatePath } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/common/Table';
import { TablePagination } from 'components/common/TablePagination';
import { ROUTES } from 'constants/routes';
import { StoresActionVem } from 'containers/stores/StoresActionVem';
import { StoresActionVae } from 'containers/stores/StoresActionVae';
import { Loader } from 'components/common/Loader';

const Link = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.link.main,
  '&:hover': {
    color: theme.palette.link.hover,
  },
}));

export const StoresTable = React.memo(
  ({ rows, page, lastPage, isLoading, query, onChangeQuery }) => {
    const { t } = useTranslation();

    const onChangePage = useCallback(
      (event, newPage) => {
        if (newPage) {
          onChangeQuery({ page: newPage });
        }
      },
      [onChangeQuery],
    );

    const columns = useMemo(
      () => [
        {
          name: t('table.id'),
          field: 'number',
          isSortable: true,
        },
        {
          name: t('table.name'),
          field: 'name',
          isSortable: true,
          cell: ({ row }) => (
            <Link
              component={RouterLink}
              to={generatePath(ROUTES.storeMenu, { storeId: row.id })}
              underline="hover"
            >
              {row.name}
            </Link>
          ),
        },
        {
          name: t('table.vem'),
          cell: StoresActionVem,
        },
        {
          name: t('table.vae'),
          cell: StoresActionVae,
        },
      ],
      [t],
    );

    return (
      <Loader isLoading={isLoading}>
        <Table
          rows={rows}
          columns={columns}
          query={query}
          onChangeQuery={onChangeQuery}
          isHeaderTransparent
        />
        {!rows.length && !isLoading && (
          <Typography textAlign="center" mt={2} variant="h5">
            {t('storesPage.dataNotFound')}
          </Typography>
        )}
        {lastPage !== 1 && (
          <TablePagination page={page} lastPage={lastPage} handleChangePage={onChangePage} />
        )}
      </Loader>
    );
  },
);

StoresTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.bool.isRequired,
        PropTypes.object.isRequired,
        PropTypes.array.isRequired,
      ]),
    ).isRequired,
  ).isRequired,
  query: PropTypes.shape({
    sort: PropTypes.shape({
      order: PropTypes.string.isRequired,
      orderBy: PropTypes.string,
    }).isRequired,
  }).isRequired,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChangeQuery: PropTypes.func.isRequired,
};
