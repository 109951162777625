import { call, put, takeLatest } from 'redux-saga/effects';

import { getStoresList, toggleVaeState, toggleVemState } from 'ducks/stores/services';
import { storesSlice } from 'ducks/stores';
import { normalize } from 'ducks/mapper';
import { SORT_ORDERS } from 'constants/sortOrders';

function* getStoresListSaga({
  payload: { page, sort, search, limit = '10', scope = 'store_full' },
}) {
  try {
    const { order, orderBy } = sort;
    const { name, sysId } = search;

    const {
      embedded: { items },
      page: currentPage,
      pages: lastPage,
    } = yield call(getStoresList, {
      page,
      limit,
      scope,
      name,
      sysId,
      order: order === SORT_ORDERS.asc || order === SORT_ORDERS.desc ? order.toUpperCase() : null,
      orderBy,
    });

    yield put(
      storesSlice.actions.getStoresListSuccess({ items: normalize(items), currentPage, lastPage }),
    );
  } catch (error) {
    yield put(storesSlice.actions.getStoresListFailed(error?.response?.data));
  }
}

function* toggleVemStateSaga({ payload: id }) {
  try {
    const { isDineinEnabled } = yield call(toggleVemState, id);

    yield put(storesSlice.actions.toggleVemStateSuccess({ id, isDineinEnabled }));
  } catch (error) {
    yield put(storesSlice.actions.toggleVemStateFailed(error?.response?.data));
  }
}

function* toggleVaeStateSaga({ payload: id }) {
  try {
    const { isTakeawayEnabled } = yield call(toggleVaeState, id);

    yield put(storesSlice.actions.toggleVaeStateSuccess({ id, isTakeawayEnabled }));
  } catch (error) {
    yield put(storesSlice.actions.toggleVaeStateFailed(error?.response?.data));
  }
}

export function* watchStores() {
  yield takeLatest(storesSlice.actions.getStoresList, getStoresListSaga);
  yield takeLatest(storesSlice.actions.toggleVemState, toggleVemStateSaga);
  yield takeLatest(storesSlice.actions.toggleVaeState, toggleVaeStateSaga);
}
