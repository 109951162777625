import React from 'react';
import { Box } from '@mui/material';

import { CreateUserButton } from './CreateUserButton';

export const UsersToolbarActions = React.memo(() => {
  return (
    <Box display="flex" sx={{ float: 'right' }}>
      <CreateUserButton />
    </Box>
  );
});
