import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  accountsSlice,
  selectAccountsList,
  selectCurrentPage,
  selectIsLoading,
  selectPageCount,
} from 'ducks/accounts';
import { AccountsTable } from 'components/accounts/AccountsTable/AccountsTable';
import { AccountsSearchFieldComponent } from 'components/accounts/AccountsSearchField';
import { AccountTitle } from 'components/accounts/AccountsTitle/AccountTitle';
import { useQuery, useNotification } from 'helpers/hooks';
import { SORT_FIELD, SORT_ORDERS } from 'constants/loyaltyUsersSorts';
import { accountSlice, selectIsDeactivated } from 'ducks/account';
import { NOTIFICATION_TYPES } from 'constants/notifications';

export const LoyaltyUsersPage = () => {
  const [title, setTitle] = useState();
  const dispatch = useDispatch();
  const page = useSelector(selectCurrentPage);
  const accountsList = useSelector(selectAccountsList);
  const isLoading = useSelector(selectIsLoading);
  const pageCount = useSelector(selectPageCount);

  const { query, onChangeQuery } = useQuery({
    initialQuery: {
      page,
      limit: 20,
      sort: {
        order: SORT_ORDERS.asc,
        orderBy: SORT_FIELD.lastName,
      },
    },
  });
  const isDeactivated = useSelector(selectIsDeactivated);

  const { t } = useTranslation();

  const { showNotification } = useNotification();

  useEffect(() => {
    dispatch(accountsSlice.actions.getAccountsList(query));
  }, [dispatch, query]);

  useEffect(() => {
    if (isDeactivated) {
      showNotification({
        notificationType: NOTIFICATION_TYPES.success,
        title: t('notifications.deactivated'),
        durationInMilliseconds: 5000,
      });
    }
  }, [isDeactivated, showNotification, t]);

  useEffect(() => {
    return () => {
      dispatch(accountsSlice.actions.setCurrentPage(1));
      dispatch(accountSlice.actions.resetIsDeactivated());
    };
  }, [dispatch]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (newPage) {
        onChangeQuery({ page: newPage });
      }
    },
    [onChangeQuery],
  );

  const handleSubmit = useCallback(
    (data) => {
      const sendData = pickBy(data, (value) => value !== null && value !== '');
      onChangeQuery({
        ...data,
        page: 1,
      });
      setTitle(sendData);
    },
    [setTitle, onChangeQuery],
  );

  return (
    <>
      <Paper sx={{ padding: 2 }}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <AccountsSearchFieldComponent onSubmit={handleSubmit} />
        </Box>
        <AccountTitle title={title} />
        <AccountsTable
          isLoading={isLoading}
          accountsList={accountsList}
          page={page}
          pageCount={pageCount}
          query={query}
          onChangeQuery={onChangeQuery}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </>
  );
};
