import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link as MuiLink, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/common/Table';
import { TablePagination } from 'components/common/TablePagination';
import { ROUTES } from 'constants/routes';
import { Loader } from 'components/common/Loader/Loader';
import { UsersActions } from 'containers/users/UserActions';

const Link = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.link.main,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.link.hover,
    textDecoration: 'underline',
  },
}));

export const UsersTable = React.memo(
  ({ isLoading, userList, pageCount, page, handleChangePage }) => {
    const { t } = useTranslation();
    const columns = useMemo(
      () => [
        {
          name: t('accountInfo.id'),
          field: 'id',
          cell: ({ row }) => (
            <Link component={RouterLink} to={generatePath(ROUTES.editUser, { userId: row.id })}>
              {row.id}
            </Link>
          ),
        },
        {
          name: t('accountInfo.username'),
          field: 'username',
        },
        {
          name: t('accountInfo.email'),
          field: 'email',
        },
        {
          name: t('accountInfo.roles'),
          field: 'roles',
          cell: ({ row }) => t(`accountInfo.role.${row.roles[0]}`),
        },
        {
          name: t('accountInfo.actions'),
          cell: UsersActions,
        },
      ],
      [t],
    );

    return (
      <Loader isLoading={isLoading}>
        <Table columns={columns} rows={userList} />
        <TablePagination page={page} lastPage={pageCount} handleChangePage={handleChangePage} />
      </Loader>
    );
  },
);

UsersTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      username: PropTypes.string,
    }),
  ),
  pageCount: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func.isRequired,
};
UsersTable.defaultProps = {
  userList: [],
  pageCount: 1,
  page: 1,
};
