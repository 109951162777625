import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { storesSlice } from 'ducks/stores';
import { StoresAction } from 'components/stores/StoresAction';

export const StoresActionVae = ({ row }) => {
  const { id, isTakeawayEnabled } = row;

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(storesSlice.actions.toggleVaeState(id));
  }, [dispatch, id]);

  return <StoresAction onClick={handleClick} isEnabled={isTakeawayEnabled} />;
};

StoresActionVae.propTypes = {
  row: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
  ).isRequired,
};
