import React, { useCallback } from 'react';
import { Box, styled, TableSortLabel, Typography, TableCell as MuiTableCell } from '@mui/material';
import PropTypes from 'prop-types';

import { SORT_ORDERS } from 'constants/sortOrders';

const TableCell = styled(MuiTableCell, { shouldForwardProp: (prop) => prop !== 'isTransparent' })(
  ({ theme, isTransparent }) => ({
    backgroundColor: isTransparent ? 'transparent' : theme.palette.table.header,
    color: theme.palette.body.text.main,
    borderColor: theme.palette.table.border,
  }),
);

const nextOrderDirectionByName = (name, isSortDouble) => ({
  [SORT_ORDERS.asc]: { nextOrderDirection: SORT_ORDERS.desc, orderedByName: name },
  [SORT_ORDERS.desc]: isSortDouble
    ? { nextOrderDirection: SORT_ORDERS.asc, orderedByName: name }
    : { nextOrderDirection: SORT_ORDERS.none, orderedByName: null },
  [SORT_ORDERS.none]: { nextOrderDirection: SORT_ORDERS.asc, orderedByName: name },
});

export const TableHeaderCell = React.memo(
  ({
    name,
    field,
    isSortable,
    orderDirection,
    orderedBy,
    onChangeQuery,
    isTransparent,
    isSortDouble,
  }) => {
    const isActive = orderedBy === field;
    const direction =
      orderDirection === SORT_ORDERS.none || !isActive ? SORT_ORDERS.asc : orderDirection;

    const handleSortClick = useCallback(() => {
      const orderDirectionBasedOnActiveState = isActive ? orderDirection : SORT_ORDERS.none;

      const { nextOrderDirection, orderedByName } = nextOrderDirectionByName(field, isSortDouble)[
        orderDirectionBasedOnActiveState
      ];

      onChangeQuery({ sort: { order: nextOrderDirection, orderBy: orderedByName } });
    }, [isActive, field, onChangeQuery, orderDirection, isSortDouble]);

    return (
      <TableCell isTransparent={isTransparent}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {isSortable ? (
            <TableSortLabel direction={direction} active={isActive} onClick={handleSortClick}>
              <Typography
                ml="26px"
                fontSize={14}
                color="body.text.main"
                fontWeight="bold"
                textAlign="center"
              >
                {name}
              </Typography>
            </TableSortLabel>
          ) : (
            <Typography fontSize={14} fontWeight="bold">
              {name}
            </Typography>
          )}
        </Box>
      </TableCell>
    );
  },
);

TableHeaderCell.defaultProps = {
  isSortable: false,
  isSortDouble: false,
  orderedBy: '',
};

TableHeaderCell.propTypes = {
  name: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  isSortable: PropTypes.bool,
  isSortDouble: PropTypes.bool,
  orderDirection: PropTypes.oneOf(Object.values(SORT_ORDERS)).isRequired,
  orderedBy: PropTypes.string,
  onChangeQuery: PropTypes.func.isRequired,
  isTransparent: PropTypes.bool.isRequired,
};
