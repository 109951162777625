import request from 'helpers/request';

export const getStoresList = ({ limit, page, scope, name, sysId, order, orderBy }) =>
  request.get('/api/stores/search', {
    params: { limit, page, scope, name, sysId, order, orderBy },
    ignoreList: ['orderBy'],
  });

export const toggleVemState = (id) => request.put(`/api/stores/${id}/restaurant`);

export const toggleVaeState = (id) => request.put(`/api/stores/${id}/clickandcollect`);
