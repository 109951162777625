import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isBoolean } from 'lodash';

import { Form } from 'components/common/Form';
import {
  pushNotificationsSlice,
  selectTriggerList,
  selectTriggersState,
  selectTriggerTemplate,
} from 'ducks/pushNotifications';
import { AutoNotificationForm } from 'components/pushNotifications/AutoNotificationForm';
import { autoNotificationSchema } from 'schemas';
import { TRIGGER_LIST_FOR_NOTIFICATIONS } from 'constants/triggerListForNotifications';
import { STATUSES } from 'constants/statuses';
import { useNotification } from 'helpers/hooks';

export const CreateAutoNotification = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { id, ...triggerTemplate } = useSelector(selectTriggerTemplate);

  const { isSent, isEdited } = useSelector(selectTriggersState);

  const triggerList = useSelector(selectTriggerList);

  const { showNotification } = useNotification();

  const onSubmit = useCallback(
    (data) => {
      if (id) {
        dispatch(pushNotificationsSlice.actions.setTriggerTemplate({ ...data, id }));
        dispatch(pushNotificationsSlice.actions.editAutoNotification({ ...data, id }));
        return;
      }
      dispatch(pushNotificationsSlice.actions.sendAutoNotification(data));
    },
    [dispatch, id],
  );

  const triggers = useMemo(
    () =>
      triggerList.map(({ trigger }) => trigger).filter((key) => key !== triggerTemplate.trigger),
    [triggerList, triggerTemplate.trigger],
  );

  const selectOptions = useMemo(
    () =>
      Object.keys(TRIGGER_LIST_FOR_NOTIFICATIONS)
        .filter((key) => !triggers.includes(key))
        .map((key) => ({
          id: key,
          value: t(`pushNotificationsPage.triggersList.${key}`),
        })),
    [t, triggers],
  );

  useEffect(() => {
    if (isBoolean(isSent)) {
      showNotification({
        notificationType: isSent ? STATUSES.success : STATUSES.error,
        title: isSent
          ? t('notifications.pushNotificationCreated')
          : t('notifications.pushNotificationNotCreated'),
      });
    }
  }, [isSent, showNotification, t]);

  useEffect(() => {
    if (isBoolean(isEdited)) {
      showNotification({
        notificationType: isEdited ? STATUSES.success : STATUSES.error,
        title: isEdited
          ? t('notifications.pushNotificationEdited')
          : t('notifications.pushNotificationNotEdited'),
      });
    }
  }, [isEdited, showNotification, t]);

  useEffect(() => {
    return () => {
      dispatch(pushNotificationsSlice.actions.resetTriggerTemplate());
    };
  }, [dispatch]);

  return (
    <Form onSubmit={onSubmit} validationSchema={autoNotificationSchema}>
      <AutoNotificationForm selectOptions={selectOptions} defaultValues={triggerTemplate} />
    </Form>
  );
};
