import * as React from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/lab';
import frLocale from 'date-fns/locale/fr';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'helpers/hooks';
import { DatepickerDay } from 'components/common/DatepickerField/DatepickerDay';
import { DatepickerToolbar } from 'components/common/DatepickerField/DatepickerToolbar';

const renderDatePickerDay = (date, selectedDates, pickersDayProps) => (
  <DatepickerDay date={date} {...pickersDayProps} />
);

export const DatepickerField = React.memo(({ name, label, error, customOnChange }) => {
  const { t } = useTranslation();

  const { control, setValue, watch } = useFormContext();

  const [open, toggleOpen] = useToggle(false);

  const handleClear = React.useCallback(() => {
    setValue(name, null, { shouldValidate: true });
    toggleOpen();
  }, [name, setValue, toggleOpen]);

  const handleToday = React.useCallback(() => {
    setValue(name, new Date(), { shouldValidate: true });
    toggleOpen();
  }, [name, setValue, toggleOpen]);

  const date = watch(name);

  const isDateValid = isValid(date) || date === '' || !date;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, ...field } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <DesktopDatePicker
            className="datePicker"
            label={label}
            open={open}
            onChange={(data) => {
              onChange(data);
              customOnChange();
            }}
            onOpen={toggleOpen}
            onClose={toggleOpen}
            showToolbar
            showDaysOutsideCurrentMonth
            renderDay={renderDatePickerDay}
            ToolbarComponent={() => (
              <DatepickerToolbar
                handleClear={handleClear}
                handleClose={toggleOpen}
                handleToday={handleToday}
              />
            )}
            renderInput={(params) => {
              return (
                <Box mb={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    name={label}
                    {...params}
                    error={!isDateValid || Boolean(error)}
                    helperText={
                      (!isDateValid && t('date.invalidDate')) || (Boolean(error) && t(error))
                    }
                  />
                </Box>
              );
            }}
            {...field}
          />
        </LocalizationProvider>
      )}
    />
  );
});

DatepickerField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  customOnChange: PropTypes.func,
};
DatepickerField.defaultProps = {
  label: 'datepicker',
  error: '',
  customOnChange: () => {},
};
