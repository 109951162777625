import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { TextField } from 'components/common/TextField';
import { ROUTES } from 'constants/routes';
import { SubmitButtonWithOnlyValidData } from 'components/common/Form';

const textLabelByRoute = {
  [ROUTES.news]: 'news.text',
  [ROUTES.mobileImages]: 'mobileImages.hyperlink',
};

export const ImageWithTextField = React.memo(({ title, isTitleVisible, text }) => {
  const { t } = useTranslation();

  const { watch } = useFormContext();

  const location = useLocation();

  const currentTitle = watch('title', title);
  const currentText = watch('text', text);
  const currentHyperlink = watch('hyperlink', text);

  return (
    <Box mt={2} mb={2}>
      {isTitleVisible ? (
        <TextField
          sx={{ maxWidth: '50%' }}
          isErrorDisplay
          name="title"
          label={t('news.title')}
          defaultValue={title}
        />
      ) : (
        <Box minHeight="56px" />
      )}
      {location.pathname === ROUTES.mobileImages ? (
        <TextField
          isErrorDisplay
          multiline
          name="hyperlink"
          label={t(textLabelByRoute[location.pathname]) || t('storeContent.text')}
          defaultValue={text}
        />
      ) : (
        <TextField
          isErrorDisplay
          multiline
          name="text"
          label={t(textLabelByRoute[location.pathname]) || t('storeContent.text')}
          defaultValue={text}
        />
      )}
      {(currentText || currentHyperlink) !== text || (isTitleVisible && currentTitle !== title) ? (
        <Box display="flex" justifyContent="flex-end">
          <SubmitButtonWithOnlyValidData>
            {t('mobileImages.submitButton')}
          </SubmitButtonWithOnlyValidData>
        </Box>
      ) : (
        <Box minHeight="36px" />
      )}
    </Box>
  );
});

ImageWithTextField.propTypes = {
  title: PropTypes.string.isRequired,
  isTitleVisible: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
