import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { HistoryTableActionsComponent } from 'components/pushNotifications/ManualHistory/HistoryTableActionsComponent';
import { pushNotificationsSlice } from 'ducks/pushNotifications';
import { ROUTES } from 'constants/routes';
import { PUSH_NOTIFICATION_STATUSES } from 'constants/pushNotificationStatuses';
import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';

export const HistoryTableActions = ({ row }) => {
  const { id, status } = row;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const { showModal } = useModal();

  const handleEdit = useCallback(() => {
    dispatch(pushNotificationsSlice.actions.setNotificationTemplate(row));

    history.push(ROUTES.notificationsManualTab);
  }, [dispatch, history, row]);

  const handleDelete = useCallback(() => {
    dispatch(pushNotificationsSlice.actions.deleteManualNotification({ id }));
  }, [dispatch, id]);

  const handleReuse = useCallback(() => {
    dispatch(
      pushNotificationsSlice.actions.setNotificationTemplate({ ...row, id: 0, schedule: '' }),
    );

    history.push(ROUTES.notificationsManualTab);
  }, [dispatch, history, row]);

  const showConfirmModal = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.deleteConfirmation,
      title: t('modals.deleteNotificationModal.header'),
      body: t('modals.deleteNotificationModal.body'),
      onSuccess: handleDelete,
    });
  }, [handleDelete, showModal, t]);

  return (
    <HistoryTableActionsComponent
      status={status}
      onDelete={showConfirmModal}
      onReuse={handleReuse}
      onEdit={handleEdit}
    />
  );
};

HistoryTableActions.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.oneOf(Object.values(PUSH_NOTIFICATION_STATUSES)).isRequired,
  }).isRequired,
};
