import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { Circle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { BaseModalDialog } from 'components/common/BaseModalDialog';
import { BaseModalDialogTitle } from 'components/common/BaseModalDialog/BaseModalDialogTitle';

const PrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.button.primary.hover,
  },
}));

const WarningButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.button.warning.hover,
  },
}));

export const ConfirmationWithListComponent = React.memo(
  ({ title, body, list, onClose, onCancel, onSuccess }) => {
    const { t } = useTranslation();

    return (
      <BaseModalDialog handleClose={onClose} disableScrollLock>
        <BaseModalDialogTitle onClose={onClose}>{title}</BaseModalDialogTitle>
        <Divider color="divider" />
        <DialogContent>
          <DialogContentText color="body.text" fontSize={14}>
            {body}
          </DialogContentText>
          <List sx={{ marginLeft: 3 }}>
            {list.map((item) => (
              <ListItem key={item} disablePadding>
                <ListItemIcon sx={{ minWidth: 19 }}>
                  <Circle sx={{ color: 'black', width: 6, height: '100%' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontWeight: 'bold', fontSize: 14 }}
                  primary={item}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <Divider color="divider" />
        <DialogActions>
          <WarningButton variant="contained" onClick={onCancel}>
            {t('common.cancel')}
          </WarningButton>
          <PrimaryButton variant="contained" onClick={onSuccess} autoFocus>
            {t('common.confirm')}
          </PrimaryButton>
        </DialogActions>
      </BaseModalDialog>
    );
  },
);

ConfirmationWithListComponent.defaultProps = {
  title: '',
  body: '',
};

ConfirmationWithListComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
