import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'components/common/Table';
import { Loader } from 'components/common/Loader';
import { TablePagination } from 'components/common/TablePagination';

export const HistoryTable = React.memo(
  ({ isLoading, rows, columns, page, lastPage, onChangePage }) => (
    <Loader isLoading={isLoading}>
      <Table rows={rows} columns={columns} isHeaderTransparent />
      {lastPage !== 1 && (
        <TablePagination page={page} lastPage={lastPage} handleChangePage={onChangePage} />
      )}
    </Loader>
  ),
);

HistoryTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.bool.isRequired,
        PropTypes.object.isRequired,
        PropTypes.array.isRequired,
      ]),
    ).isRequired,
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      field: PropTypes.string,
      cell: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }).isRequired,
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};
