import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { UsersSearchFieldComponent } from 'components/users/UsersSearchField';

export const UsersSearchField = ({ onChangeQuery }) => {
  const onSubmit = useCallback(
    (data) => {
      onChangeQuery({ ...data, page: 1 });
    },
    [onChangeQuery],
  );

  return <UsersSearchFieldComponent onSubmit={onSubmit} />;
};
UsersSearchField.propTypes = {
  onChangeQuery: PropTypes.func.isRequired,
};
