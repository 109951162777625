import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ORDER_STATE } from 'constants/orders';
import { convertPrice } from 'helpers/converters';
import { OrdersDetailedMainInformation } from 'components/orders/OrdersDetailed/OrdersDetailedMainInformation';
import { OrdersDetailedCustomerInformation } from 'components/orders/OrdersDetailed/OrdersDetailedCustomerInformation';
import { OrdersDetailedCartInformation } from 'components/orders/OrdersDetailed/OrdersDetailedCartInformation';
import { OrdersDetailedAddInformation } from 'components/orders/OrdersDetailed/OrdersDetailedAddInformation';
import { DATE_FORMATS } from 'constants/dateFormats';
import { formatDate } from 'helpers/helpers';

export const OrdersDetailedInformation = React.memo(({ orderData }) => {
  const { t } = useTranslation();

  const currencySign = orderData.currency?.sign ?? '';

  const pricePayboxPart = convertPrice(orderData.total);
  const pricePaymentPart = convertPrice(Math.abs(orderData.loyaltyStatus?.centsUsed));
  const priceTotal = convertPrice(orderData.total + Math.abs(orderData.loyaltyStatus?.centsUsed));

  const postcode = orderData.user?.embedded?.profile?.postcode;

  const cartRows =
    orderData.basket && orderData.basket.length !== 0
      ? orderData.basket.map((item, index) => ({
          id: `${item.name}--${index}`, // There are no unique fields
          amount: 1,
          product: item.name,
          unitPrice: `${convertPrice(item.price)} ${currencySign}`,
          total: `${convertPrice(1 * item.price)} ${currencySign}`,
        }))
      : [];

  const cartColumns = [
    {
      name: t('orders.cartInformation.amount'),
      field: 'amount',
    },
    {
      name: t('orders.cartInformation.product'),
      field: 'product',
    },
    {
      name: t('orders.cartInformation.unitPrice'),
      field: 'unitPrice',
    },
    {
      name: t('orders.cartInformation.total'),
      field: 'total',
    },
  ];

  const additionalInformation =
    orderData.events &&
    Object.values(orderData.events).map(({ date, status }) => [
      formatDate(date, DATE_FORMATS.orderDateFormat),
      status,
    ]);

  const additionalRows = additionalInformation
    ? additionalInformation.map(([date, status]) => ({
        id: status,
        date,
        status: status && t(`orders.additionalInformation.status.${status}`),
      }))
    : [];

  const expectedDeliveryDatetime =
    orderData.expectedDeliveryDatetime &&
    formatDate(orderData.expectedDeliveryDatetime ?? 0, DATE_FORMATS.orderDateFormat);
  const createdAt = formatDate(orderData.createdAt ?? 0, DATE_FORMATS.orderDateFormat);

  const additionalColumns = [
    {
      name: t('orders.additionalInformation.date'),
      field: 'date',
    },
    {
      name: t('orders.additionalInformation.status.status'),
      field: 'status',
    },
  ];

  return (
    <>
      <OrdersDetailedMainInformation
        id={orderData.id}
        storeName={orderData.store?.name}
        isAwaiting={orderData.status === ORDER_STATE.awating}
        type={orderData.type}
        createdAt={createdAt}
        expectedDeliveryDatetime={expectedDeliveryDatetime}
      />
      <Divider />
      <Grid
        container
        sx={{
          padding: '32px 16px',
          justifyContent: 'space-between',
        }}
      >
        <OrdersDetailedCustomerInformation
          cardNumber={orderData.card?.number}
          firstName={orderData.user?.firstName}
          mobileNumber={orderData.user?.embedded?.profile?.mobileNumber}
          postcode={postcode}
          email={orderData.user?.email}
          willUsePaymentBalance={orderData.useBalance}
          pricePayboxPart={pricePayboxPart}
          pricePaymentPart={pricePaymentPart}
          currencySign={currencySign}
        />
        <OrdersDetailedCartInformation
          cartRows={cartRows}
          cartColumns={cartColumns}
          priceTotal={priceTotal}
          currencySign={currencySign}
          isEventCanceled={!!orderData.events?.canceled}
        />
      </Grid>
      <Divider />
      <OrdersDetailedAddInformation
        commcenterGuid={orderData.commcenterGuid}
        additionalRows={additionalRows}
        additionalColumns={additionalColumns}
      />
    </>
  );
});

OrdersDetailedInformation.propTypes = {
  orderData: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    status: PropTypes.string,
    commcenterGuid: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
      embedded: PropTypes.shape({
        profile: PropTypes.shape({
          mobileNumber: PropTypes.string,
          postcode: PropTypes.string,
        }),
      }),
    }),
    store: PropTypes.shape({
      name: PropTypes.string,
    }),
    basket: PropTypes.arrayOf(
      PropTypes.shape({
        mealOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            price: PropTypes.number,
            systemId: PropTypes.number,
          }),
        ),
        name: PropTypes.string,
        price: PropTypes.number,
        systemId: PropTypes.number,
      }),
    ),
    card: PropTypes.shape({
      number: PropTypes.string,
    }),
    currency: PropTypes.shape({
      sign: PropTypes.string,
    }),
    events: PropTypes.shape({
      accepted: PropTypes.shape({
        date: PropTypes.string,
        status: PropTypes.string,
      }),
      pending: PropTypes.shape({
        date: PropTypes.string,
        status: PropTypes.string,
      }),
      pickup: PropTypes.shape({
        date: PropTypes.string,
        status: PropTypes.string,
      }),
      delivered: PropTypes.shape({
        date: PropTypes.string,
        status: PropTypes.string,
      }),
      canceled: PropTypes.shape({
        date: PropTypes.string,
        status: PropTypes.string,
      }),
    }),
    loyaltyStatus: PropTypes.shape({
      centsUsed: PropTypes.number,
    }),
    createdAt: PropTypes.string,
    expectedDeliveryDatetime: PropTypes.string,
    total: PropTypes.number,
    amountPayedByBalance: PropTypes.number,
    useBalance: PropTypes.bool,
  }),
};

OrdersDetailedInformation.defaultProps = {
  orderData: {
    id: 0,
    type: '',
    status: '',
    commcenterGuid: '',
    user: {
      firstName: '',
      email: '',
      embedded: {
        profile: {
          mobileNumber: '',
        },
      },
    },
    store: {
      name: '',
    },
    basket: [],
    card: {},
    currency: {
      sign: '',
    },
    createdAt: '',
    expectedDeliveryDatetime: '',
    total: 0,
    amountPayedByBalance: 0,
    useBalance: false,
  },
};
