import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { DELIVERY_STATUSES } from 'constants/deliveryStatuses';

const colorByDeliveryStatus = {
  [DELIVERY_STATUSES.on]: 'success',
  [DELIVERY_STATUSES.off]: 'error',
  [DELIVERY_STATUSES.temporaryOff]: 'primary',
};

const tooltipTextByDeliveryStatus = {
  [DELIVERY_STATUSES.on]: 'storePage.tooltip.on',
  [DELIVERY_STATUSES.off]: 'storePage.tooltip.off',
  [DELIVERY_STATUSES.temporaryOff]: 'storePage.tooltip.tempOff',
};

export const StoreAction = React.memo(({ onClick, deliveryStatus }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(tooltipTextByDeliveryStatus[deliveryStatus])} arrow placement="right">
      <IconButton size="small" onClick={onClick} color={colorByDeliveryStatus[deliveryStatus]}>
        <FontAwesomeIcon icon={faPowerOff} />
      </IconButton>
    </Tooltip>
  );
});

StoreAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  deliveryStatus: PropTypes.string.isRequired,
};
