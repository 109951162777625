import * as React from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/lab';
import frLocale from 'date-fns/locale/fr';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'helpers/hooks';
import { DatepickerDay } from 'components/common/DatepickerField/DatepickerDay';

const renderDatePickerDay = (date, selectedDates, pickersDayProps) => (
  <DatepickerDay date={date} {...pickersDayProps} />
);

export const DateTimepickerField = React.memo(({ name, label, defaultValue, ...props }) => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext();

  const [open, toggleOpen] = useToggle(false);

  const date = watch(name);

  const isDateValid = isValid(new Date(date)) || date === '' || !date;

  const onOpenClose = () => {
    setTimeout(() => document.activeElement?.blur());
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <DesktopDateTimePicker
            {...props}
            clearable
            label={label}
            open={open}
            onOpen={toggleOpen}
            onClose={toggleOpen}
            onViewChange={onOpenClose}
            showDaysOutsideCurrentMonth
            renderDay={renderDatePickerDay}
            renderInput={(params) => (
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  name={label}
                  {...params}
                  error={!isDateValid}
                  helperText={!isDateValid && t('date.invalidDate')}
                />
              </Box>
            )}
            {...field}
          />
        </LocalizationProvider>
      )}
    />
  );
});

DateTimepickerField.defaultProps = {
  label: 'datepicker',
  defaultValue: '',
};
DateTimepickerField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};
