import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { ROUTES } from 'constants/routes';
import {
  mobileImagesSlice,
  selectIsMobileImagesIdsEqual,
  selectMobileImagesIds,
  selectMobileImagesIsLoading,
} from 'ducks/mobileImages';
import { MobileImage } from 'containers/mobileImages/MobileImage';
import { Loader } from 'components/common/Loader';
import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { DraggableElement } from 'components/common/DraggableElement';

export const MobileImagesPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const mobileImagesIds = useSelector(selectMobileImagesIds);

  const isLoading = useSelector(selectMobileImagesIsLoading);

  const isIdsEqual = useSelector(selectIsMobileImagesIdsEqual);

  const { showModal } = useModal();

  const breadcrumbsItemsArray = useMemo(
    () => [
      {
        text: t('mobileImages.carousel'),
        route: ROUTES.mobileImages,
      },
    ],
    [t],
  );

  const handleCreateMobileImage = useCallback(
    (data) => {
      dispatch(
        mobileImagesSlice.actions.addMobileImage({
          image: data.image[0],
          hyperlink: data.hyperlink,
        }),
      );
    },
    [dispatch],
  );

  const onAddClick = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.addMobileImage,
      title: t('modals.mobileImage.addHeader'),
      onSuccess: handleCreateMobileImage,
    });
  }, [handleCreateMobileImage, showModal, t]);

  const onFinishReorderClick = useCallback(() => {
    dispatch(mobileImagesSlice.actions.sendNewListOrder({ newOrder: mobileImagesIds }));
  }, [dispatch, mobileImagesIds]);

  const moveElement = useCallback(
    (dragIndex, hoverIndex) => {
      dispatch(mobileImagesSlice.actions.reorderItems({ dragIndex, hoverIndex }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(mobileImagesSlice.actions.getMobileImagesList());
  }, [dispatch]);

  return (
    <Box p={2} component={Paper}>
      <Box pb={2}>
        <Breadcrumbs breadcrumbsItemsArray={breadcrumbsItemsArray} />
      </Box>
      <Loader isLoading={isLoading}>
        {mobileImagesIds.map((id, index) => (
          <DraggableElement
            key={id}
            dragType="mobileImage"
            moveElement={moveElement}
            id={id}
            index={index}
          >
            <MobileImage id={id} />
          </DraggableElement>
        ))}
      </Loader>
      <Box display="flex" flexDirection="column">
        {!isIdsEqual && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              sx={{ maxWidth: 180, mb: 2, mt: 2 }}
              variant="contained"
              onClick={onFinishReorderClick}
            >
              {t('mobileImages.finishReorder')}
            </Button>
          </Box>
        )}
        <Button sx={{ maxWidth: 180 }} variant="contained" onClick={onAddClick}>
          {t('mobileImages.addButton')}
        </Button>
      </Box>
    </Box>
  );
};
