import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Cancel } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const HistoryTableActionsComponent = React.memo(({ isActive, onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <>
      {isActive && (
        <>
          <Tooltip title={t('pushNotificationsPage.tooltip.edit')} arrow placement="right">
            <IconButton size="small" onClick={onEdit}>
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('pushNotificationsPage.tooltip.delete')} arrow placement="right">
            <IconButton size="small" onClick={onDelete}>
              <Cancel color="error" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
});

HistoryTableActionsComponent.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
