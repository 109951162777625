import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Table } from 'components/common/Table';
import { TablePagination } from 'components/common/TablePagination';
import { StoreActionDeliveryStatus } from 'containers/store/StoreActionDeliveryStatus';
import { Checkbox } from 'components/common/Checkbox';
import { StorePopper } from 'components/store/StorePopper';
import { formatDate, getKeysWithSpecifiedValue } from 'helpers/helpers';
import { Loader } from 'components/common/Loader';
import { DATE_FORMATS } from 'constants/dateFormats';

export const StoreTable = React.memo(
  ({ rows, page, lastPage, isLoading, query, onChangeQuery, isSuccessClicked }) => {
    const { t } = useTranslation();

    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const {
      watch,
      setValue,
      reset,
      formState: { isSubmitSuccessful },
    } = useFormContext();

    const onChangePage = useCallback(
      (event, newPage) => {
        if (newPage) {
          onChangeQuery({ page: newPage });
        }
      },
      [onChangeQuery],
    );

    const columns = useMemo(
      () => [
        {
          name: '',
          field: 'checkbox',
          cell: ({ row }) => <Checkbox name={row.id.toString()} />,
        },
        {
          name: t('table.systemId'),
          field: 'id',
          isSortable: true,
          cell: ({ row }) => row.systemId,
        },
        {
          name: t('table.name'),
          field: 'product_name',
          isSortable: true,
          cell: ({ row }) => row.productName,
        },
        {
          name: t('table.categories'),
          field: 'categories',
          cell: ({ row }) => (row.categories.length ? row.categories[0].name : ''),
        },
        {
          name: t('table.available'),
          field: 'available',
          cell: StoreActionDeliveryStatus,
        },
        {
          name: t('table.startDate'),
          field: 'start_date',
          isSortable: true,
          cell: ({ row: { startDate } }) => formatDate(startDate, DATE_FORMATS.storeDateFormat),
        },
        {
          name: t('table.endDate'),
          field: 'end_date',
          isSortable: true,
          cell: ({ row: { endDate } }) => formatDate(endDate, DATE_FORMATS.storeDateFormat),
        },
      ],
      [t],
    );

    const checkboxesValues = watch();

    useEffect(() => {
      if (getKeysWithSpecifiedValue(checkboxesValues, true).length) {
        setIsPopperOpen(true);
      } else {
        setIsPopperOpen(false);
      }
    }, [checkboxesValues]);

    useEffect(() => {
      if (isSubmitSuccessful && isSuccessClicked) {
        getKeysWithSpecifiedValue(checkboxesValues, true).map((id) => setValue(id, false));
        reset({}, { keepValues: true });
      }
    }, [checkboxesValues, isSubmitSuccessful, isSuccessClicked, reset, setValue]);

    return (
      <Loader isLoading={isLoading}>
        <Table
          rows={rows}
          columns={columns}
          query={query}
          onChangeQuery={onChangeQuery}
          isHeaderTransparent
        />
        {!rows.length && !isLoading && (
          <Typography textAlign="center" mt={1} variant="h5">
            {t('storePage.dataNotFound')}
          </Typography>
        )}
        {lastPage !== 1 && (
          <TablePagination page={page} lastPage={lastPage} handleChangePage={onChangePage} />
        )}
        <StorePopper isOpen={isPopperOpen} />
      </Loader>
    );
  },
);

StoreTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
        PropTypes.bool.isRequired,
        PropTypes.object.isRequired,
        PropTypes.array.isRequired,
      ]),
    ).isRequired,
  ).isRequired,
  query: PropTypes.shape({
    sort: PropTypes.shape({
      order: PropTypes.string.isRequired,
      orderBy: PropTypes.string,
    }).isRequired,
  }).isRequired,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChangeQuery: PropTypes.func.isRequired,
  isSuccessClicked: PropTypes.bool.isRequired,
};
