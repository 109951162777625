import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectIsAuthenticated } from 'ducks/auth/selectors';
import { CommonLayout } from 'components/layouts/CommonLayout';
import { GuestLayout } from 'components/layouts/GuestLayout';

export const Page = React.memo(({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return isAuthenticated ? (
    <CommonLayout>{children}</CommonLayout>
  ) : (
    <GuestLayout> {children}</GuestLayout>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
};
