import React from 'react';
import { Popper as MuiPopper } from '@mui/material';
import PropTypes from 'prop-types';

const popperModifiers = [
  {
    name: 'flip',
    enabled: true,
    options: {
      altBoundary: true,
      rootBoundary: 'viewport',
      padding: 8,
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: true,
      tether: false,
      rootBoundary: 'viewport',
      padding: 24,
    },
  },
];

export const Popper = React.memo(({ isOpen, placement, anchorEl, children }) => (
  <MuiPopper
    open={isOpen}
    placement={placement}
    disablePortal
    anchorEl={anchorEl}
    modifiers={popperModifiers}
  >
    {children}
  </MuiPopper>
));

Popper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  placement: PropTypes.oneOf([
    'auto-end',
    'auto-start',
    'auto',
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]).isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Element)]).isRequired,
  children: PropTypes.node.isRequired,
};
