import React from 'react';
import { Box, Fade, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Modify the styles of Box component to render the welcome text
 */
const GreetingBox = styled(Box)(() => ({
  marginTop: 16,
  paddingLeft: 8,
  '& > *:first-of-type': {
    textTransform: 'uppercase',
    marginBottom: 8,
  },
}));

export const NavBarGreetingComponent = React.memo(({ isOpen, username }) => {
  const { t } = useTranslation();

  return (
    <>
      {isOpen && (
        <Fade in={isOpen}>
          <GreetingBox>
            <Typography color="sideBar.greetingTitle" fontSize={12}>
              {t('sideBar.greetingTitle')}
            </Typography>
            <Typography color="sideBar.text" fontSize={12}>
              {t('sideBar.greeting', { username })}
            </Typography>
          </GreetingBox>
        </Fade>
      )}
    </>
  );
});

NavBarGreetingComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
};
