import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  Typography as MuiTypography,
  styled,
  Button as MuiButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'helpers/helpers';
import { DATE_FORMATS } from 'constants/dateFormats';

const Typography = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.body.text.main,
  fontSize: 14,
  fontWeight: 'bold',
  marginRight: theme.spacing(2),
  width: 220,
  float: 'left',
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.accordion.button,
  '&:hover': {
    backgroundColor: theme.palette.accordion.hover,
  },
  color: theme.palette.accordion.details,
  borderRadius: 0,
  fontSize: 12,
  padding: theme.spacing(0, 2),
  marginLeft: theme.spacing(2),
}));

export const InformationCollapseComponent = React.memo(
  ({ onEdit, onConfirm, information: { balance, cards, expiresAt }, enabled }) => {
    const { t } = useTranslation();

    return (
      <Box border="1px solid #e9e9e9" marginTop={1}>
        {cards && (
          <List>
            <ListItem>
              <Typography>{t('loyaltyInfo.status')}:</Typography>
              <MuiTypography variant="body2">
                {enabled ? (
                  t('account.registered')
                ) : (
                  <>
                    {t('account.notRegistered')}
                    <Button onClick={onConfirm}>{t('account.activateUser')}</Button>
                  </>
                )}
              </MuiTypography>
            </ListItem>
            <ListItem>
              <Typography>{t('loyaltyInfo.creationDate')}:</Typography>
              <MuiTypography variant="body2">{cards[0].updatedAt.slice(0, 10)}</MuiTypography>
            </ListItem>
            <ListItem>
              <Typography style={{ alignSelf: 'baseline' }}>
                {t('loyaltyInfo.cardNumbers')}:
              </Typography>
              <Box display="flex" flexDirection="column">
                {cards.map((card) => (
                  <MuiTypography variant="body2" mb="10px" key={card.number}>
                    {`${card.number} - ${card.type} - ${
                      card.status
                        ? t('loyaltyInfo.cardStatus.enabled')
                        : t('loyaltyInfo.cardStatus.disabled')
                    }`}
                  </MuiTypography>
                ))}
              </Box>
            </ListItem>
            <ListItem>
              <Typography>{t('loyaltyInfo.currentBalance')}:</Typography>
              <MuiTypography variant="body2">
                {t('account.euroValue', { value: balance / 100 })}
              </MuiTypography>
              <Button onClick={onEdit}>{t('account.changeBalance')}</Button>
            </ListItem>
            <ListItem>
              <Typography>{t('loyaltyInfo.lotExpiration')}:</Typography>
              <MuiTypography variant="body2">
                {formatDate(expiresAt, DATE_FORMATS.accountDateFormat)}
              </MuiTypography>
            </ListItem>
          </List>
        )}
      </Box>
    );
  },
);

InformationCollapseComponent.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  information: PropTypes.shape({
    balance: PropTypes.number,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string,
        status: PropTypes.number,
        type: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
    ),
    expiresAt: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};
