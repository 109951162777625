import { createSlice } from '@reduxjs/toolkit';

import { normalize } from 'ducks/mapper';

const initialState = {
  error: {},
  store: { id: 0, embedded: { schedules: [], services: [] } },
  productsById: {},
  productsIds: [],
  isLoading: false,
  meta: {
    currentPage: 1,
    lastPage: 1,
  },

  servicesById: {},
  servicesIds: [],

  listById: {},
  listIds: [],
  initialListIds: [],
  currentStoreContent: {
    id: 0,
    src: '',
    alt: '',
    text: '',
  },
  isLoadingStoreContent: false,
};

export const storeSlice = createSlice({
  name: '@@store',
  initialState,
  reducers: {
    getStoreById: (state) => {
      state.store = initialState.store;
    },
    getStoreByIdSuccess: (state, { payload: { store } }) => {
      state.store = store;
    },
    getStoreByIdFailed: (state, action) => {
      state.error = action.payload;
    },

    getProductsList: (state) => {
      state.isLoading = true;
      state.productsById = initialState.productsById;
      state.productsIds = initialState.productsIds;
      state.meta.currentPage = initialState.meta.currentPage;
      state.meta.lastPage = initialState.meta.lastPage;
    },
    getProductsListSuccess: (state, { payload: { products, currentPage, lastPage } }) => {
      const { byId, allIds } = products;
      state.productsById = byId;
      state.productsIds = allIds;
      state.isLoading = false;
      state.meta.currentPage = currentPage;
      state.meta.lastPage = lastPage;
    },
    getProductsListFailed: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    changeDeliveryStatus: (state) => state,
    changeDeliveryStatusSuccess: (state, { payload: { productId, deliveryStatus } }) => {
      state.productsById[productId].productStores[0].deliveryStatus = deliveryStatus;
    },
    changeDeliveryStatusFailed: (state, action) => {
      state.error = action.payload;
    },

    setCurrentPage: (state, { payload: page }) => {
      state.meta.currentPage = page;
    },
    getServicesList: (state) => {
      state.isLoading = true;
    },
    getServicesListSuccess: (state, { payload: services }) => {
      const { byId, allIds } = normalize(services);
      state.servicesById = byId;
      state.servicesIds = allIds;
      state.isLoading = false;
    },
    getServicesListFailed: (state, { payload }) => {
      state.error = payload;
    },
    toggleStoreService: (state) => {
      state.isLoading = true;
    },
    toggleStoreServiceSuccess: (state, { payload: { services } }) => {
      state.isLoading = false;
      state.store.embedded.services = services;
    },
    toggleStoreServiceFailed: (state, { payload }) => {
      state.error = payload;
    },
    changeStoreSchedule: (state) => {
      state.isLoading = true;
    },
    setStoreScheduleSuccess: (state, { payload: { scheduleId, scheduleLines } }) => {
      state.isLoading = false;

      const prevSchedules = state.store.embedded.schedules;
      state.store.embedded.schedules = prevSchedules.map(({ id, ...params }) => {
        if (id === scheduleId) {
          return {
            ...params,
            id,
            scheduleLines,
          };
        }

        return {
          ...params,
          id,
        };
      });
    },
    setStoreScheduleFailed: (state, { payload }) => {
      state.error = payload;
    },

    getStoreContentList: (state) => {
      state.isLoadingStoreContent = true;
    },
    getStoreContentListSuccess: (state, { payload: { items } }) => {
      const { byId, allIds } = items;
      state.listById = byId;
      state.listIds = allIds;
      state.initialListIds = allIds;

      state.isLoadingStoreContent = false;
    },
    getStoreContentListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoadingStoreContent = false;
    },

    addStoreContent: (state) => state,
    addStoreContentSuccess: (state) => state,
    addStoreContentFailed: (state, action) => {
      state.error = action.payload;
    },

    editStoreContentImage: (state) => state,
    editStoreContentImageSuccess: (state) => state,
    editStoreContentImageFailed: (state, action) => {
      state.error = action.payload;
    },

    editStoreContentText: (state) => state,
    editStoreContentTextSuccess: (state) => state,
    editStoreContentTextFailed: (state, action) => {
      state.error = action.payload;
    },

    deleteStoreContent: (state) => state,
    deleteStoreContentSuccess: (state, { payload: { id } }) => {
      delete state.listById[id];
      state.listIds = state.listIds.filter((listId) => listId !== id);
      state.initialListIds = state.initialListIds.filter((listId) => listId !== id);
    },
    deleteStoreContentFailed: (state, action) => {
      state.error = action.payload;
    },

    setCurrentStoreContent: (state, { payload }) => {
      state.currentStoreContent = payload;
    },
    resetCurrentStoreContent: (state) => {
      state.currentStoreContent = initialState.currentStoreContent;
    },

    resetStoreContent: (state) => {
      state.listById = initialState.listById;
      state.listIds = initialState.listIds;
      state.initialListIds = initialState.initialListIds;
    },

    reorderItems: (state, { payload: { dragIndex, hoverIndex } }) => {
      const dragId = state.listIds[dragIndex];
      state.listIds.splice(dragIndex, 1);
      state.listIds.splice(hoverIndex, 0, dragId);
    },

    sendNewListOrder: (state) => state,
    sendNewListOrderSuccess: (state, { payload: { newOrder } }) => {
      state.initialListIds = newOrder;
    },
    sendNewListOrderFailed: (state, action) => {
      state.error = action.payload;
    },
  },
});
