import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useModal, useNotification } from 'helpers/hooks';
import { authSlice } from 'ducks/auth';
import { NOTIFICATION_TYPES } from 'constants/notifications';
import { MODAL_TYPES } from 'constants/modals';
import { TopBarComponent } from 'components/common/TopBar';
import {
  currentUserSlice,
  selectChangePasswordStatus,
  selectCurrentUsername,
} from 'ducks/currentUser';

export const TopBar = ({ isOpen }) => {
  const { t } = useTranslation();

  const { showNotification } = useNotification();

  const { showModal } = useModal();

  const dispatch = useDispatch();

  const username = useSelector(selectCurrentUsername);

  const changePasswordStatus = useSelector(selectChangePasswordStatus);

  const onLogout = useCallback(() => {
    dispatch(authSlice.actions.logout());

    showNotification({
      notificationType: NOTIFICATION_TYPES.success,
      title: t('notifications.logout'),
      durationInMilliseconds: 5000,
    });
  }, [dispatch, showNotification, t]);

  const handlePasswordChangeSuccess = useCallback(
    (data) => {
      const { oldPassword, password } = data;
      dispatch(
        currentUserSlice.actions.changePassword({
          currentPassword: oldPassword,
          newPassword: password,
        }),
      );
    },
    [dispatch],
  );

  const onPasswordChange = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.passwordChange,
      title: t('modals.changePasswordModal.header'),
      onSuccess: handlePasswordChangeSuccess,
    });
  }, [handlePasswordChangeSuccess, showModal, t]);

  useEffect(() => {
    if (changePasswordStatus) {
      showNotification({
        notificationType:
          changePasswordStatus === NOTIFICATION_TYPES.success
            ? NOTIFICATION_TYPES.success
            : NOTIFICATION_TYPES.error,
        title:
          changePasswordStatus === NOTIFICATION_TYPES.success
            ? t('notifications.changePassword.success')
            : t('notifications.changePassword.failed'),
        durationInMilliseconds: 5000,
      });
    }
  }, [changePasswordStatus, showNotification, t]);

  return (
    <TopBarComponent
      username={username}
      onPasswordChange={onPasswordChange}
      onLogout={onLogout}
      isOpen={isOpen}
    />
  );
};

TopBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
