import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { normalize } from 'ducks/mapper';
import { storeSlice } from 'ducks/store/reducer';
import {
  changeDeliveryStatus,
  getProductsList,
  getStoreById,
  getServicesList,
  toggleStoreService,
  changeStoreSchedule,
  addStoreContent,
  deleteStoreContent,
  editStoreContent,
  getStoreContentList,
  sendNewListOrder,
} from 'ducks/store/services';
import { formatDate, getLastWord } from 'helpers/helpers';
import { SORT_ORDERS } from 'constants/sortOrders';
import { STORE_MENU_SELECT_OPTIONS } from 'constants/selectOptions';
import { DATE_FORMATS } from 'constants/dateFormats';

function* getStoreByIdSaga({ payload: id }) {
  try {
    const store = yield call(getStoreById, id);
    yield put(storeSlice.actions.getStoreByIdSuccess({ store }));
  } catch (error) {
    yield put(storeSlice.actions.getStoreByIdFailed(error?.response?.data));
  }
}

function* getProductsListSaga({
  payload: {
    page,
    storeId,
    sort,
    search,
    limit = '20',
    scope = 'product_full',
    exclude = 'meal_product_stores',
  },
}) {
  try {
    const { order, orderBy } = sort;
    const {
      systemId,
      productName,
      category,
      deliveryStatus,
      startDateIntervalFrom,
      endDateIntervalFrom,
      startDateIntervalTo,
      endDateIntervalTo,
    } = search;

    const {
      embedded: { items },
      page: currentPage,
      pages: lastPage,
    } = yield call(getProductsList, {
      page,
      limit,
      scope,
      exclude,
      storeId,
      order: order === SORT_ORDERS.asc || order === SORT_ORDERS.desc ? order.toUpperCase() : null,
      orderBy,
      systemId,
      productName,
      category: category && category.toLowerCase().split(' ').join('_'),
      deliveryStatus: deliveryStatus !== STORE_MENU_SELECT_OPTIONS.all ? deliveryStatus : null,
      startDateIntervalFrom:
        startDateIntervalFrom &&
        formatDate(startDateIntervalFrom?.toISOString(), DATE_FORMATS.storeDateFormat),
      endDateIntervalFrom:
        endDateIntervalFrom &&
        formatDate(endDateIntervalFrom?.toISOString(), DATE_FORMATS.storeDateFormat),
      startDateIntervalTo:
        startDateIntervalTo &&
        formatDate(startDateIntervalTo?.toISOString(), DATE_FORMATS.storeDateFormat),
      endDateIntervalTo:
        endDateIntervalTo &&
        formatDate(endDateIntervalTo?.toISOString(), DATE_FORMATS.storeDateFormat),
    });

    yield put(
      storeSlice.actions.getProductsListSuccess({
        products: normalize(items),
        currentPage,
        lastPage,
      }),
    );
  } catch (error) {
    yield put(storeSlice.actions.getProductsListFailed(error?.response?.data));
  }
}

function* changeDeliveryStatusSaga({ payload: { storeId, productId, issue } }) {
  try {
    const { message } = yield call(changeDeliveryStatus, {
      storeId,
      productId,
      issue,
    });

    yield put(
      storeSlice.actions.changeDeliveryStatusSuccess({
        productId,
        deliveryStatus: getLastWord(message).toLowerCase(),
      }),
    );
  } catch (error) {
    yield put(storeSlice.actions.changeDeliveryStatusFailed(error?.response?.data));
  }
}
function* getServicesListSaga() {
  try {
    const res = yield call(getServicesList);
    yield put(storeSlice.actions.getServicesListSuccess(res.embedded.items));
  } catch (error) {
    yield put(storeSlice.actions.getServicesListFailed(error?.response?.data));
  }
}

function* toggleStoreServiceSaga({ payload: { storeId, service } }) {
  try {
    const res = yield call(toggleStoreService, storeId, service);
    yield put(storeSlice.actions.toggleStoreServiceSuccess(res));
  } catch (error) {
    yield put(storeSlice.actions.toggleStoreServiceFailed(error?.response?.data));
  }
}

function* setStoreScheduleSaga({ payload: { storeId, params, scheduleLines } }) {
  try {
    yield call(changeStoreSchedule, storeId, params);
    yield put(
      storeSlice.actions.setStoreScheduleSuccess({ scheduleId: params.scheduleId, scheduleLines }),
    );
  } catch (error) {
    yield put(storeSlice.actions.setStoreScheduleFailed(error?.response?.data));
  }
}

function* getStoreContentListSaga({ payload: { storeId } }) {
  try {
    const items = yield call(getStoreContentList, { storeId });

    yield put(storeSlice.actions.getStoreContentListSuccess({ items: normalize(items) }));
  } catch (error) {
    yield put(storeSlice.actions.getStoreContentListFailed(error?.response?.data));
  }
}

function* addStoreContentSaga({ payload: { image, text, storeId } }) {
  try {
    yield call(addStoreContent, { image, text, storeId });

    yield put(storeSlice.actions.addStoreContentSuccess());

    yield put(storeSlice.actions.getStoreContentList({ storeId }));
  } catch (error) {
    yield put(storeSlice.actions.addStoreContentFailed(error?.response?.data));
  }
}

function* editStoreContentImageSaga({ payload: { id, image, storeId } }) {
  try {
    yield call(editStoreContent, { id, image, storeId });

    yield put(storeSlice.actions.editStoreContentImageSuccess());

    yield put(storeSlice.actions.getStoreContentList({ storeId }));
  } catch (error) {
    yield put(storeSlice.actions.editStoreContentImageFailed(error?.response?.data));
  }
}

function* editStoreContentTextSaga({ payload: { id, text, storeId } }) {
  try {
    yield call(editStoreContent, { id, text, storeId });

    yield put(storeSlice.actions.editStoreContentTextSuccess({ id, text }));

    yield put(storeSlice.actions.getStoreContentList({ storeId }));
  } catch (error) {
    yield put(storeSlice.actions.editStoreContentTextFailed(error?.response?.data));
  }
}

function* deleteStoreContentSaga({ payload: { id } }) {
  try {
    yield call(deleteStoreContent, { id });

    yield put(storeSlice.actions.deleteStoreContentSuccess({ id }));
  } catch (error) {
    yield put(storeSlice.actions.deleteStoreContentFailed(error?.response?.data));
  }
}

function* sendNewListOrderSaga({ payload: { newOrder, storeId } }) {
  try {
    yield call(sendNewListOrder, { newOrder, storeId });

    yield put(storeSlice.actions.sendNewListOrderSuccess({ newOrder }));

    yield put(storeSlice.actions.getStoreContentList({ storeId }));
  } catch (error) {
    yield put(storeSlice.actions.sendNewListOrderFailed(error?.response?.data));
  }
}

export function* watchStore() {
  yield takeLatest(storeSlice.actions.getStoreById, getStoreByIdSaga);
  yield takeLatest(storeSlice.actions.getProductsList, getProductsListSaga);
  yield takeEvery(storeSlice.actions.changeDeliveryStatus, changeDeliveryStatusSaga);

  yield takeLatest(storeSlice.actions.getServicesList, getServicesListSaga);
  yield takeEvery(storeSlice.actions.toggleStoreService, toggleStoreServiceSaga);
  yield takeLatest(storeSlice.actions.changeStoreSchedule, setStoreScheduleSaga);

  yield takeLatest(storeSlice.actions.getStoreContentList, getStoreContentListSaga);
  yield takeLatest(storeSlice.actions.addStoreContent, addStoreContentSaga);
  yield takeLatest(storeSlice.actions.editStoreContentImage, editStoreContentImageSaga);
  yield takeLatest(storeSlice.actions.editStoreContentText, editStoreContentTextSaga);
  yield takeLatest(storeSlice.actions.deleteStoreContent, deleteStoreContentSaga);
  yield takeLatest(storeSlice.actions.sendNewListOrder, sendNewListOrderSaga);
}
