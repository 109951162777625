import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectModals } from 'ducks/modals/selectors';
import { MODAL_COMPONENTS_BY_TYPES } from 'constants/modals';

export const ModalManager = ({ modalsMap }) => {
  const modals = useSelector(selectModals);

  return (
    <>
      {modals.map(({ id, modalType, ...modal }) => {
        const CurrentModal = modalsMap[modalType];
        return <CurrentModal key={id} id={id} {...modal} />;
      })}
    </>
  );
};

ModalManager.defaultProps = {
  modalsMap: MODAL_COMPONENTS_BY_TYPES,
};

ModalManager.propTypes = {
  modalsMap: PropTypes.objectOf(PropTypes.elementType.isRequired),
};
