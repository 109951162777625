import axios from 'axios';

import { normalizeRequest, normalizeResponse } from 'helpers/mapper';
import { BASE_URL } from 'config';

const request = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  ignoreList: [],
});

/**
 * Save JWT to the LocalStorage
 */
function setJWTInLocalStorage(response) {
  if (response?.accessToken) {
    window.localStorage.setItem('token', response.accessToken);
  }
  return response;
}

function responseMapper(response) {
  return normalizeResponse(response.data);
}

function requestMapper(requestToSend) {
  if (requestToSend.headers['Content-Type'] === 'multipart/form-data') {
    return requestToSend;
  }

  const { ignoreList, data, params } = requestToSend;
  return {
    ...requestToSend,
    data: normalizeRequest(data, ignoreList),
    params: normalizeRequest(params, ignoreList),
  };
}

/**
 * Set jwt token in the authorization header under the 'Authorization' key
 */
function setJWTHeader(config) {
  const newConfig = config;

  newConfig.headers.common.Authorization = `Bearer ${window.localStorage.getItem('token')}`;

  return newConfig;
}

/**
 * Request interceptor for mapping the response to camelCase
 */
request.interceptors.response.use(responseMapper);

/**
 * Request interceptor for mapping the request to snake_case
 */
request.interceptors.request.use(requestMapper);

/**
 * Request interceptor for setting JWT to request header
 */
request.interceptors.request.use(setJWTHeader);

/**
 * Response interceptor for setting JWT to LocalStorage
 */
request.interceptors.response.use(setJWTInLocalStorage);

export default request;
