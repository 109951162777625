import { isEqual } from 'lodash';

export const selectMobileImagesIds = (state) => state.mobileImages.listIds;
export const selectMobileImagesById = (state) => state.mobileImages.listById;

export const selectMobileImagesIsLoading = (state) => state.mobileImages.isLoading;

export const selectCurrentMobileImage = (state) => state.mobileImages.currentMobileImage;

export const selectIsMobileImagesIdsEqual = (state) =>
  isEqual(state.mobileImages.listIds, state.mobileImages.initialListIds);
