import * as React from 'react';
import { Fab as MuiFab, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

const Fab = styled(MuiFab)(({ theme }) => ({
  backgroundColor: theme.palette.fab.main,
  color: theme.palette.fab.text,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.fab.hover,
  },
}));

export const CreateUserButton = () => (
  <Fab color="primary" aria-label="add" size="small" component={Link} to={ROUTES.createUser}>
    <AddIcon />
  </Fab>
);
