import React, { useCallback } from 'react';
import { CircularProgress, Fade, ListItemText, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  faHome,
  faMobileAlt,
  faShoppingCart,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

/**
 * Create styles for ListItemButton in hover state and when the header is chosen
 */
const hoveredListItemButton = (theme) => ({
  marginLeft: 0,
  color: theme.palette.sideBar.hover,
  backgroundColor: 'inherit',
  borderLeft: '3px solid',
  borderColor: theme.palette.sideBar.border,
  '& *': {
    color: theme.palette.sideBar.hover,
  },
});

/**
 * Modify the styles of MuiListItemButton component to render the subheaders
 */
const ListSubItemButton = styled(MuiListItemButton)(({ theme }) => ({
  padding: theme.spacing(0, 2, 1, 4),
  color: theme.palette.sideBar.text,
  whiteSpace: 'pre-line',
  maxHeight: 60,
}));

/**
 * Modify the styles of MuiListItemIcon to render the headers icons
 */
const ListItemIcon = styled(MuiListItemIcon)(() => ({
  minWidth: 20,
  minHeight: 32,
  alignItems: 'center',
  justifyContent: 'center',
}));

/**
 * Modify the styles of MuiListItemButton to render the headers
 */
const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isCurrent',
})(({ theme, isCurrent }) => ({
  color: theme.palette.sideBar.text,
  marginLeft: 3,
  '& *': {
    color: theme.palette.sideBar.text,
  },
  ...(isCurrent && hoveredListItemButton(theme)),
  '&:hover': hoveredListItemButton(theme),
}));

export const NavBarItem = React.memo(
  ({ isOpen, isCurrent, title, icon, subtitles, route, ...props }) => {
    const { t } = useTranslation();

    const history = useHistory();

    const handleListItemClick = useCallback(() => {
      if (history.location.pathname !== route) {
        history.push(route);
      }
    }, [history, route]);

    return (
      <>
        <ListItemButton isCurrent={isCurrent} onClick={handleListItemClick} {...props}>
          <Fade in={isOpen}>
            <ListItemText primaryTypographyProps={{ fontSize: 15 }}>{t(title)}</ListItemText>
          </Fade>
          <ListItemIcon>
            <FontAwesomeIcon icon={icon} />
          </ListItemIcon>
        </ListItemButton>

        {Boolean(subtitles?.length) &&
          isOpen &&
          isCurrent &&
          subtitles.map(({ subtitle, onClick, isLoading }) =>
            isLoading ? (
              <ListSubItemButton disabled sx={{ justifyContent: 'center', mr: 4 }} key={subtitle}>
                <CircularProgress color="inherit" size={24} />
              </ListSubItemButton>
            ) : (
              <Fade in={isOpen} key={subtitle} timeout={{ enter: 1500 }}>
                <ListSubItemButton onClick={onClick}>
                  <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                    {t(subtitle)}
                  </ListItemText>
                </ListSubItemButton>
              </Fade>
            ),
          )}
      </>
    );
  },
);

NavBarItem.defaultProps = {
  subtitles: [],
};

NavBarItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOf([faHome, faShoppingCart, faUser, faUsers, faMobileAlt]).isRequired,
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      subtitle: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ),
  route: PropTypes.string.isRequired,
};
