import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/common/Table';

export const OrdersDetailedAddInformation = React.memo(
  ({ commcenterGuid, additionalRows, additionalColumns }) => {
    const { t } = useTranslation();

    return (
      <Box sx={{ padding: '32px 16px' }}>
        <Typography variant="h6">
          {t('orders.additionalInformation.additionalInformation')}
        </Typography>
        <Box pl={2} pr={2}>
          <Typography>
            <b>{t('orders.additionalInformation.guid')}</b>
            {` : ${commcenterGuid || ''}`}
          </Typography>
          <Typography ml={2} mt={4} fontWeight="bold">
            {t('orders.additionalInformation.events')}
          </Typography>
          <Table rows={additionalRows} columns={additionalColumns} />
        </Box>
      </Box>
    );
  },
);

OrdersDetailedAddInformation.propTypes = {
  commcenterGuid: PropTypes.string,
  additionalRows: PropTypes.arrayOf(PropTypes.shape({})),
  additionalColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
    }),
  ),
};

OrdersDetailedAddInformation.defaultProps = {
  commcenterGuid: '',
  additionalRows: [],
  additionalColumns: [],
};
