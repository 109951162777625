import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumbs } from 'components/common/Breadcrumbs';
import { ROUTES } from 'constants/routes';
import { Loader } from 'components/common/Loader';
import { useModal } from 'helpers/hooks';
import { MODAL_TYPES } from 'constants/modals';
import { DraggableElement } from 'components/common/DraggableElement';
import { NewsItem } from 'containers/news/NewsItem';
import { newsSlice, selectIsNewsIdsEqual, selectNewsIds, selectNewsIsLoading } from 'ducks/news';

export const NewsPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const newsIds = useSelector(selectNewsIds);

  const isLoading = useSelector(selectNewsIsLoading);

  const isIdsEqual = useSelector(selectIsNewsIdsEqual);

  const { showModal } = useModal();

  const breadcrumbsItemsArray = useMemo(
    () => [
      {
        text: t('news.news'),
        route: ROUTES.news,
      },
    ],
    [t],
  );

  const handleCreateNews = useCallback(
    (data) => {
      dispatch(
        newsSlice.actions.addNewsItem({
          image: data.image[0],
          title: data.title,
          text: data.text,
        }),
      );
    },
    [dispatch],
  );

  const onAddClick = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.addNews,
      title: t('modals.news.addHeader'),
      onSuccess: handleCreateNews,
    });
  }, [handleCreateNews, showModal, t]);

  const onFinishReorderClick = useCallback(() => {
    dispatch(newsSlice.actions.sendNewListOrder({ newOrder: newsIds }));
  }, [dispatch, newsIds]);

  const moveElement = useCallback(
    (dragIndex, hoverIndex) => {
      dispatch(newsSlice.actions.reorderItems({ dragIndex, hoverIndex }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(newsSlice.actions.getNewsList());
  }, [dispatch]);

  return (
    <Box p={2} component={Paper}>
      <Box pb={2}>
        <Breadcrumbs breadcrumbsItemsArray={breadcrumbsItemsArray} />
      </Box>
      <Loader isLoading={isLoading}>
        {newsIds.map((id, index) => (
          <DraggableElement
            key={id}
            dragType="mobileImage"
            moveElement={moveElement}
            id={id}
            index={index}
          >
            <NewsItem id={id} />
          </DraggableElement>
        ))}
      </Loader>
      <Box display="flex" flexDirection="column">
        {!isIdsEqual && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              sx={{ maxWidth: 180, mb: 2, mt: 2 }}
              variant="contained"
              onClick={onFinishReorderClick}
            >
              {t('news.finishReorder')}
            </Button>
          </Box>
        )}
        <Button sx={{ maxWidth: 180 }} variant="contained" onClick={onAddClick}>
          {t('news.addButton')}
        </Button>
      </Box>
    </Box>
  );
};
