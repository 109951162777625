export const selectProfile = (state) => state.account.profile;
export const selectInformation = (state) => state.account.information;
export const selectHistory = (state) => state.account.history;
export const selectIsLoading = (state) => state.account.isLoading;
export const selectStatus = (state) => state.account.status;
export const selectPasswordStatus = (state) => state.account.resetPasswordStatus;
export const selectError = (state) => state.account.error;

export const selectIsDeactivated = (state) => state.account.isDeactivated;
export const selectIsAdditionalHistoryLoading = (state) => state.account.isAdditionalHistoryLoading;

export const selectAccountHistoryMeta = (state) => state.account.historyMeta;
