import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listById: {},
  listIds: [],
  initialListIds: [],
  currentNewsItem: {
    id: 0,
    src: '',
    alt: '',
    title: '',
    text: '',
  },
  isLoading: false,
  error: {},
};

export const newsSlice = createSlice({
  name: '@@news',
  initialState,
  reducers: {
    getNewsList: (state) => {
      state.isLoading = true;
    },
    getNewsListSuccess: (state, { payload: { items } }) => {
      const { byId, allIds } = items;
      state.listById = byId;
      state.listIds = allIds;
      state.initialListIds = allIds;

      state.isLoading = false;
    },
    getNewsListFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    addNewsItem: (state) => state,
    addNewsItemSuccess: (state) => state,
    addNewsItemFailed: (state, action) => {
      state.error = action.payload;
    },

    editNewsItemImage: (state) => state,
    editNewsItemImageSuccess: (state) => state,
    editNewsItemImageFailed: (state, action) => {
      state.error = action.payload;
    },

    editNewsItemText: (state) => state,
    editNewsItemTextSuccess: (state) => state,
    editNewsItemTextFailed: (state, action) => {
      state.error = action.payload;
    },

    deleteNewsItem: (state) => state,
    deleteNewsItemSuccess: (state, { payload: { id } }) => {
      delete state.listById[id];
      state.listIds = state.listIds.filter((listId) => listId !== id);
      state.initialListIds = state.initialListIds.filter((listId) => listId !== id);
    },
    deleteNewsItemFailed: (state, action) => {
      state.error = action.payload;
    },

    setCurrentNewsItem: (state, { payload }) => {
      state.currentNewsItem = payload;
    },
    resetCurrentNewsItem: (state) => {
      state.currentNewsItem = initialState.currentNewsItem;
    },

    reorderItems: (state, { payload: { dragIndex, hoverIndex } }) => {
      const dragId = state.listIds[dragIndex];
      state.listIds.splice(dragIndex, 1);
      state.listIds.splice(hoverIndex, 0, dragId);
    },

    sendNewListOrder: (state) => state,
    sendNewListOrderSuccess: (state, { payload: { newOrder } }) => {
      state.initialListIds = newOrder;
    },
    sendNewListOrderFailed: (state, action) => {
      state.error = action.payload;
    },
  },
});
