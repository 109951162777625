import { createSlice } from '@reduxjs/toolkit';

import { STATUSES } from 'constants/statuses';

const initialState = {
  isAuthenticated: !!localStorage.getItem('token'),
  authStatus: '',
  error: {},
};

export const authSlice = createSlice({
  name: '@@auth',
  initialState,
  reducers: {
    login: (state) => {
      state.authStatus = initialState.authStatus;
    },
    loginSuccess: (state) => {
      state.isAuthenticated = true;
      state.authStatus = STATUSES.success;
    },
    loginFailed: (state, action) => {
      state.error = action.payload;
      state.authStatus = STATUSES.error;
    },
    logout: (state) => {
      state.authStatus = initialState.authStatus;
      state.isAuthenticated = false;
    },
  },
});
