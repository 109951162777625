import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, generatePath } from 'react-router';

import { ORDER_STATE } from 'constants/orders';
import { ROUTES } from 'constants/routes';

const Card = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  padding: 16,
  marginBottom: 20,
  border: `1px solid ${theme.palette.tab.border}`,
  cursor: 'pointer',
}));

export const OrderCard = React.memo(
  ({
    id,
    total,
    payment,
    isPaymentByBalance,
    paymentByBalance,
    currency,
    client,
    restaurant,
    creationDate,
    expectedDate,
    status,
  }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleClick = useCallback(() => {
      history.push(generatePath(ROUTES.order, { orderId: id }));
    }, [history, id]);

    return (
      <Card onClick={handleClick}>
        <Typography variant="h6" component="h4">
          {`${t('orders.cards.order')} #${id} - `}
          {status === ORDER_STATE.awating && <b>{t('orders.cards.withdrawalAwait')}</b>}
        </Typography>
        <Grid container justifyContent="space-between">
          <Box>
            <Typography>{`${t('orders.cards.total')}: ${total} ${currency}`}</Typography>
            <Typography>{`${t('orders.cards.payment')}: ${payment} ${currency}`}</Typography>
            {isPaymentByBalance && (
              <Typography>{`${t(
                'orders.cards.paymentByBalance',
              )}: ${paymentByBalance} ${currency}`}</Typography>
            )}
            <Typography>{`${t('orders.cards.client')}: ${client} - ${t(
              'orders.cards.clientLoyalty',
            )}`}</Typography>
            <Typography>{`${t('orders.cards.restaurant')}: ${restaurant}`}</Typography>
          </Box>
          <Box>
            <Typography>{`${t('orders.cards.creationDate')}: ${creationDate}`}</Typography>
            <Typography>{`${t('orders.cards.expectedDate')}: ${expectedDate}`}</Typography>
          </Box>
        </Grid>
      </Card>
    );
  },
);

OrderCard.propTypes = {
  id: PropTypes.number,
  total: PropTypes.string,
  payment: PropTypes.string,
  isPaymentByBalance: PropTypes.bool.isRequired,
  paymentByBalance: PropTypes.string,
  currency: PropTypes.string,
  client: PropTypes.string,
  restaurant: PropTypes.string,
  creationDate: PropTypes.string,
  expectedDate: PropTypes.string,
  status: PropTypes.string,
};

OrderCard.defaultProps = {
  id: -1,
  total: '',
  payment: '',
  paymentByBalance: '',
  currency: '',
  client: '',
  restaurant: '',
  creationDate: '',
  expectedDate: '',
  status: '',
};
