import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { add, isValid, parseISO } from 'date-fns';

import { TextField } from 'components/common/TextField';
import { DateTimepickerField } from 'components/common/DateTimepickerField';
import { SubmitButtonWithOnlyValidData as SubmitButton } from 'components/common/Form';

export const ManualNotificationForm = React.memo(({ defaultValues, buttonText, onCancel }) => {
  const { t } = useTranslation();

  const { title, content, schedule } = defaultValues;

  const defaultDate = isValid(parseISO(schedule))
    ? String(parseISO(schedule))
    : add(new Date(), { minutes: 1 });

  return (
    <Box display="flex" justifyContent="center" p={1}>
      <Box display="flex" flexDirection="column" minWidth={400}>
        <TextField
          name="title"
          multiline
          label={t('pushNotificationsPage.newNotification.title')}
          isErrorDisplay
          defaultValue={title}
        />
        <TextField
          name="content"
          multiline
          label={t('pushNotificationsPage.newNotification.content')}
          isErrorDisplay
          defaultValue={content}
        />
        <DateTimepickerField
          minDateTime={add(new Date(), { minutes: 1 })}
          name="schedule"
          label={t('pushNotificationsPage.schedule')}
          defaultValue={defaultDate}
        />
        <Box display="flex" justifyContent={onCancel ? 'space-between' : 'flex-end'}>
          {Boolean(onCancel) && (
            <Button variant="contained" onClick={onCancel}>
              {t('pushNotificationsPage.buttons.cancel')}
            </Button>
          )}
          <SubmitButton>{t(buttonText)}</SubmitButton>
        </Box>
      </Box>
    </Box>
  );
});

ManualNotificationForm.defaultProps = {
  onCancel: null,
};

ManualNotificationForm.propTypes = {
  defaultValues: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    schedule: PropTypes.string.isRequired,
  }).isRequired,
  buttonText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
};
