import { takeLatest, put, call } from 'redux-saga/effects';

import { changePassword, fetchCurrentUser } from 'ducks/currentUser/services';

import { currentUserSlice } from './reducer';

function* fetchCurrentUserSaga() {
  try {
    const { username, email, id, roles } = yield call(fetchCurrentUser);

    if (!roles.length) {
      yield put(currentUserSlice.actions.fetchCurrentUserFailed());
      return;
    }

    yield put(
      currentUserSlice.actions.fetchCurrentUserSuccess({ username, email, id, role: roles[0] }),
    );
  } catch (error) {
    yield put(currentUserSlice.actions.fetchCurrentUserFailed(error?.response?.data));
  }
}

function* changePasswordSaga({ payload: { currentPassword, newPassword } }) {
  try {
    yield call(changePassword, { currentPassword, newPassword });

    yield put(currentUserSlice.actions.changePasswordSuccess());
  } catch (error) {
    yield put(currentUserSlice.actions.changePasswordFailed(error?.response?.data));
  }
}

export function* watchCurrentUser() {
  yield takeLatest(currentUserSlice.actions.fetchCurrentUser, fetchCurrentUserSaga);
  yield takeLatest(currentUserSlice.actions.changePassword, changePasswordSaga);
}
